import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import {
  naiveDateFormat,
  shortDateFormat,
} from "../../common/utils/dateformat";
import { TourFragment } from "../../graphql/generated";
import TourPic from "./tourpic";
import PageHeader from "../../common/components/page-header";

const TourTitle: React.FC<{
  tour: TourFragment | undefined;
}> = ({ tour }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <TourPic tour={tour} large />
      </PageHeader.Pic>
      <PageHeader.Title>{naiveDateFormat(tour?.date)}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          {shortDateFormat(tour?.createdAt)}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default TourTitle;
