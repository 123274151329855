import { QueueListIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import {
  ReprocessDataPlanFragment,
  useReprocessDataPlansQuery,
} from "../../graphql/generated";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../common/utils/classnames";
import { shortDateFormat } from "../../common/utils/dateformat";
import ReprocessDataPlanPic from "./pic";

export type SelectedPlan =
  | { selection: "none" }
  | { selection: "create" }
  | { selection: "value"; value: ReprocessDataPlanFragment };

export const ReprocessDataPlanPickerDialog: React.FC<{
  isOpen: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  onPlanPicked: (plan: SelectedPlan) => void;
}> = ({ isOpen, isUpdating, onCancel, onPlanPicked }) => {
  const [selectedPlan, setSelectedPlan] = React.useState<SelectedPlan | null>(
    null
  );

  const [{ data }] = useReprocessDataPlansQuery({
    variables: {
      limit: 25,
    },
  });

  return (
    <ZenDialog
      icon={QueueListIcon}
      show={isOpen}
      onCancel={onCancel}
      onSubmit={() => onPlanPicked(selectedPlan!)}
      submit="Select"
      title="Select plan"
      state={
        isUpdating
          ? ZenDialogState.Submitting
          : selectedPlan
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div>
        <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
          <RadioGroup.Label className="sr-only">Plan</RadioGroup.Label>
          <div className="space-y-2">
            <RadioGroup.Option
              key={"none"}
              value={{ selection: "none" }}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "ring-2 ring-indigo-500" : "",
                  "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between space-x-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-gray-900 text-sm font-medium truncate">
                            None
                          </h3>
                        </div>
                        <p className="mt-1 text-gray-500 text-xs truncate">
                          No plan selected
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-indigo-500" : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              key={"create"}
              value={{ selection: "create" }}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "ring-2 ring-indigo-500" : "",
                  "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center">
                    <div className="w-full flex items-center justify-between space-x-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-gray-900 text-sm font-medium truncate">
                            New
                          </h3>
                        </div>
                        <p className="mt-1 text-gray-500 text-xs truncate">
                          Create a new plan
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-indigo-500" : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
            {data?.reprocessDataPlans.map((plan) => (
              <RadioGroup.Option
                key={plan?.id}
                value={{ selection: "value", value: plan }}
                className={({ checked, active }) =>
                  classNames(
                    checked ? "border-transparent" : "border-gray-300",
                    active ? "ring-2 ring-indigo-500" : "",
                    "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center">
                      <div className="w-full flex items-center justify-between space-x-6">
                        <ReprocessDataPlanPic plan={plan} />
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="text-gray-900 text-sm font-medium truncate">
                              {plan?.title ?? plan?.id}
                            </h3>
                          </div>
                          <p className="mt-1 text-gray-500 text-xs truncate">
                            {plan?.id}
                          </p>
                          <p className="mt-1 text-gray-500 text-xs truncate">
                            {shortDateFormat(plan?.createdAt)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        active ? "border" : "border-2",
                        checked ? "border-indigo-500" : "border-transparent",
                        "absolute -inset-px rounded-lg pointer-events-none"
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </ZenDialog>
  );
};
