import React from "react";
import { FeatureFlagFragment } from "../../graphql/generated";
import FeatureFlagItemActions from "./itemactions";
import FeatureFlagPic from "./pic";
import PageHeader from "../../common/components/page-header";

const FeatureFlagTitle: React.FC<{ flag?: FeatureFlagFragment }> = ({
  flag,
}) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <FeatureFlagPic flag={flag} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {flag?.name.trim() === "" ? "Empty Name" : flag?.name}
      </PageHeader.Title>
      <PageHeader.Actions>
        <FeatureFlagItemActions flag={flag} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default FeatureFlagTitle;
