import React from "react";
import { useTitle } from "../../common/utils/title";
import { SearchContext } from "../../common/context/search";
import { useIdentityPairQuery } from "../../graphql/generated";
import Loading from "../../common/components/loading";
import { Transition } from "@headlessui/react";
import Error from "../../common/components/error";
import IdentityOauthCard from "../components/card";

const IdentityOauthList: React.FC = () => {
  useTitle("Identities");

  const { searchBounced } = React.useContext(SearchContext);

  const [{ data, error, fetching }] = useIdentityPairQuery({
    variables: {
      sub: searchBounced ?? "",
    },
  });

  const count =
    data?.identityPair?.awsCognitoUser || data?.identityPair?.identityOauth
      ? 1
      : 0;

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Identities
                <span className="text-sm text-gray-500 px-2">
                  {fetching ? "Loading..." : `${count}`}
                </span>
              </h2>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <div className="p-4">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {data?.identityPair && count > 0 ? (
                  <IdentityOauthCard identity={data.identityPair} />
                ) : (
                  <em>Exact match required</em>
                )}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default IdentityOauthList;
