import React from "react";
import { Link } from "react-router-dom";
import AccountProfilePic from "../../account/components/profilepic";
import { IdentityPairFragment } from "../../graphql/generated";

const IdentityOauthCard: React.FC<{
  identity?: IdentityPairFragment;
  accountId?: string;
}> = ({ identity, accountId }) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        {identity?.identityOauth?.account && (
          <AccountProfilePic account={identity?.identityOauth?.account} />
        )}
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={
            accountId
              ? `/identities-oauth/${identity?.id}?account=${accountId}`
              : `/identities-oauth/${identity?.id}`
          }
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm text-gray-500 truncate">
            {identity?.identityOauth?.email ?? identity?.sub}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default IdentityOauthCard;
