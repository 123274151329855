import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  RmMappingDetailsFragment,
  RmProblemsForMappingDocument,
  useRmMappingDeleteAllProblemsMutation,
  useRmMappingDeleteMutation,
  useRmMappingDisableMutation,
  useRmMappingDisableProblemReportingMutation,
  useRmMappingEnableMutation,
  useRmMappingEnableProblemReportingMutation,
  useRmMappingQuery,
  useRmMappingSetNameMutation,
  useRmProblemsForMappingQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import { Navigation, NavigationItems } from "../components/nav";
import ResourceMappingTitle from "../components/resource-mapping-title";
import RMMappingTypeControl from "../components/mapping-types/RMMappingTypeControl";
import Problems from "../components/problems";
import { useClient } from "urql";
import CommonSwitch from "../../common/components/switch";
import { NotificationContext } from "../../common/context/notification";
import RMConditionsPanel from "../components/conditions-panel";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { classNames } from "../../common/utils/classnames";

const RMMapping: React.FC = () => {
  const graphqlClient = useClient();
  const params = useParams();
  const { notifier } = React.useContext(NotificationContext);
  const [{ data, error }] = useRmMappingQuery({
    variables: { id: params.mappingId ?? "" },
  });
  const [{ data: problemData, error: problemError }, refetchProblems] =
    useRmProblemsForMappingQuery({
      variables: { mappingId: params.mappingId ?? "" },
      pause: !params.mappingId,
    });

  const [loading, setLoading] = React.useState(false);
  const canFetchMore =
    problemData?.rmProblemsForMapping.pageInfo.hasNextPage ?? false;
  const fetchMore = React.useCallback(async () => {
    if (problemData?.rmProblemsForMapping.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(RmProblemsForMappingDocument, {
          first: 50,
          after: problemData?.rmProblemsForMapping.pageInfo.endCursor,
          mappingId: params.mappingId ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [problemData, params.mappingId, graphqlClient]);

  const name = React.useMemo(() => {
    if (data?.rmMapping.name) {
      return data?.rmMapping.name;
    } else if (data?.rmMapping.field.name) {
      return `${data.rmMapping.field.name} mapping`;
    } else {
      return undefined;
    }
  }, [data]);
  useTitle(
    name,
    data?.rmMapping.field.rootResource.name,
    data?.rmMapping.field.rootResource.mls.shortName,
    "Resource Mapping"
  );

  const nav = NavigationItems.withHome();
  nav.addMls(
    data?.rmMapping.field.rootResource.mls.shortName,
    data?.rmMapping.field.rootResource.mls.id
  );
  if (data && data.rmMapping && data.rmMapping.ancestors) {
    for (const ancestor of data.rmMapping.ancestors) {
      nav.addAncestor(ancestor);
    }
  }
  nav.addMapping(data?.rmMapping.name ?? "Mapping", data?.rmMapping.id);

  const [, enableMutation] = useRmMappingEnableMutation();
  const [, disableMutation] = useRmMappingDisableMutation();

  const onEnabledToggleChanged = React.useCallback(async () => {
    if (data?.rmMapping.isEnabled === true) {
      await disableMutation({
        mappingId: data?.rmMapping.id || "",
      }).then(notifier.notifyGraphql("Mapping disabled"));
    } else if (data?.rmMapping.isEnabled === false) {
      await enableMutation({
        mappingId: data?.rmMapping.id || "",
      }).then(notifier.notifyGraphql("Mapping enabled"));
    } else {
      notifier.failure("Mapping not updated");
    }
  }, [data?.rmMapping.id, data?.rmMapping.isEnabled]);

  const [, enableProblemReportingMutation] =
    useRmMappingEnableProblemReportingMutation();
  const [, disableProblemReportingMutation] =
    useRmMappingDisableProblemReportingMutation();

  const onReportProblemsToggleChanged = React.useCallback(async () => {
    if (data?.rmMapping.reportProblems === true) {
      await disableProblemReportingMutation({
        mappingId: data?.rmMapping.id || "",
      }).then(notifier.notifyGraphql("Reporting problems disabled"));
    } else if (data?.rmMapping.reportProblems === false) {
      await enableProblemReportingMutation({
        mappingId: data?.rmMapping.id || "",
      }).then(notifier.notifyGraphql("Reporting problems enabled"));
    } else {
      notifier.failure("Problem reporting not updated");
    }
  }, [data?.rmMapping.id, data?.rmMapping.reportProblems]);

  const [{ fetching: deleteAllProblemsFetching }, deleteAllProblemsMutation] =
    useRmMappingDeleteAllProblemsMutation();
  const deleteAllProblems = React.useCallback(async () => {
    if (deleteAllProblemsFetching) {
      return;
    }
    await deleteAllProblemsMutation({
      mappingId: data?.rmMapping.id || "",
    }).then(notifier.notifyGraphql("Problems deleted"));
    refetchProblems({ requestPolicy: "network-only" });
  }, [data?.rmMapping]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle
            name={name}
            actions={<MappingActions mapping={data?.rmMapping} />}
          >
            <CommonSwitch
              label="Enabled"
              enabled={data?.rmMapping.isEnabled ?? false}
              toggle={onEnabledToggleChanged}
            />
            <CommonSwitch
              label="Report problems"
              enabled={data?.rmMapping.reportProblems ?? false}
              toggle={onReportProblemsToggleChanged}
            />
          </ResourceMappingTitle>
          {data && data.rmMapping && (
            <>
              <RMConditionsPanel mapping={data.rmMapping} />
              <RMMappingTypeControl
                mapping={data.rmMapping}
                problems={problemData?.rmProblemsForMapping}
              />
            </>
          )}
          <Problems
            data={problemData?.rmProblemsForMapping}
            error={problemError}
            fetch={{
              fetch: fetchMore,
              canFetch: canFetchMore,
              isFetching: loading,
            }}
            declareBankruptcy={{
              declareBankruptcy: deleteAllProblems,
              canDeclareBankruptcy: true,
              isDeclaringBankruptcy: deleteAllProblemsFetching,
            }}
          />
        </div>
      </Transition>
    </>
  );
};

const MappingActions: React.FC<{ mapping?: RmMappingDetailsFragment }> = ({
  mapping,
}) => {
  const { notifier } = React.useContext(NotificationContext);
  const navigate = useNavigate();
  const [setNameDialogIsOpen, setSetNameDialogIsOpen] = React.useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);

  const [{ fetching: setNameFetching }, setNameMutation] =
    useRmMappingSetNameMutation();

  const setNameCallback = React.useCallback(
    async (name?: string) => {
      if (name && name.trim() === "") {
        name = undefined;
      }
      await setNameMutation({
        mappingId: mapping?.id || "",
        name: name,
      }).then(notifier.notifyGraphql("Name updated"));
      setSetNameDialogIsOpen(false);
    },
    [mapping]
  );

  const [{ fetching: deleteMappingFetching }, deleteMappingMutation] =
    useRmMappingDeleteMutation();

  const deleteMappingCallback = React.useCallback(async () => {
    const { data } = await deleteMappingMutation({
      mappingId: mapping?.id ?? "",
    }).then(notifier.notifyGraphql());
    if (data) {
      navigate(`/resource_mapping/fields/${mapping?.field.id}`);
    }
  }, [mapping, deleteMappingMutation]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => setSetNameDialogIsOpen(true)}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    {mapping?.name ? "Update name..." : "Set name..."}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={mapping?.isEnabled}>
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => setDeleteDialogIsOpen(true)}
                  >
                    <TrashIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete mapping...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <SetNameDialog
        mapping={mapping}
        show={setNameDialogIsOpen}
        onCancel={() => setSetNameDialogIsOpen(false)}
        onSubmit={setNameCallback}
        fetching={setNameFetching}
      />
      <DeleteMappingDialog
        show={deleteDialogIsOpen}
        onCancel={() => setDeleteDialogIsOpen(false)}
        onSubmit={deleteMappingCallback}
        fetching={deleteMappingFetching}
      />
    </>
  );
};

const SetNameDialog: React.FC<{
  show?: boolean;
  mapping?: RmMappingDetailsFragment;
  onCancel: () => void;
  onSubmit: (name?: string) => void;
  fetching?: boolean;
}> = ({ mapping, show, onCancel, onSubmit, fetching }) => {
  const [name, setName] = React.useState(mapping?.name ?? "");

  return (
    <ZenDialog
      show={show}
      title="Rename mapping"
      icon={PencilIcon}
      submit="Rename"
      onSubmit={() => onSubmit(name.trim() === "" ? undefined : name.trim())}
      onCancel={onCancel}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
    </ZenDialog>
  );
};

const DeleteMappingDialog: React.FC<{
  show?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  fetching?: boolean;
}> = ({ show, onCancel, onSubmit, fetching }) => {
  return (
    <ZenDialog
      show={show}
      title="Delete mapping"
      icon={TrashIcon}
      submit="Delete"
      onSubmit={() => onSubmit()}
      onCancel={onCancel}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <p>Are you sure you want to permanently delete this mapping?</p>
      </div>
      <div className="mt-1">
        <p>
          <i>Note:</i> all problems associated with this mapping will be
          orphaned but will remain. It is suggested that you clean up all
          problems before deleting and that you only delete mappings that have
          been disabled for more than 5 minutes, so that orphaned problems are
          not created.
        </p>
      </div>
    </ZenDialog>
  );
};

export default RMMapping;
