import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import { useCreateMissingIdentityOauthMutation } from "../../graphql/generated";

const MissingIdentityOauthActions: React.FC<{
  sub?: string;
  accountId?: string;
}> = ({ sub, accountId }) => {
  const { notifier } = React.useContext(NotificationContext);

  if (!sub || !accountId) {
    return <></>;
  }

  const [, createMissingMutation] = useCreateMissingIdentityOauthMutation();
  const createMissing = React.useCallback(async () => {
    await createMissingMutation({
      sub,
      accountId,
    }).then(notifier.notifyGraphql("Missing identity created"));
  }, [sub, accountId, createMissingMutation]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled={!accountId}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => createMissing()}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <UserPlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Create missing identity record
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default MissingIdentityOauthActions;
