import React from "react";
import { RmFieldDetailsFragment } from "../../graphql/generated";
import Panel from "../../common/components/panel";
import { DescriptionList } from "../../common/components/descriptionlist";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import FieldActions from "./field-actions";
import { Link } from "react-router-dom";

export const FieldDetailsPanel: React.FC<{
  field?: RmFieldDetailsFragment;
}> = ({ field }) => {
  return (
    <Panel>
      <Panel.Title actions={<FieldActions field={field} />}>
        Field Details
      </Panel.Title>
      <Panel.Body>
        <DescriptionList>
          <DescriptionListContainer title="Definition" span={3}>
            <div className="whitespace-pre-line">
              {field?.definition ?? "—"}
            </div>
          </DescriptionListContainer>
          <DescriptionListContainer title="Searchable">
            {!field
              ? "—"
              : field.isSearchableGlobal
              ? "Yes, across MLSes"
              : field.isSearchableLocal
              ? "Yes, within this MLS"
              : "No"}
          </DescriptionListContainer>
          {field?.name === "LocalStatus" && (
            <DescriptionListContainer title="Local statuses">
              <Link
                to={`/resource_mapping/mlses/${field.rootResource.mls.id}/statuses`}
                className="text-blue-900 hover:underline"
              >
                Configure local statuses
              </Link>
            </DescriptionListContainer>
          )}
          {field?.name === "LocalPropertyType" && (
            <DescriptionListContainer title="Local property types">
              <Link
                to={`/resource_mapping/mlses/${field.rootResource.mls.id}/property_types`}
                className="text-blue-900 hover:underline"
              >
                Configure local property types
              </Link>
            </DescriptionListContainer>
          )}
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};
