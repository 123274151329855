import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  LocalStatusCategory,
  LocalStatusColor,
  LocalStatusFragment,
  LocalStatusInput,
  useRmMlsRemoveLocalStatusMutation,
  useRmMlsSetLocalStatusMutation,
  useRmMlsStatusesQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import { Navigation, NavigationItems } from "../components/nav";
import ResourceMappingTitle from "../components/resource-mapping-title";
import Panel from "../../common/components/panel";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import Pill from "../components/pill";
import {
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { DescriptionList } from "../../common/components/descriptionlist";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import CommonSwitch from "../../common/components/switch";

const RMMlsStatuses: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useRmMlsStatusesQuery({
    variables: { id: params.mlsId ?? "" },
  });
  useTitle("Statuses", data?.mls.shortName, "Resource Mapping");

  const nav = NavigationItems.withHome();
  nav.addMls(data?.mls.shortName, data?.mls.id);
  nav.add("Statuses", `/resource_mapping/mlses/${data?.mls.id}/statuses`);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle name={data?.mls.shortName}>
            Statuses
          </ResourceMappingTitle>
          <Panel>
            <Panel.Title>Mapping</Panel.Title>
            <Panel.Body>
              <DescriptionList>
                <DescriptionListItem
                  title="LocalStatus"
                  link={`/resource_mapping/fields/${data?.mls.id}:Property:LocalStatus`}
                  value="Configure LocalStatus mapping"
                  span={3}
                />
              </DescriptionList>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Title>Configured statuses</Panel.Title>
            <Panel.Body>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {data?.mls.localStatuses.map((localStatus) => (
                  <LocalStatusCard
                    key={localStatus.id}
                    mlsId={data?.mls.id}
                    localStatus={localStatus}
                    candidates={data?.mls.localStatusCandidates}
                  />
                ))}
              </div>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Title>Unconfigured statuses</Panel.Title>
            <Panel.Body>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {data?.mls.localStatusCandidates
                  .filter(
                    (candidate) =>
                      !data?.mls.localStatuses.some(
                        (status) => status.key === candidate
                      )
                  )
                  .map((candidate) => (
                    <MissingLocalStatusCard
                      key={candidate}
                      mlsId={data?.mls.id}
                      candidateKey={candidate}
                    />
                  ))}
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </Transition>
    </>
  );
};

const LocalStatusCardPrototype: React.FC<{
  localStatus: LocalStatusFragment;
  keyExists: boolean;
  buttons: React.ReactNode;
  bgColor?: string;
}> = ({ localStatus, keyExists, buttons, bgColor }) => {
  let categoryPill = <></>;
  let colorPill = <></>;

  switch (localStatus.category) {
    case LocalStatusCategory.Available:
      categoryPill = <Pill bgColor="bg-gray-500">category:available</Pill>;
      break;
    case LocalStatusCategory.ComingSoon:
      categoryPill = <Pill bgColor="bg-gray-500">category:coming-soon</Pill>;
      break;
    case LocalStatusCategory.OffMarket:
      categoryPill = <Pill bgColor="bg-gray-500">category:off-market</Pill>;
      break;
    case LocalStatusCategory.Pending:
      categoryPill = <Pill bgColor="bg-gray-500">category:pending</Pill>;
      break;
  }
  switch (localStatus.color) {
    case LocalStatusColor.Black:
      colorPill = <Pill bgColor="bg-gray-500">color:black</Pill>;
      break;
    case LocalStatusColor.Blue:
      colorPill = <Pill bgColor="bg-gray-500">color:blue</Pill>;
      break;
    case LocalStatusColor.Green:
      colorPill = <Pill bgColor="bg-gray-500">color:green</Pill>;
      break;
    case LocalStatusColor.Orange:
      colorPill = <Pill bgColor="bg-gray-500">color:orange</Pill>;
      break;
    case LocalStatusColor.Red:
      colorPill = <Pill bgColor="bg-gray-500">color:red</Pill>;
      break;
    case LocalStatusColor.Yellow:
      colorPill = <Pill bgColor="bg-gray-500">color:yellow</Pill>;
      break;
  }

  return (
    <div
      className={classNames(
        "relative rounded-lg border border-gray-300 px-6 py-3 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 grid",
        bgColor ? bgColor : "bg-white"
      )}
      style={{
        gridTemplateColumns: "1fr auto",
      }}
    >
      <div>
        <div>
          {keyExists ? (
            <>{localStatus?.key}</>
          ) : (
            <>
              <abbr
                title="This key is not an available value on the LocalStatus lookup mapping"
                className="underline decoration-wavy decoration-red-500 text-red-900"
              >
                {localStatus?.key}
              </abbr>
            </>
          )}{" "}
          – {localStatus?.short} – {localStatus?.long}
        </div>
        <div className="color-gray-600 text-sm">{localStatus?.description}</div>
        <div>
          {categoryPill} {colorPill}{" "}
          {localStatus?.isClosed && (
            <Pill bgColor="bg-gray-500">is:closed</Pill>
          )}{" "}
          {localStatus?.isPrivate && (
            <Pill bgColor="bg-gray-500">is:private</Pill>
          )}
        </div>
      </div>
      <div>{buttons}</div>
    </div>
  );
};

const MissingLocalStatusCard: React.FC<{
  mlsId: string;
  candidateKey: string;
}> = ({ mlsId, candidateKey }) => {
  const displayLocalStatus = {
    id: `missing-${candidateKey}`,
    key: candidateKey,
    short: "UNKN",
    long: "Unknown",
    category: LocalStatusCategory.Available,
    isPrivate: false,
    isClosed: false,
    color: LocalStatusColor.Black,
    description: "The status is unknown",
  };
  const createLocalStatus = {
    id: `missing-${candidateKey}`,
    key: candidateKey,
    short: candidateKey,
    long: candidateKey,
    category: LocalStatusCategory.OffMarket,
    isPrivate: false,
    isClosed: false,
    color: LocalStatusColor.Black,
    description: `The status is ${candidateKey}.`,
  };

  const { notifier } = React.useContext(NotificationContext);
  const [{ fetching: setLocalStatusFetching }, setLocalStatusMutation] =
    useRmMlsSetLocalStatusMutation();
  const [isUpdateOpen, setIsUpdateOpen] = React.useState(false);

  const onUpdate = React.useCallback(
    async (data: LocalStatusInput) => {
      await setLocalStatusMutation({
        id: mlsId,
        key: displayLocalStatus.key,
        localStatus: data,
      }).then(notifier.notifyGraphql("Status updated"));
      setIsUpdateOpen(false);
    },
    [setLocalStatusMutation, setIsUpdateOpen, displayLocalStatus.key, mlsId]
  );

  const buttons = (
    <>
      <button
        className="flex-none cursor-pointer"
        onClick={() => setIsUpdateOpen(true)}
      >
        <PlusCircleIcon className="w-5 h-5" />
      </button>
      <LocalStatusConfigurationDialog
        isOpen={isUpdateOpen}
        localStatus={createLocalStatus}
        isFetching={setLocalStatusFetching}
        onClose={() => setIsUpdateOpen(false)}
        onSubmit={onUpdate}
      />
    </>
  );

  return (
    <LocalStatusCardPrototype
      localStatus={displayLocalStatus}
      keyExists={true}
      buttons={buttons}
      bgColor="bg-red-100"
    />
  );
};

const LocalStatusCard: React.FC<{
  mlsId: string;
  localStatus: LocalStatusFragment;
  candidates?: string[];
}> = ({ localStatus, candidates, mlsId }) => {
  const { notifier } = React.useContext(NotificationContext);

  const isConfigured = candidates?.some(
    (candidate) => candidate === localStatus?.key
  );

  const [{ fetching: setLocalStatusFetching }, setLocalStatusMutation] =
    useRmMlsSetLocalStatusMutation();
  const [{ fetching: removeLocalStatusFetching }, removeLocalStatusMutation] =
    useRmMlsRemoveLocalStatusMutation();

  const [isUpdateOpen, setIsUpdateOpen] = React.useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = React.useState(false);

  const onUpdate = React.useCallback(
    async (data: LocalStatusInput) => {
      await setLocalStatusMutation({
        id: mlsId,
        key: localStatus.key,
        localStatus: data,
      }).then(notifier.notifyGraphql("Status updated"));
      setIsUpdateOpen(false);
    },
    [setLocalStatusMutation, setIsUpdateOpen, localStatus.key, mlsId]
  );

  const onRemove = React.useCallback(async () => {
    await removeLocalStatusMutation({
      id: mlsId,
      key: localStatus.key,
    }).then(notifier.notifyGraphql("Status removed"));
    setIsRemoveOpen(false);
  }, [removeLocalStatusMutation, setIsRemoveOpen, localStatus.key, mlsId]);

  const buttons = (
    <>
      <button
        className="flex-none cursor-pointer"
        onClick={() => setIsUpdateOpen(true)}
      >
        <PencilIcon className="w-5 h-5" />
      </button>
      <button
        className="flex-none cursor-pointer"
        onClick={() => setIsRemoveOpen(true)}
      >
        <TrashIcon className="w-5 h-5" />
      </button>
    </>
  );

  return (
    <>
      <LocalStatusCardPrototype
        localStatus={localStatus}
        keyExists={isConfigured ?? false}
        buttons={buttons}
      />
      <LocalStatusConfigurationDialog
        isOpen={isUpdateOpen}
        localStatus={localStatus}
        isFetching={setLocalStatusFetching}
        onClose={() => setIsUpdateOpen(false)}
        onSubmit={onUpdate}
      />
      <LocalStatusRemoveDialog
        isOpen={isRemoveOpen}
        localStatus={localStatus}
        isFetching={removeLocalStatusFetching}
        onClose={() => setIsRemoveOpen(false)}
        onSubmit={onRemove}
      />
    </>
  );
};

const LocalStatusConfigurationDialog: React.FC<{
  isOpen: boolean;
  localStatus: LocalStatusFragment;
  isFetching: boolean;
  onClose: () => void;
  onSubmit: (data: LocalStatusInput) => void;
}> = ({ isOpen, localStatus, isFetching, onClose, onSubmit }) => {
  const [long, setLong] = React.useState<string>(localStatus.long);
  const [short, setShort] = React.useState<string>(localStatus.short);
  const [category, setCategory] = React.useState<LocalStatusCategory>(
    localStatus.category
  );
  const [isPrivate, setIsPrivate] = React.useState<boolean>(
    localStatus.isPrivate
  );
  const [isClosed, setIsClosed] = React.useState<boolean>(localStatus.isClosed);
  const [color, setColor] = React.useState<LocalStatusColor>(localStatus.color);
  const [description, setDescription] = React.useState<string>(
    localStatus.description
  );

  const onDialogSubmit = React.useCallback(() => {
    onSubmit({
      long,
      short,
      category,
      isPrivate,
      isClosed,
      color,
      description,
    });
  }, [
    onSubmit,
    long,
    short,
    category,
    isPrivate,
    isClosed,
    color,
    description,
  ]);

  return (
    <ZenDialog
      onCancel={() => onClose()}
      onSubmit={() => onDialogSubmit()}
      show={isOpen}
      icon={PencilIcon}
      title={"Edit Local Status"}
      submit={"Update"}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Key</div>
        <div className="font-bold">{localStatus.key}</div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Short</div>
        <label className="block py-1">
          <input
            type="text"
            value={short}
            onChange={(e) => setShort(e.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Long</div>
        <label className="block py-1">
          <input
            type="text"
            value={long}
            onChange={(e) => setLong(e.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Description</div>
        <label className="block py-1">
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Category</div>
        <label className="block py-1">
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value as LocalStatusCategory)}
          >
            <option value={LocalStatusCategory.Available}>Available</option>
            <option value={LocalStatusCategory.ComingSoon}>Coming Soon</option>
            <option value={LocalStatusCategory.OffMarket}>Off Market</option>
            <option value={LocalStatusCategory.Pending}>Pending</option>
          </select>
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Color</div>
        <label className="block py-1">
          <select
            value={color}
            onChange={(e) => setColor(e.target.value as LocalStatusColor)}
          >
            <option
              value={LocalStatusColor.Black}
              title="Formerly reserved for closed, contingent, etc. inactive listings"
            >
              Black
            </option>
            <option
              value={LocalStatusColor.Blue}
              title="Typically reserved for active listings"
            >
              Blue
            </option>
            <option
              value={LocalStatusColor.Green}
              title="Formerly reserved for private listings,"
            >
              Green
            </option>
            <option
              value={LocalStatusColor.Orange}
              title="Typically reserved for closed, contingent, etc. inactive listings"
            >
              Orange
            </option>
            <option
              value={LocalStatusColor.Red}
              title="Typically reserved for pending listings"
            >
              Red
            </option>
            <option
              value={LocalStatusColor.Yellow}
              title="Typically reserved for coming soon listings"
            >
              Yellow
            </option>
          </select>
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Is Private</div>
        <label className="block py-1">
          <CommonSwitch
            enabled={isPrivate}
            toggle={() => setIsPrivate(!isPrivate)}
            label="Is Private"
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Is Closed</div>
        <label className="block py-1">
          <CommonSwitch
            enabled={isClosed}
            toggle={() => setIsClosed(!isClosed)}
            label="Is Closed"
          />
        </label>
      </div>
    </ZenDialog>
  );
};

const LocalStatusRemoveDialog: React.FC<{
  isOpen: boolean;
  isFetching: boolean;
  localStatus: LocalStatusFragment;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ isOpen, isFetching, localStatus, onClose, onSubmit }) => {
  return (
    <ZenDialog
      onCancel={() => onClose()}
      onSubmit={() => onSubmit()}
      show={isOpen}
      icon={TrashIcon}
      title={"Remove Local Status"}
      submit={"Remove"}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <p>
          Are you sure you want to permanently delete this status configuration?
        </p>
      </div>
      <div className="mt-1">
        <p>
          <i>Note:</i> all listings that still contain the value "
          {localStatus.key}" in the LocalStatus field will display "UNKN" and
          "Unknown" on the front-end.
        </p>
      </div>
    </ZenDialog>
  );
};

export default RMMlsStatuses;
