import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import { shortDateFormat } from "../../common/utils/dateformat";
import { SavedFilterFragment } from "../../graphql/generated";
import SavedFilterPic from "./savedfilterpic";
import PageHeader from "../../common/components/page-header";

const SavedFilterTitle: React.FC<{
  filter?: SavedFilterFragment;
}> = ({ filter }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <SavedFilterPic filter={filter} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {filter?.name.trim() === "" ? "Empty Name" : filter?.name}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          {shortDateFormat(filter?.createdAt)}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default SavedFilterTitle;
