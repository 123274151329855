import {
  CalendarIcon,
  EnvelopeIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import Timestamp from "../../common/components/timestamp";
import { ClipboardContext } from "../../common/context/clipboard";
import { AccountFragment, AccountType } from "../../graphql/generated";
import AccountItemActions from "./itemactions";
import AccountProfilePic from "./profilepic";
import PageHeader from "../../common/components/page-header";

const accountType = (accountType?: AccountType) => {
  switch (accountType) {
    case AccountType.Agent:
      return "Agent";
    case AccountType.Draft:
      return "Draft";
    case AccountType.Member:
      return "Member";
    case AccountType.Lender:
      return "Lender";
    default:
      return "Unknown";
  }
};

const AccountTitle: React.FC<{ account?: AccountFragment }> = ({ account }) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <PageHeader>
      <PageHeader.Pic>
        <AccountProfilePic account={account} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {account?.name.trim() === "" ? "Empty Name" : account?.name}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={UserIcon}>
          {accountType(account?.accountType)}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          <Timestamp timestamp={account?.createdAt} format="short" popover />
          /
          <Timestamp timestamp={account?.activeAt} format="short" popover />
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={EnvelopeIcon}>
          <div
            className="text-blue-900 cursor-pointer"
            onClick={() => sendToClip(account?.email)}
          >
            {account?.email}
          </div>
          <Link
            to={{
              pathname: "/subetha/recipients",
              search: `?email=${account?.email}`,
            }}
          >
            {}
            <MagnifyingGlassIcon
              className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
              aria-hidden="true"
            />
          </Link>
        </PageHeader.DetailsItem>
        {account?.phone && (
          <PageHeader.DetailsItem icon={PhoneIcon}>
            <a href={`tel:${account?.phone}`}>{account?.phone}</a>
          </PageHeader.DetailsItem>
        )}
      </PageHeader.Details>

      <PageHeader.Actions>
        <AccountItemActions account={account} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default AccountTitle;
