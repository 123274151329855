import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  ArrowPathIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  AccountSubscriptionFragment,
  useAccountSubscriptionRemoveOverrideMutation,
  useAccountSubscriptionSetStripeProductofferingMutation,
  useGetAvailableProductOfferingsForAccountQuery,
} from "../../graphql/generated";
import { CreateManualOverrideDialog } from "./createManualOverrideDialog";

const AccountSubscriptionActions: React.FC<{
  subscription?: AccountSubscriptionFragment;
  refreshAccountSubscription?: () => void;
}> = ({ subscription, refreshAccountSubscription }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [isOverrideOpen, setIsOverrideOpen] = React.useState(false);

  const [{ fetching }, manualOverrideMutation] =
    useAccountSubscriptionRemoveOverrideMutation();

  const removeOverride = React.useCallback(() => {
    if (!fetching) {
      manualOverrideMutation({ id: subscription?.id || "" });
    }
  }, [subscription, fetching, manualOverrideMutation]);

  const [productOfferingOpen, setProductOfferingOpen] = React.useState(false);
  const [, setProductOfferingMutation] =
    useAccountSubscriptionSetStripeProductofferingMutation();
  const [selected, setSelected] = React.useState<string>(
    subscription?.productOfferingId ?? ""
  );
  const [{ data: productOfferingData }] =
    useGetAvailableProductOfferingsForAccountQuery({
      variables: {
        accountId: subscription?.accountId ?? "",
      },
    });
  const designateOffering = React.useCallback(async () => {
    await setProductOfferingMutation({
      accountSubscriptionId: subscription?.id ?? "",
      productOfferingId: selected,
    }).then(
      notifier.notifyGraphql(
        "Product offering changed, please allow several minutes for the data to flow to us from Stripe."
      )
    );
  }, [subscription?.id, setProductOfferingMutation, selected]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              {subscription?.manualOverrideLastModifiedAt && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={removeOverride}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <ArrowPathIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Clear Manual Override
                    </div>
                  )}
                </Menu.Item>
              )}

              {!subscription?.manualOverrideLastModifiedAt && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => setIsOverrideOpen(!isOverrideOpen)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <ArrowPathIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Set Manual Override
                    </div>
                  )}
                </Menu.Item>
              )}

              {!subscription?.productOfferingId &&
                subscription?.purchaseStore === "STRIPE" && (
                  <div
                    onClick={() => setProductOfferingOpen(true)}
                    className={classNames(
                      "bg-gray-100 text-gray-900",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <ArrowPathIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Link to Product Offering
                  </div>
                )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <CreateManualOverrideDialog
        isOpen={isOverrideOpen}
        onClose={() => {
          setIsOverrideOpen(false);
        }}
        onUpdate={() => {
          setIsOverrideOpen(false);
          refreshAccountSubscription && refreshAccountSubscription();
        }}
        subscription={subscription}
      />

      <Transition.Root show={productOfferingOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setProductOfferingOpen}
        >
          <div className="absolute inset-0 overflow-hidden h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-none">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Product Offerings
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setProductOfferingOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1  flex flex-col overflow-scroll">
                      <div className="px-4 sm:px-6 py-4 sm:py-6 mt-4 grow overflow-scroll">
                        <RadioGroup value={selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only">
                            Product Offering -- Was Agent
                          </RadioGroup.Label>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {productOfferingData?.getAvailableProductOfferingsForAccount.map(
                              (offering) => (
                                <RadioGroup.Option
                                  key={offering?.id}
                                  value={offering?.id}
                                  className={({ checked, active }) =>
                                    classNames(
                                      checked
                                        ? "border-transparent"
                                        : "border-gray-300",
                                      active ? "ring-2 ring-indigo-500" : "",
                                      "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <div className="flex items-center">
                                        <div className="text-sm">
                                          <RadioGroup.Label
                                            as="div"
                                            className="font-medium text-gray-900"
                                          >
                                            {offering?.description}{" "}
                                          </RadioGroup.Label>
                                          <RadioGroup.Description
                                            as="div"
                                            className="text-gray-500"
                                          >
                                            <p className="sm:inline">
                                              {offering?.offeringType
                                                .__typename ==
                                              "OfferingTypeIndividualAgentDetails"
                                                ? "Individual"
                                                : "Enterprise"}
                                            </p>
                                          </RadioGroup.Description>
                                        </div>
                                      </div>
                                      <div
                                        className={classNames(
                                          active ? "border" : "border-2",
                                          checked
                                            ? "border-indigo-500"
                                            : "border-transparent",
                                          "absolute -inset-px rounded-lg pointer-events-none"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </>
                                  )}
                                </RadioGroup.Option>
                              )
                            )}
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="pt-5 px-4 sm:px-6">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setProductOfferingOpen(false)}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={designateOffering}
                            disabled={!selected}
                            type="submit"
                            className={classNames(
                              selected
                                ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                                : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
                              "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                            )}
                          >
                            Enable Selected
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AccountSubscriptionActions;
