import React from "react";
import Panel from "../../../common/components/panel";
import {
  ResourceMetadataFieldFragment,
  RmFieldLookupValueFragment,
  RmLookupTwoSourceMappingTypeFragment,
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
  useResourceMetadataForFieldQuery,
  useRmMappingSetLookupTwoSourceTransformMutation,
  useRmMappingSetPrimarySourceMutation,
  useRmMappingSetSecondarySourceMutation,
  useRmProblemAcknowledgeMutation,
} from "../../../graphql/generated";
import { NotificationContext } from "../../../common/context/notification";
import {
  LookupValueSelector,
  SelectedLookupValue,
  selectedValueSpread,
} from "./LookupValueSelector";
import LoadingIcon from "../../../common/components/loadingicon";
import { diceCoefficient } from "dice-coefficient";
import ProblemStatus from "../problem-status";
import {
  ResourceMetadataLookupValueComponent,
  resourceMetadataLookupValueForValue,
} from "../resource-metadata/resource-metadata-lookup-value-component";
import { ResourceMetadataFieldComponent } from "../resource-metadata/resource-metadata-field-component";
import { EditableSource, sourceName } from "../mapping-source";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import { DescriptionList } from "../../../common/components/descriptionlist";
import { Link } from "react-router-dom";

const RMLookupTwoSourceMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmLookupTwoSourceMappingTypeFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping, mappingType, problems }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [primarySource, setPrimarySource] = React.useState<string>("");
  const [secondarySource, setSecondarySource] = React.useState<string>();
  const [destination, setDestination] = React.useState<SelectedLookupValue>({
    state: "ignore",
    newValue: "",
  });
  const options = mapping.destinationIsLocalLookup
    ? mapping.field.localLookupValues
    : mapping.field.standardLookupValues;

  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();
  const [{ fetching: setSecondarySourceFetching }, setSecondarySourceMutation] =
    useRmMappingSetSecondarySourceMutation();

  const [{ fetching }, mutation] =
    useRmMappingSetLookupTwoSourceTransformMutation();
  const [{ data: resourceMetadataPrimary }] = useResourceMetadataForFieldQuery({
    variables: {
      id: mapping.field.rootResource.resourceMetadataId,
      fieldName:
        mappingType.primarySource.__typename === "RMFieldMappingSource"
          ? mappingType.primarySource.name
          : "",
    },
    pause: mappingType.primarySource.__typename !== "RMFieldMappingSource",
  });
  const [{ data: resourceMetadataSecondary }] =
    useResourceMetadataForFieldQuery({
      variables: {
        id: mapping.field.rootResource.resourceMetadataId,
        fieldName:
          mappingType.secondarySource.__typename === "RMFieldMappingSource"
            ? mappingType.secondarySource.name
            : "",
      },
      pause: mappingType.primarySource.__typename !== "RMFieldMappingSource",
    });

  const resourceMetadataPrimaryField =
    resourceMetadataPrimary?.resourceMetadata.field ?? undefined;
  const resourceMetadataSecondaryField =
    resourceMetadataSecondary?.resourceMetadata.field ?? undefined;

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    await mutation({
      mappingId: mapping.id,
      primarySource,
      secondarySource,
      ...selectedValueSpread(destination),
    }).then(notifier.notifyGraphql("Mapping updated"));
  }, [
    fetching,
    mutation,
    mapping,
    primarySource,
    secondarySource,
    destination,
  ]);

  return (
    <>
      <Panel>
        <Panel.Title>Two-source Lookup Mapping</Panel.Title>
        <Panel.Body>
          <DescriptionList>
            <DescriptionListContainer title="Primary source">
              <EditableSource
                source={mappingType.primarySource}
                onSubmit={(_, sourceInput) =>
                  setPrimarySourceMutation({
                    mappingId: mapping.id,
                    source: sourceInput,
                  })
                }
                isSubmitting={setPrimarySourceFetching}
              />
            </DescriptionListContainer>
            <DescriptionListContainer title="Secondary source">
              <EditableSource
                source={mappingType.secondarySource}
                onSubmit={(_, sourceInput) =>
                  setSecondarySourceMutation({
                    mappingId: mapping.id,
                    source: sourceInput,
                  })
                }
                isSubmitting={setSecondarySourceFetching}
              />
            </DescriptionListContainer>
            <DescriptionListContainer title="Transforms" span={4}>
              <table className="w-full text-sm text-left">
                <thead>
                  <tr>
                    <th className="p-1">
                      Source:{" "}
                      <ResourceMetadataFieldComponent
                        source={mappingType.primarySource}
                        field={resourceMetadataPrimaryField}
                      />
                    </th>
                    <th className="p-1">
                      Source:{" "}
                      <ResourceMetadataFieldComponent
                        source={mappingType.secondarySource}
                        field={resourceMetadataSecondaryField}
                      />
                    </th>
                    <th className="p-1">Destination: {mapping.field.name}</th>
                  </tr>
                </thead>
                <tbody>
                  {mappingType.transforms.map((transform, idx) => {
                    return (
                      <tr key={idx} className="border-t">
                        <td className="p-1">
                          <ResourceMetadataLookupValueComponent
                            field={resourceMetadataPrimaryField}
                            value={transform.primaryFrom}
                          />
                        </td>
                        <td className="p-1">
                          {transform.secondaryFrom && (
                            <ResourceMetadataLookupValueComponent
                              field={resourceMetadataSecondaryField}
                              value={transform.secondaryFrom}
                            />
                          )}
                          {!transform.secondaryFrom && (
                            <span className="text-gray-400">null</span>
                          )}
                        </td>
                        <td className="p-1">
                          {transform.to && transform.to}
                          {!transform.to && (
                            <span className="text-gray-400">ignore</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr key="add" className="border-t">
                    <td className="p-1">
                      <input
                        type="text"
                        value={primarySource}
                        onChange={(e) => setPrimarySource(e.target.value)}
                      />
                    </td>
                    <td className="p-1">
                      <input
                        type="text"
                        value={secondarySource}
                        onChange={(e) => setSecondarySource(e.target.value)}
                      />
                    </td>
                    <td className="p-1">
                      <LookupValueSelector
                        selected={destination}
                        setSelected={setDestination}
                        options={options}
                        creationIsAllowed={mapping.destinationIsLocalLookup}
                      />{" "}
                      {!fetching && (
                        <button onClick={() => setLookup()}>Set</button>
                      )}
                      {fetching && <LoadingIcon />}
                    </td>
                  </tr>
                </tbody>
              </table>
            </DescriptionListContainer>
          </DescriptionList>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Suggestions</Panel.Title>
        <Panel.Body>
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">
                  Source: {sourceName(mappingType.primarySource)}
                </th>
                <th className="p-1">
                  Source: {sourceName(mappingType.secondarySource)}
                </th>
                <th className="p-1">Destination: {mapping.field.name}</th>
                <th className="p-1"></th>
              </tr>
            </thead>
            <tbody>
              {problems?.edges.map((edge, idx) => (
                <Suggestion
                  key={idx}
                  edge={edge}
                  mapping={mapping}
                  primaryField={resourceMetadataPrimaryField}
                  secondaryField={resourceMetadataSecondaryField}
                  options={options}
                />
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
};

const Suggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
  primaryField?: ResourceMetadataFieldFragment;
  secondaryField?: ResourceMetadataFieldFragment;
  options: RmFieldLookupValueFragment[];
}> = ({ edge, mapping, primaryField, secondaryField, options }) => {
  if (edge.node.data["problem_type"] === "unidentified_two_source_lookup") {
    return (
      <UnidentifiedLookupSuggestion
        edge={edge}
        mapping={mapping}
        primaryField={primaryField}
        secondaryField={secondaryField}
        options={options}
      />
    );
  }
  if (edge.node.data["problem_type"] === "local_data_not_configured") {
    return <LocalDataNotConfiguredSuggestion edge={edge} mapping={mapping} />;
  }

  return <></>;
};

const UnidentifiedLookupSuggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
  primaryField?: ResourceMetadataFieldFragment;
  secondaryField?: ResourceMetadataFieldFragment;
  options: RmFieldLookupValueFragment[];
}> = ({ edge, mapping, primaryField, secondaryField, options }) => {
  const { notifier } = React.useContext(NotificationContext);

  const creationIsAllowed = mapping.destinationIsLocalLookup;
  const problem = edge.node;
  const primarySource = problem.data["primary_value"];
  const secondarySource = problem.data["secondary_value"];
  const secondaryDisplay = secondarySource ?? "(null)";
  const checkValues = [
    secondarySource ? `${primarySource} / ${secondarySource}` : primarySource,
  ];
  const isAcknowledged = problem.acknowledgedAt != null;

  const primaryLookupValue = resourceMetadataLookupValueForValue(
    primarySource,
    primaryField
  );
  const secondaryLookupValue = secondarySource
    ? resourceMetadataLookupValueForValue(secondarySource, secondaryField)
    : null;

  if (primaryLookupValue && secondaryLookupValue) {
    checkValues.push(
      `${primaryLookupValue.name} / ${secondaryLookupValue.name}`
    );
  } else if (primaryLookupValue) {
    checkValues.push(primaryLookupValue.name);
  } else if (secondaryLookupValue) {
    checkValues.push(secondaryLookupValue.name);
  }

  const suggestions = options
    .map((value) => {
      return {
        value: value.value,
        definition: value.definition,
        coefficient: checkValues
          .map((checkValue) => diceCoefficient(checkValue, value.value))
          .reduce((a, b) => Math.max(a, b)),
      };
    })
    .filter((suggestion) => suggestion.coefficient > 0.5);

  suggestions.sort((a, b) => b.coefficient - a.coefficient);
  suggestions.splice(3);

  const [destination, setDestination] = React.useState<SelectedLookupValue>(
    () => {
      if (suggestions.length && suggestions[0].coefficient > 0.8) {
        return {
          state: "existing",
          selectedValue: suggestions[0].value,
          newValue: "",
        };
      } else if (creationIsAllowed) {
        return {
          state: "new",
          newValue: "",
        };
      } else {
        return {
          state: "ignore",
          newValue: "",
        };
      }
    }
  );

  const [{ fetching }, mutation] =
    useRmMappingSetLookupTwoSourceTransformMutation();
  const [, mutationAcknowledge] = useRmProblemAcknowledgeMutation();

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      primarySource,
      secondarySource,
      ...selectedValueSpread(destination),
    }).then(notifier.notifyGraphql("Mapping updated"));
    if (!error) {
      mutationAcknowledge({
        problemId: problem.id,
      });
    }
  }, [
    fetching,
    mutation,
    mutationAcknowledge,
    mapping,
    problem,
    primarySource,
    secondarySource,
    destination,
  ]);

  return (
    <tr>
      <td>
        <ResourceMetadataLookupValueComponent
          field={primaryField}
          value={primarySource}
        />
      </td>
      <td>
        <ResourceMetadataLookupValueComponent
          field={secondaryField}
          value={secondaryDisplay}
        />
      </td>
      <td>
        <LookupValueSelector
          selected={destination}
          setSelected={setDestination}
          suggestions={suggestions}
          options={options}
          disabled={isAcknowledged}
          creationIsAllowed={creationIsAllowed}
        />
        {!fetching && (
          <button onClick={() => setLookup()} disabled={isAcknowledged}>
            Set
          </button>
        )}
        {fetching && <LoadingIcon />}
      </td>

      <td>
        <ProblemStatus problem={problem} />
      </td>
    </tr>
  );
};

const LocalDataNotConfiguredSuggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
}> = ({ edge, mapping }) => {
  const problem = edge.node;
  const fieldName = mapping.field.name;

  if (fieldName === "LocalPropertyType") {
    return (
      <tr>
        <td colSpan={2}>
          <strong>{problem.data["lookup_value"]}</strong> is configured on this
          field but is not configured as an MLS local property type.
        </td>
        <td>
          <Link
            to={`/resource_mapping/mlses/${problem.rootResource.mls.id}/property_types`}
            className="text-blue-900 hover:underline"
          >
            Configure property types
          </Link>
        </td>
        <td>
          <ProblemStatus problem={problem} />
        </td>
      </tr>
    );
  } else if (fieldName === "LocalStatus") {
    return (
      <tr>
        <td colSpan={2}>
          <strong>{problem.data["lookup_value"]}</strong> is configured on this
          field but is not configured as an MLS local status.
        </td>
        <td>
          <Link
            to={`/resource_mapping/mlses/${problem.rootResource.mls.id}/statuses`}
            className="text-blue-900 hover:underline"
          >
            Configure statuses
          </Link>
        </td>
        <td>
          <ProblemStatus problem={problem} />
        </td>
      </tr>
    );
  } else {
    return <></>;
  }
};

export default RMLookupTwoSourceMappingTypeControl;
