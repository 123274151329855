import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import Timestamp from "../../common/components/timestamp";
import { LeadFragment } from "../../graphql/generated";
import LeadPic from "./pic";
import PageHeader from "../../common/components/page-header";

const LeadTitle: React.FC<{ lead?: LeadFragment }> = ({ lead }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <LeadPic lead={lead} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {lead?.account?.name ?? lead?.unauthenticatedDetails?.name ?? lead?.id}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Created:{" "}
          <Timestamp timestamp={lead?.createdAt} format="short" popover />
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default LeadTitle;
