import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  UserPlusIcon,
  UserMinusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  useEnableAccountForFeatureFlagMutation,
  useDisableAccountForFeatureFlagMutation,
  FeatureFlagFragment,
  AccountFragment,
} from "../../graphql/generated";
import AccountPickerDialog from "../../account/components/account-picker-dialog";

const AccountsActions: React.FC<{
  featureFlag?: FeatureFlagFragment;
  enabled?: boolean;
}> = ({ featureFlag, enabled }) => {
  const [open, setOpen] = React.useState(false);

  const { notifier } = React.useContext(NotificationContext);

  const [{ fetching: enableAccountFetching }, enableAccountMutation] =
    useEnableAccountForFeatureFlagMutation();

  const [{ fetching: disableAccountFetching }, disableAccountMutation] =
    useDisableAccountForFeatureFlagMutation();

  const enableAccount = React.useCallback(
    async (account?: AccountFragment) => {
      if (!enableAccountFetching && account) {
        await enableAccountMutation({
          featureFlagId: featureFlag?.id ?? "",
          accountId: account.id,
        }).then(notifier.notifyGraphql());
        setOpen(false);
      }
    },
    [featureFlag, enableAccountFetching, enableAccountMutation, setOpen]
  );

  const disableAccount = React.useCallback(
    async (account?: AccountFragment) => {
      if (!disableAccountFetching && account) {
        await disableAccountMutation({
          featureFlagId: featureFlag?.id ?? "",
          accountId: account.id,
        }).then(notifier.notifyGraphql());
        setOpen(false);
      }
    },
    [featureFlag, disableAccountFetching, disableAccountMutation, setOpen]
  );

  const action = enabled ? enableAccount : disableAccount;
  const text = enabled ? "Enable account" : "Disable account";

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setOpen(!open)}
                  >
                    {enabled ? (
                      <UserPlusIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <UserMinusIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                    {text}
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <AccountPickerDialog
        isOpen={open}
        isUpdating={enableAccountFetching || disableAccountFetching}
        onAccountPicked={action}
        onCancel={() => setOpen(false)}
      />
    </>
  );
};

export default AccountsActions;
