import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  LocalPropertyTypeCategory,
  LocalPropertyTypeFragment,
  LocalPropertyTypeInput,
  useRmMlsPropertyTypesQuery,
  useRmMlsRemoveLocalPropertyTypeMutation,
  useRmMlsSetLocalPropertyTypeMutation,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import { Navigation, NavigationItems } from "../components/nav";
import ResourceMappingTitle from "../components/resource-mapping-title";
import Panel from "../../common/components/panel";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import Pill from "../components/pill";
import {
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { DescriptionList } from "../../common/components/descriptionlist";
import DescriptionListItem from "../../common/components/descriptionlistitem";

const RMMlsPropertyTypes: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useRmMlsPropertyTypesQuery({
    variables: { id: params.mlsId ?? "" },
  });
  useTitle("Property Types", data?.mls.shortName, "Resource Mapping");

  const nav = NavigationItems.withHome();
  nav.addMls(data?.mls.shortName, data?.mls.id);
  nav.add(
    "Property Types",
    `/resource_mapping/mlses/${data?.mls.id}/property_types`
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle name={data?.mls.shortName}>
            Property Types
          </ResourceMappingTitle>
          <Panel>
            <Panel.Title>Mapping</Panel.Title>
            <Panel.Body>
              <DescriptionList>
                <DescriptionListItem
                  title="LocalPropertyType"
                  link={`/resource_mapping/fields/${data?.mls.id}:Property:LocalPropertyType`}
                  value="Configure LocalPropertyType mapping"
                  span={3}
                />
              </DescriptionList>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Title>Configured property types</Panel.Title>
            <Panel.Body>
              <div className="grid grid-cols-3 gap-4">
                {data?.mls.localPropertyTypes.map((localPropertyType) => (
                  <LocalPropertyTypeCard
                    key={localPropertyType.id}
                    mlsId={data?.mls.id}
                    localPropertyType={localPropertyType}
                    candidates={data?.mls.localPropertyTypeCandidates}
                  />
                ))}
              </div>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Title>Unconfigured property types</Panel.Title>
            <Panel.Body>
              <div className="grid grid-cols-3 gap-4">
                {data?.mls.localPropertyTypeCandidates
                  .filter(
                    (candidate) =>
                      !data?.mls.localPropertyTypes.some(
                        (propertyType) => propertyType.key === candidate
                      )
                  )
                  .map((candidate) => (
                    <MissingLocalPropertyTypeCard
                      key={candidate}
                      mlsId={data?.mls.id}
                      candidateKey={candidate}
                    />
                  ))}
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </Transition>
    </>
  );
};

const LocalPropertyTypeCardPrototype: React.FC<{
  localPropertyType: LocalPropertyTypeFragment;
  keyExists: boolean;
  buttons: React.ReactNode;
  bgColor?: string;
}> = ({ localPropertyType, keyExists, buttons, bgColor }) => {
  let categoryPill = <></>;

  switch (localPropertyType.category) {
    case LocalPropertyTypeCategory.Sale:
      categoryPill = <Pill bgColor="bg-gray-500">category:sale</Pill>;
      break;
    case LocalPropertyTypeCategory.Rental:
      categoryPill = <Pill bgColor="bg-gray-500">category:rental</Pill>;
      break;
  }

  return (
    <div
      className={classNames(
        "relative rounded-lg border border-gray-300 px-6 py-3 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 grid",
        bgColor ? bgColor : "bg-white"
      )}
      style={{
        gridTemplateColumns: "1fr auto",
      }}
    >
      <div>
        <div>
          {keyExists ? (
            <>{localPropertyType?.key}</>
          ) : (
            <>
              <abbr
                title="This key is not an available value on the LocalPropertyType lookup mapping"
                className="underline decoration-wavy decoration-red-500 text-red-900"
              >
                {localPropertyType?.key}
              </abbr>
            </>
          )}{" "}
          – {localPropertyType?.short} – {localPropertyType?.long}
        </div>
        <div>{categoryPill}</div>
      </div>
      <div>{buttons}</div>
    </div>
  );
};

const MissingLocalPropertyTypeCard: React.FC<{
  mlsId: string;
  candidateKey: string;
}> = ({ mlsId, candidateKey }) => {
  const displayLocalPropertyType = {
    id: `missing-${candidateKey}`,
    key: candidateKey,
    short: "UNKN",
    long: "Unknown",
    category: LocalPropertyTypeCategory.Sale,
  };
  const createLocalPropertyType = {
    id: `missing-${candidateKey}`,
    key: candidateKey,
    short: candidateKey,
    long: candidateKey,
    category: LocalPropertyTypeCategory.Sale,
  };

  const { notifier } = React.useContext(NotificationContext);
  const [
    { fetching: setLocalPropertyTypeFetching },
    setLocalPropertyTypeMutation,
  ] = useRmMlsSetLocalPropertyTypeMutation();
  const [isUpdateOpen, setIsUpdateOpen] = React.useState(false);

  const onUpdate = React.useCallback(
    async (data: LocalPropertyTypeInput) => {
      await setLocalPropertyTypeMutation({
        id: mlsId,
        key: displayLocalPropertyType.key,
        localPropertyType: data,
      }).then(notifier.notifyGraphql("Property type updated"));
      setIsUpdateOpen(false);
    },
    [
      setLocalPropertyTypeMutation,
      setIsUpdateOpen,
      displayLocalPropertyType.key,
      mlsId,
    ]
  );

  const buttons = (
    <>
      <button
        className="flex-none cursor-pointer"
        onClick={() => setIsUpdateOpen(true)}
      >
        <PlusCircleIcon className="w-5 h-5" />
      </button>
      <LocalPropertyTypeConfigurationDialog
        isOpen={isUpdateOpen}
        localPropertyType={createLocalPropertyType}
        isFetching={setLocalPropertyTypeFetching}
        onClose={() => setIsUpdateOpen(false)}
        onSubmit={onUpdate}
      />
    </>
  );

  return (
    <LocalPropertyTypeCardPrototype
      localPropertyType={displayLocalPropertyType}
      keyExists={true}
      buttons={buttons}
      bgColor="bg-red-100"
    />
  );
};

const LocalPropertyTypeCard: React.FC<{
  mlsId: string;
  localPropertyType: LocalPropertyTypeFragment;
  candidates?: string[];
}> = ({ localPropertyType, candidates, mlsId }) => {
  const { notifier } = React.useContext(NotificationContext);

  const isConfigured = candidates?.some(
    (candidate) => candidate === localPropertyType?.key
  );

  const [
    { fetching: setLocalPropertyTypeFetching },
    setLocalPropertyTypeMutation,
  ] = useRmMlsSetLocalPropertyTypeMutation();
  const [
    { fetching: removeLocalPropertyTypeFetching },
    removeLocalPropertyTypeMutation,
  ] = useRmMlsRemoveLocalPropertyTypeMutation();

  const [isUpdateOpen, setIsUpdateOpen] = React.useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = React.useState(false);

  const onUpdate = React.useCallback(
    async (data: LocalPropertyTypeInput) => {
      await setLocalPropertyTypeMutation({
        id: mlsId,
        key: localPropertyType.key,
        localPropertyType: data,
      }).then(notifier.notifyGraphql("Property type updated"));
      setIsUpdateOpen(false);
    },
    [
      setLocalPropertyTypeMutation,
      setIsUpdateOpen,
      localPropertyType.key,
      mlsId,
    ]
  );

  const onRemove = React.useCallback(async () => {
    await removeLocalPropertyTypeMutation({
      id: mlsId,
      key: localPropertyType.key,
    }).then(notifier.notifyGraphql("Property type removed"));
    setIsRemoveOpen(false);
  }, [
    removeLocalPropertyTypeMutation,
    setIsRemoveOpen,
    localPropertyType.key,
    mlsId,
  ]);

  const buttons = (
    <>
      <button
        className="flex-none cursor-pointer"
        onClick={() => setIsUpdateOpen(true)}
      >
        <PencilIcon className="w-5 h-5" />
      </button>
      <button
        className="flex-none cursor-pointer"
        onClick={() => setIsRemoveOpen(true)}
      >
        <TrashIcon className="w-5 h-5" />
      </button>
    </>
  );

  return (
    <>
      <LocalPropertyTypeCardPrototype
        localPropertyType={localPropertyType}
        keyExists={isConfigured ?? false}
        buttons={buttons}
      />
      <LocalPropertyTypeConfigurationDialog
        isOpen={isUpdateOpen}
        localPropertyType={localPropertyType}
        isFetching={setLocalPropertyTypeFetching}
        onClose={() => setIsUpdateOpen(false)}
        onSubmit={onUpdate}
      />
      <LocalPropertyTypeRemoveDialog
        isOpen={isRemoveOpen}
        localPropertyType={localPropertyType}
        isFetching={removeLocalPropertyTypeFetching}
        onClose={() => setIsRemoveOpen(false)}
        onSubmit={onRemove}
      />
    </>
  );
};

const LocalPropertyTypeConfigurationDialog: React.FC<{
  isOpen: boolean;
  localPropertyType: LocalPropertyTypeFragment;
  isFetching: boolean;
  onClose: () => void;
  onSubmit: (data: LocalPropertyTypeInput) => void;
}> = ({ isOpen, localPropertyType, isFetching, onClose, onSubmit }) => {
  const [long, setLong] = React.useState<string>(localPropertyType.long);
  const [short, setShort] = React.useState<string>(localPropertyType.short);
  const [category, setCategory] = React.useState<LocalPropertyTypeCategory>(
    localPropertyType.category
  );

  const onDialogSubmit = React.useCallback(() => {
    onSubmit({
      long,
      short,
      category,
    });
  }, [onSubmit, long, short, category]);

  return (
    <ZenDialog
      onCancel={() => onClose()}
      onSubmit={() => onDialogSubmit()}
      show={isOpen}
      icon={PencilIcon}
      title={"Edit Local Property Type"}
      submit={"Update"}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Key</div>
        <div className="font-bold">{localPropertyType.key}</div>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Short</div>
        <label className="block py-1">
          <input
            type="text"
            value={short}
            onChange={(e) => setShort(e.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Long</div>
        <label className="block py-1">
          <input
            type="text"
            value={long}
            onChange={(e) => setLong(e.target.value)}
          />
        </label>
      </div>
      <div className="p-1 my-3">
        <div className="text-lg font-light mb-1">Category</div>
        <label className="block py-1">
          <select
            value={category}
            onChange={(e) =>
              setCategory(e.target.value as LocalPropertyTypeCategory)
            }
          >
            <option value={LocalPropertyTypeCategory.Sale}>Sale</option>
            <option value={LocalPropertyTypeCategory.Rental}>Rental</option>
          </select>
        </label>
      </div>
    </ZenDialog>
  );
};

const LocalPropertyTypeRemoveDialog: React.FC<{
  isOpen: boolean;
  isFetching: boolean;
  localPropertyType: LocalPropertyTypeFragment;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ isOpen, isFetching, localPropertyType, onClose, onSubmit }) => {
  return (
    <ZenDialog
      onCancel={() => onClose()}
      onSubmit={() => onSubmit()}
      show={isOpen}
      icon={TrashIcon}
      title={"Remove Local Property Type"}
      submit={"Remove"}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <p>
          Are you sure you want to permanently delete this property type
          configuration?
        </p>
      </div>
      <div className="mt-1">
        <p>
          <i>Note:</i> all listings that still contain the value "
          {localPropertyType.key}" in the LocalPropertyType field will display
          "UNKN" and "Unknown" on the front-end.
        </p>
      </div>
    </ZenDialog>
  );
};

export default RMMlsPropertyTypes;
