import React from "react";
import { InternalListingBucketFragment } from "../../graphql/generated";
import InternalListingBucketPic from "./pic";
import InternalListingBucketItemActions from "./item-actions";
import PageHeader from "../../common/components/page-header";

const InternalListingBucketTitle: React.FC<{
  bucket?: InternalListingBucketFragment;
}> = ({ bucket }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <InternalListingBucketPic bucket={bucket} large />
      </PageHeader.Pic>
      <PageHeader.Title>{bucket?.name}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem>{bucket?.id}</PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Actions>
        <InternalListingBucketItemActions bucket={bucket} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default InternalListingBucketTitle;
