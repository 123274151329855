import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { AwsCognitoUserFragment } from "../../graphql/generated";
import { DescriptionList } from "../../common/components/descriptionlist";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Timestamp from "../../common/components/timestamp";
import CognitoUserItemActions from "./cognito-user-actions";

const IdentityOauthCognitoUser: React.FC<{
  awsCognitoUser?: AwsCognitoUserFragment;
}> = ({ awsCognitoUser }) => {
  if (!awsCognitoUser) {
    return (
      <Panel>
        <Panel.Title>AWS Cognito data</Panel.Title>
        <Panel.Body>
          <i>Not found</i>
        </Panel.Body>
      </Panel>
    );
  }

  return (
    <Panel>
      <Panel.Title
        actions={<CognitoUserItemActions awsCognitoUser={awsCognitoUser} />}
      >
        AWS Cognito data
      </Panel.Title>
      <Panel.Body summary>
        <DescriptionList>
          <DescriptionListItem title="Username" value={awsCognitoUser?.id} />
          <DescriptionListItem
            title="Link"
            value="AWS Cognito"
            link={`https://us-west-2.console.aws.amazon.com/cognito/v2/idp/user-pools/us-west-2_uTU534c2w/user-management/users/details/${awsCognitoUser.username}?region=us-west-2`}
          />
          <DescriptionListItem
            title="Enabled"
            value={awsCognitoUser.enabled.toString()}
          />
          <DescriptionListItem
            title="Preferred MFA Setting"
            value={awsCognitoUser?.preferredMfaSetting}
          />
          <DescriptionListContainer title="User Create Date">
            <Timestamp
              timestamp={awsCognitoUser?.userCreateDate}
              format="short"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListContainer title="User Last Modified Date">
            <Timestamp
              timestamp={awsCognitoUser?.userLastModifiedDate}
              format="short"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListItem
            title="User Status"
            value={awsCognitoUser?.userStatus}
          />
          <DescriptionListContainer title="Identities">
            {(!awsCognitoUser ||
              !awsCognitoUser.identities ||
              !awsCognitoUser.identities.length) &&
              "—"}
            {awsCognitoUser &&
              awsCognitoUser.identities &&
              awsCognitoUser.identities.map((identity) => (
                <span key={identity.id} className="mr-1">
                  {identity.providerName}
                </span>
              ))}
          </DescriptionListContainer>
        </DescriptionList>
      </Panel.Body>
      <Panel.Title>Attributes</Panel.Title>
      <Panel.Body>
        <DescriptionList>
          {awsCognitoUser?.userAttributes?.map((attr) => {
            return (
              <DescriptionListItem
                key={attr.id}
                title={attr.name}
                value={attr.value}
              />
            );
          })}
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default IdentityOauthCognitoUser;
