import React from "react";
import {
  ReprocessDataTaskDetailsFragment,
  ReprocessDataTaskFragment,
} from "../../graphql/generated";
import ReprocessDataTaskPic from "./pic";
import ReprocessDataTaskItemActions from "./itemactions";
import PageHeader from "../../common/components/page-header";

export function buildTaskTitle(task?: ReprocessDataTaskFragment): string {
  return `Reprocess ${task?.rootResource.mls.shortName} ${task?.rootResource.name}`;
}

export const ReprocessDataTaskTitle: React.FC<{
  task?: ReprocessDataTaskDetailsFragment;
}> = ({ task }) => {
  const title = buildTaskTitle(task);

  return (
    <PageHeader>
      <PageHeader.Pic>
        <ReprocessDataTaskPic task={task} large />
      </PageHeader.Pic>
      <PageHeader.Title>{title}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem>{task?.id}</PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Actions>
        <ReprocessDataTaskItemActions task={task} />
      </PageHeader.Actions>
    </PageHeader>
  );
};
