import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmTextFlattenMappingTypeFragment,
  useRmMappingSetPrimarySourceMutation,
  useRmMappingUpdateTextFlattenMutation,
} from "../../../graphql/generated";
import { NotificationContext } from "../../../common/context/notification";
import { DescriptionList } from "../../../common/components/descriptionlist";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import CommonSwitch from "../../../common/components/switch";
import { EditableSource } from "../mapping-source";

const RMTextFlattenMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmTextFlattenMappingTypeFragment;
}> = ({ mapping, mappingType }) => {
  const { notifier } = React.useContext(NotificationContext);

  const [{ fetching: updateFetching }, updateTextFlattenMutation] =
    useRmMappingUpdateTextFlattenMutation();
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  const onSplitOnCommaToggleChanged = React.useCallback(async () => {
    if (updateFetching) {
      return;
    }
    await updateTextFlattenMutation({
      mappingId: mapping.id,
      splitOnComma: !mappingType.splitOnComma,
    }).then(notifier.notifyGraphql("Split on commas toggled"));
  }, [
    mapping.id,
    mappingType.splitOnComma,
    updateFetching,
    updateTextFlattenMutation,
  ]);

  return (
    <Panel>
      <Panel.Title>Text Flatten Mapping</Panel.Title>
      <Panel.Body>
        <DescriptionList>
          <DescriptionListContainer title="Source">
            <EditableSource
              source={mappingType.source}
              onSubmit={(_, sourceInput) =>
                setPrimarySourceMutation({
                  mappingId: mapping.id,
                  source: sourceInput,
                })
              }
              isSubmitting={setPrimarySourceFetching}
            />
          </DescriptionListContainer>
          <DescriptionListContainer
            title="Split on commas"
            info="Whether or not to treat a comma-separated string as multiple values. Enable this for RETS MLSes and certain API MLSes that return multiple values in a single comma-separated string."
          >
            <CommonSwitch
              label="Split on commas"
              enabled={mappingType.splitOnComma ?? false}
              toggle={onSplitOnCommaToggleChanged}
            />
          </DescriptionListContainer>
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default RMTextFlattenMappingTypeControl;
