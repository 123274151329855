import React from "react";
import Panel from "../../../common/components/panel";
import {
  ResourceMetadataFieldFragment,
  RmFieldLookupValueFragment,
  RmLookupBasicMappingTypeFragment,
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
  useResourceMetadataForFieldQuery,
  useRmMappingSetLookupBasicTransformMutation,
  useRmMappingSetPrimarySourceMutation,
  useRmMappingUpdateLookupBasicMutation,
  useRmProblemAcknowledgeMutation,
} from "../../../graphql/generated";
import { NotificationContext } from "../../../common/context/notification";
import {
  LookupValueSelector,
  SelectedLookupValue,
  selectedValueSpread,
} from "./LookupValueSelector";
import LoadingIcon from "../../../common/components/loadingicon";
import ProblemStatus from "../problem-status";
import { diceCoefficient } from "dice-coefficient";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import CommonSwitch from "../../../common/components/switch";
import {
  ResourceMetadataLookupValueComponent,
  resourceMetadataLookupValueForValue,
} from "../resource-metadata/resource-metadata-lookup-value-component";
import { ResourceMetadataFieldComponent } from "../resource-metadata/resource-metadata-field-component";
import { EditableSource, sourceName } from "../mapping-source";
import { DescriptionList } from "../../../common/components/descriptionlist";
import { Link } from "react-router-dom";

const RMLookupBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmLookupBasicMappingTypeFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping, mappingType, problems }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [source, setSource] = React.useState<string>("");
  const [destination, setDestination] = React.useState<SelectedLookupValue>({
    state: "ignore",
    newValue: "",
  });
  const options = mapping.destinationIsLocalLookup
    ? mapping.field.localLookupValues
    : mapping.field.standardLookupValues;

  const [{ fetching }, mutation] =
    useRmMappingSetLookupBasicTransformMutation();
  const [{ fetching: updateFetching }, updateLookupBasicMutation] =
    useRmMappingUpdateLookupBasicMutation();
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  const [{ data: resourceMetadata }] = useResourceMetadataForFieldQuery({
    variables: {
      id: mapping.field.rootResource.resourceMetadataId,
      fieldName:
        mappingType.source.__typename === "RMFieldMappingSource"
          ? mappingType.source.name
          : "",
    },
    pause: mappingType.source.__typename !== "RMFieldMappingSource",
  });

  const resourceMetadataField =
    resourceMetadata?.resourceMetadata.field ?? undefined;

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    await mutation({
      mappingId: mapping.id,
      source,
      ...selectedValueSpread(destination),
    }).then(notifier.notifyGraphql("Mapping updated"));
  }, [fetching, mutation, mapping, source, destination]);

  const onSplitOnCommaToggleChanged = React.useCallback(async () => {
    if (updateFetching) {
      return;
    }
    await updateLookupBasicMutation({
      mappingId: mapping.id,
      splitOnComma: !mappingType.splitOnComma,
    }).then(notifier.notifyGraphql("Split on commas toggled"));
  }, [
    mapping.id,
    mappingType.splitOnComma,
    updateFetching,
    updateLookupBasicMutation,
  ]);

  return (
    <>
      <Panel>
        <Panel.Title>Lookup Mapping</Panel.Title>
        <Panel.Body>
          <DescriptionList>
            <DescriptionListContainer title="Source">
              <EditableSource
                source={mappingType.source}
                onSubmit={(_, sourceInput) =>
                  setPrimarySourceMutation({
                    mappingId: mapping.id,
                    source: sourceInput,
                  })
                }
                isSubmitting={setPrimarySourceFetching}
              />
            </DescriptionListContainer>
            <DescriptionListContainer
              title="Split on commas"
              info="Whether or not to treat a comma-separated string as multiple values. Enable this for RETS MLSes and certain API MLSes that return multiple values in a single comma-separated string."
            >
              <CommonSwitch
                label="Split on commas"
                enabled={mappingType.splitOnComma ?? false}
                toggle={onSplitOnCommaToggleChanged}
              />
            </DescriptionListContainer>
            <DescriptionListContainer title="Transforms" span={4}>
              <table className="w-full text-sm text-left">
                <thead>
                  <tr>
                    <th className="p-1">
                      Source:{" "}
                      <ResourceMetadataFieldComponent
                        source={mappingType.source}
                        field={resourceMetadataField}
                      />
                    </th>
                    <th className="p-1">Destination: {mapping.field.name}</th>
                  </tr>
                </thead>
                <tbody>
                  {mappingType.transforms.map((transform, idx) => {
                    return (
                      <tr key={idx} className="border-t">
                        <td className="p-1">
                          <ResourceMetadataLookupValueComponent
                            field={resourceMetadataField}
                            value={transform.from}
                          />
                        </td>
                        <td className="p-1">
                          {transform.to && transform.to}
                          {!transform.to && (
                            <span className="text-gray-400">ignore</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr key="add" className="border-t">
                    <td className="p-1">
                      <input
                        type="text"
                        value={source}
                        onChange={(e) => setSource(e.target.value)}
                      />
                    </td>
                    <td className="p-1">
                      <LookupValueSelector
                        selected={destination}
                        setSelected={setDestination}
                        options={options}
                        creationIsAllowed={mapping.destinationIsLocalLookup}
                      />{" "}
                      {!fetching && (
                        <button onClick={() => setLookup()}>Set</button>
                      )}
                      {fetching && <LoadingIcon />}
                    </td>
                  </tr>
                </tbody>
              </table>
            </DescriptionListContainer>
          </DescriptionList>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Suggestions</Panel.Title>
        <Panel.Body>
          <table className="w-full text-sm text-left">
            <thead>
              <tr>
                <th className="p-1">
                  Source: {sourceName(mappingType.source)}
                </th>
                <th className="p-1">Destination: {mapping.field.name}</th>
                <th className="p-1"></th>
              </tr>
            </thead>
            <tbody>
              {problems?.edges.map((edge) => (
                <Suggestion
                  key={edge.node.id}
                  edge={edge}
                  mapping={mapping}
                  field={resourceMetadataField}
                  options={options}
                />
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
};

const Suggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
  field?: ResourceMetadataFieldFragment;
  options: RmFieldLookupValueFragment[];
}> = ({ edge, mapping, field, options }) => {
  if (edge.node.data["problem_type"] === "unidentified_lookup") {
    return (
      <UnidentifiedLookupSuggestion
        edge={edge}
        mapping={mapping}
        field={field}
        options={options}
      />
    );
  }
  if (edge.node.data["problem_type"] === "local_data_not_configured") {
    return <LocalDataNotConfiguredSuggestion edge={edge} mapping={mapping} />;
  }

  return <></>;
};

const UnidentifiedLookupSuggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
  field?: ResourceMetadataFieldFragment;
  options: RmFieldLookupValueFragment[];
}> = ({ edge, mapping, field, options }) => {
  const { notifier } = React.useContext(NotificationContext);

  if (edge.node.data["problem_type"] !== "unidentified_lookup") {
    return <></>;
  }

  const creationIsAllowed = mapping.destinationIsLocalLookup;
  const problem = edge.node;
  const source = problem.data["value"];
  const isAcknowledged = problem.acknowledgedAt != null;
  const lookupValue = resourceMetadataLookupValueForValue(source, field);

  const suggestions = options
    .map((value) => {
      return {
        value: value.value,
        definition: value.definition,
        coefficient: Math.max(
          diceCoefficient(source, value.value),
          lookupValue ? diceCoefficient(lookupValue.name, value.value) : 0.0
        ),
      };
    })
    .filter((suggestion) => suggestion.coefficient > 0.5);

  suggestions.sort((a, b) => b.coefficient - a.coefficient);
  suggestions.splice(3);

  const [destination, setDestination] = React.useState<SelectedLookupValue>(
    () => {
      if (suggestions.length && suggestions[0].coefficient > 0.8) {
        return {
          state: "existing",
          selectedValue: suggestions[0].value,
          newValue: lookupValue?.name ?? source,
        };
      } else if (creationIsAllowed) {
        return {
          state: "new",
          newValue: lookupValue?.name ?? source,
        };
      } else {
        return {
          state: "ignore",
          newValue: "",
        };
      }
    }
  );

  const [{ fetching }, mutation] =
    useRmMappingSetLookupBasicTransformMutation();
  const [, mutationAcknowledge] = useRmProblemAcknowledgeMutation();

  const setLookup = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { error } = await mutation({
      mappingId: mapping.id,
      source,
      ...selectedValueSpread(destination),
    }).then(notifier.notifyGraphql("Mapping updated"));
    if (!error) {
      mutationAcknowledge({
        problemId: problem.id,
      });
    }
  }, [
    fetching,
    mutation,
    mutationAcknowledge,
    mapping,
    problem,
    source,
    destination,
  ]);

  return (
    <tr>
      <td>
        <ResourceMetadataLookupValueComponent field={field} value={source} />
      </td>
      <td>
        <LookupValueSelector
          selected={destination}
          setSelected={setDestination}
          suggestions={suggestions}
          options={options}
          disabled={isAcknowledged}
          creationIsAllowed={creationIsAllowed}
        />
        {!fetching && (
          <button onClick={() => setLookup()} disabled={isAcknowledged}>
            Set
          </button>
        )}
        {fetching && <LoadingIcon />}
      </td>

      <td>
        <ProblemStatus problem={problem} />
      </td>
    </tr>
  );
};

const LocalDataNotConfiguredSuggestion: React.FC<{
  edge: RmProblemConnectionFragment["edges"][number];
  mapping: RmMappingDetailsFragment;
}> = ({ edge, mapping }) => {
  const problem = edge.node;
  const fieldName = mapping.field.name;

  if (fieldName === "LocalPropertyType") {
    return (
      <tr>
        <td>
          <strong>{problem.data["lookup_value"]}</strong> is configured on this
          field but is not configured as an MLS local property type.
        </td>
        <td>
          <Link
            to={`/resource_mapping/mlses/${problem.rootResource.mls.id}/property_types`}
            className="text-blue-900 hover:underline"
          >
            Configure property types
          </Link>
        </td>
        <td>
          <ProblemStatus problem={problem} />
        </td>
      </tr>
    );
  } else if (fieldName === "LocalStatus") {
    return (
      <tr>
        <td>
          <strong>{problem.data["lookup_value"]}</strong> is configured on this
          field but is not configured as an MLS local status.
        </td>
        <td>
          <Link
            to={`/resource_mapping/mlses/${problem.rootResource.mls.id}/statuses`}
            className="text-blue-900 hover:underline"
          >
            Configure statuses
          </Link>
        </td>
        <td>
          <ProblemStatus problem={problem} />
        </td>
      </tr>
    );
  } else {
    return <></>;
  }
};

export default RMLookupBasicMappingTypeControl;
