import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  RmFieldFragment,
  RmFieldType,
  RmLookupMode,
  RmNormalizationFragment,
  useRmNormalizationAddRuleNormalizationMutation,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon, PencilIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../common/utils/classnames";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { NotificationContext } from "../../common/context/notification";
import Pill from "./pill";

export const NormalizationsPanel: React.FC<{
  fields?: RmFieldFragment[];
  normalizations?: RmNormalizationFragment[];
  header?: string;
}> = ({ fields, normalizations, header: defaultHeader }) => {
  const header = defaultHeader ?? "Normalizations";

  return (
    <Panel>
      <Panel.Title actions={<Actions fields={fields} />}>{header}</Panel.Title>
      <Panel.Body>
        <NormalizationsUl normalizations={normalizations} />
      </Panel.Body>
    </Panel>
  );
};

export const NormalizationsUl: React.FC<{
  normalizations?: RmNormalizationFragment[];
}> = ({ normalizations }) => {
  if (normalizations && normalizations.length) {
    return (
      <ul className="list-disc list-inside">
        {normalizations &&
          normalizations.map((normalization) => {
            return (
              <li key={normalization.id}>
                <Link
                  to={`/resource_mapping/normalizations/${normalization.id}`}
                >
                  {normalization.name && <>{normalization.name} &mdash; </>}
                  {normalization.field.name}
                  {!normalization.isEnabled && (
                    <Pill bgColor="bg-amber-600">disabled</Pill>
                  )}
                  {!normalization.reportProblems && (
                    <Pill bgColor="bg-amber-600">reporting disabled</Pill>
                  )}
                </Link>
              </li>
            );
          })}
      </ul>
    );
  } else {
    return <i>None</i>;
  }
};

const Actions: React.FC<{
  fields?: RmFieldFragment[];
}> = ({ fields }) => {
  const navigate = useNavigate();
  const { notifier } = React.useContext(NotificationContext);
  const [isAddNormalizationDialogOpen, setIsAddNormalizationDialogOpen] =
    React.useState(false);

  const [{ fetching: addRuleMutationFetching }, addRuleMutation] =
    useRmNormalizationAddRuleNormalizationMutation();

  const onAddRuleDialogSubmit = React.useCallback(
    async (fieldId: string, isLocal: boolean) => {
      const { error, data } = await addRuleMutation({ fieldId, isLocal }).then(
        notifier.notifyGraphql()
      );

      if (!error) {
        navigate(
          `/resource_mapping/normalizations/${data?.rmNormalizationAddRuleNormalization.id}`
        );
      }
      setIsAddNormalizationDialogOpen(false);
    },
    [addRuleMutation, setIsAddNormalizationDialogOpen]
  );

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setIsAddNormalizationDialogOpen(true)}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddNormalizationDialog
        show={isAddNormalizationDialogOpen}
        isFetching={addRuleMutationFetching}
        onCancel={() => setIsAddNormalizationDialogOpen(false)}
        onSubmit={onAddRuleDialogSubmit}
        fields={fields}
      />
    </>
  );
};

const AddNormalizationDialog: React.FC<{
  show?: boolean;
  isFetching?: boolean;
  onCancel: () => void;
  onSubmit: (fieldId: string, isLocal: boolean) => void;
  fields?: RmFieldFragment[];
}> = ({ show, isFetching, onCancel, onSubmit, fields }) => {
  const [fieldId, setFieldId] = React.useState<string | undefined>(undefined);
  const [isLocal, setIsLocal] = React.useState<boolean | undefined>(undefined);

  function buildOptionValue(fieldId: string, isLocal: boolean): string {
    return `${fieldId}^${isLocal}`;
  }

  function parseOptionValue(combined: string): [string, boolean] {
    const [left, right] = combined.split("^", 2);
    return [left, right === "true"];
  }

  const onOptionValueChanged = React.useCallback(
    (combined: string) => {
      const [fieldId, isLocal] = parseOptionValue(combined);
      setFieldId(fieldId);
      setIsLocal(isLocal);
    },
    [setFieldId, setIsLocal]
  );

  const onAddNormalizationSubmit = React.useCallback(() => {
    if (fieldId !== undefined && isLocal !== undefined) {
      onSubmit(fieldId, isLocal);
    }
  }, [fieldId, isLocal]);

  const selectedOptionValue = buildOptionValue(fieldId ?? "", isLocal ?? false);

  return (
    <ZenDialog
      icon={PencilIcon}
      show={show}
      onCancel={onCancel}
      onSubmit={onAddNormalizationSubmit}
      submit="Add"
      title="Add normalization"
      state={
        isFetching
          ? ZenDialogState.Submitting
          : fieldId
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <select
        value={selectedOptionValue}
        onChange={(e) => onOptionValueChanged(e.target.value)}
      >
        {fields &&
          fields.map((field) => {
            if (field.type === RmFieldType.Lookup) {
              const options = [];
              if (field.reziLookupMode !== RmLookupMode.None) {
                const id = buildOptionValue(field.id, false);
                options.push(
                  <option key={id} value={id}>
                    {field.name} (rezi)
                  </option>
                );
              }
              if (field.localLookupMode !== RmLookupMode.None) {
                const id = buildOptionValue(field.id, true);
                options.push(
                  <option key={id} value={id}>
                    {field.name} (local)
                  </option>
                );
              }
              return options;
            } else {
              const id = buildOptionValue(field.id, false);
              return (
                <option key={id} value={id}>
                  {field.name}
                </option>
              );
            }
          })}
      </select>
    </ZenDialog>
  );
};
