import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  KeyIcon,
  TrashIcon,
  UserMinusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  AwsCognitoUserFragment,
  AwsCognitoUserIdentityFragment,
  useAwsCognitoUserRemoveIdentityMutation,
  useAwsCognitoUserSetPasswordMutation,
  useDeleteAwsCognitoUserMutation,
} from "../../graphql/generated";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";

const CognitoUserItemActions: React.FC<{
  awsCognitoUser?: AwsCognitoUserFragment;
}> = ({ awsCognitoUser }) => {
  const { notifier } = React.useContext(NotificationContext);

  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);
  const [selectedIdentity, setSelectedIdentity] =
    React.useState<AwsCognitoUserIdentityFragment>();
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [{ fetching: changePasswordFetching }, changePasswordMutation] =
    useAwsCognitoUserSetPasswordMutation();
  const [{ fetching: removeIdentityFetching }, removeIdentityMutation] =
    useAwsCognitoUserRemoveIdentityMutation();
  const [{ fetching: deleteFetching }, deleteMutation] =
    useDeleteAwsCognitoUserMutation();

  const changePassword = React.useCallback(
    async (password: string) => {
      if (!password) {
        return;
      }
      await changePasswordMutation({
        sub: awsCognitoUser?.id ?? "",
        password: password,
      }).then(notifier.notifyGraphql("Password updated"));
      setChangePasswordOpen(false);
    },
    [awsCognitoUser, setChangePasswordOpen]
  );

  const removeIdentity = React.useCallback(async () => {
    if (!selectedIdentity) {
      return;
    }
    await removeIdentityMutation({
      sub: awsCognitoUser?.id ?? "",
      identityId: selectedIdentity.id,
    }).then(notifier.notifyGraphql("External identity removed"));
    setSelectedIdentity(undefined);
  }, [selectedIdentity, setSelectedIdentity]);

  const performDelete = React.useCallback(async () => {
    await deleteMutation({
      sub: awsCognitoUser?.id ?? "",
    }).then(notifier.notifyGraphql("Deleted"));
    setDeleteOpen(false);
  }, [awsCognitoUser, setDeleteOpen]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled={!awsCognitoUser}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setChangePasswordOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <KeyIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Change password...
                  </div>
                )}
              </Menu.Item>
              {awsCognitoUser?.identities &&
                awsCognitoUser.identities.map((identity) => (
                  <Menu.Item>
                    {({ active, disabled }) => (
                      <div
                        onClick={() => setSelectedIdentity(identity)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900 cursor-pointer"
                            : disabled
                            ? "text-gray-400"
                            : "text-gray-700",
                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                        )}
                      >
                        <UserMinusIcon
                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        Remove {identity.providerName}...
                      </div>
                    )}
                  </Menu.Item>
                ))}
              <Menu.Item disabled={!awsCognitoUser}>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setDeleteOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <TrashIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <ChangePasswordDialog
        onCancel={() => setChangePasswordOpen(false)}
        onSubmit={(password: string) => changePassword(password)}
        show={changePasswordOpen}
        fetching={changePasswordFetching}
      />

      <RemoveIdentityDialog
        onCancel={() => setSelectedIdentity(undefined)}
        onSubmit={() => removeIdentity()}
        identity={selectedIdentity}
        fetching={removeIdentityFetching}
      />

      <DeleteDialog
        onCancel={() => setDeleteOpen(false)}
        onSubmit={() => performDelete()}
        show={deleteOpen}
        fetching={deleteFetching}
      />
    </>
  );
};

const ChangePasswordDialog: React.FC<{
  show?: boolean;
  onCancel: () => void;
  onSubmit: (password: string) => void;
  fetching?: boolean;
}> = ({ onCancel, onSubmit, fetching, show }) => {
  const [password, setPassword] = React.useState("");
  return (
    <ZenDialog
      icon={KeyIcon}
      onCancel={() => onCancel()}
      onSubmit={() => {
        onSubmit(password);
      }}
      title={"Change password"}
      show={show}
      submit={"Update"}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div>
        <label>New password</label>
      </div>
      <div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
    </ZenDialog>
  );
};

const RemoveIdentityDialog: React.FC<{
  onCancel: () => void;
  onSubmit: () => void;
  identity?: AwsCognitoUserIdentityFragment;
  fetching?: boolean;
}> = ({ onCancel, onSubmit, fetching, identity }) => {
  return (
    <ZenDialog
      icon={UserMinusIcon}
      onCancel={() => onCancel()}
      onSubmit={() => onSubmit()}
      title={"Remove identity"}
      show={!!identity}
      submit={"Remove"}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <p>
        Remove the <strong>{identity?.providerName}</strong> identity?
      </p>
    </ZenDialog>
  );
};

const DeleteDialog: React.FC<{
  show?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  fetching?: boolean;
}> = ({ onCancel, onSubmit, fetching, show }) => {
  const [confirmationText, setConfirmationText] = React.useState("");
  const isValid = confirmationText.toLowerCase() == "delete";
  return (
    <ZenDialog
      icon={TrashIcon}
      onCancel={() => onCancel()}
      onSubmit={() => {
        onSubmit();
      }}
      title={"Delete"}
      show={show}
      submit={"Delete"}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div>
        <label>Type "delete" to confirm.</label>
      </div>
      <div>
        <input
          type="text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
        />
      </div>
    </ZenDialog>
  );
};

export default CognitoUserItemActions;
