import {
  IdentificationIcon,
  BuildingLibraryIcon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { OfficeQuery } from "../../graphql/generated";
import OfficeActions from "./actions";
import OfficePic from "./pic";
import PageHeader from "../../common/components/page-header";

const OfficeTitle: React.FC<{ office?: OfficeQuery["office"] }> = ({
  office,
}) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <OfficePic office={office} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {office?.name?.trim() === "" ? "Empty Name" : office?.name} -{" "}
        {office?.address?.city}, {office?.address?.state}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={IdentificationIcon}>
          {office?.mlsId}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={BuildingLibraryIcon}>
          {office?.mls?.name}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={EnvelopeIcon}>
          {office?.address?.address1}, {office?.address?.city},{" "}
          {office?.address?.zipCode} {office?.address?.state}
        </PageHeader.DetailsItem>
        {office?.phone && (
          <PageHeader.DetailsItem icon={PhoneIcon}>
            <a href={`tel:${office.phone}`}>{office.phone}</a>
          </PageHeader.DetailsItem>
        )}
      </PageHeader.Details>
      <PageHeader.Actions>
        <OfficeActions office={office} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default OfficeTitle;
