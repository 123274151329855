import { MagnifyingGlassIcon, UsersIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { AccountFragment, useAccountsQuery } from "../../graphql/generated";
import { SearchContext } from "../../common/context/search";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../../common/utils/classnames";
import { distanceDateFormat } from "../../common/utils/dateformat";
import AccountProfilePic from "./profilepic";

const AccountPickerDialog: React.FC<{
  isOpen: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  onAccountPicked: (account: AccountFragment) => void;
}> = ({ isOpen, isUpdating, onCancel, onAccountPicked }) => {
  const [selectedAccount, setSelectedAccount] =
    React.useState<AccountFragment | null>(null);

  const { search, setSearch, searchBounced } = React.useContext(SearchContext);

  const [{ data }] = useAccountsQuery({
    variables: {
      first: 10,
      search: searchBounced,
    },
  });

  return (
    <ZenDialog
      icon={UsersIcon}
      show={isOpen}
      onCancel={onCancel}
      onSubmit={() => onAccountPicked(selectedAccount!)}
      submit="Select"
      title="Select account"
      state={
        isUpdating
          ? ZenDialogState.Submitting
          : selectedAccount
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div style={{ gridTemplateRows: "auto 1fr" }} className="grid max-h-full">
        <div className="relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
            placeholder={`Search for accounts`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="mt-4 grow overflow-scroll">
          <RadioGroup value={selectedAccount} onChange={setSelectedAccount}>
            <RadioGroup.Label className="sr-only">Account</RadioGroup.Label>
            <div className="space-y-2">
              {data?.accounts.edges?.map((account) => (
                <RadioGroup.Option
                  key={account?.node.id}
                  value={account?.node}
                  className={({ checked, active }) =>
                    classNames(
                      checked ? "border-transparent" : "border-gray-300",
                      active ? "ring-2 ring-indigo-500" : "",
                      "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex items-center">
                        <div className="w-full flex items-center justify-between space-x-6">
                          <AccountProfilePic account={account?.node} />
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-gray-900 text-sm font-medium truncate">
                                {account?.node.name.trim() === ""
                                  ? "Empty Name"
                                  : account?.node.name}
                                <span className="ml-1 text-xs text-gray-500">
                                  · {account?.node.email}
                                </span>
                              </h3>
                              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                {account?.node.accountType === "AGENT"
                                  ? "Agent"
                                  : "Member"}
                              </span>
                            </div>
                            <p className="mt-1 text-gray-500 text-xs truncate">
                              Active{" "}
                              {distanceDateFormat(account?.node.activeAt)}
                            </p>
                            <p className="mt-1 text-gray-500 text-xs truncate">
                              {account?.node.id}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          active ? "border" : "border-2",
                          checked ? "border-indigo-500" : "border-transparent",
                          "absolute -inset-px rounded-lg pointer-events-none"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
    </ZenDialog>
  );
};

export default AccountPickerDialog;
