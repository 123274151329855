import React from "react";
import Panel from "../../common/components/panel";
import {
  AccountFragment,
  useIdentityPairsForAccountQuery,
} from "../../graphql/generated";
import IdentityOauthCard from "../../identity-oauth/components/card";

const AccountOauthIdentities: React.FC<{ account?: AccountFragment }> = ({
  account,
}) => {
  const [{ data }] = useIdentityPairsForAccountQuery({
    variables: {
      accountId: account?.id || "",
    },
    pause: !account?.id,
  });

  return (
    <Panel>
      <Panel.Title>
        Identities (new):{" "}
        <span className="text-xs text-gray-500">
          {data?.identityPairsForAccount.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.identityPairsForAccount.map((identity) => (
            <IdentityOauthCard
              identity={identity}
              accountId={account?.id}
              key={identity.id}
            />
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default AccountOauthIdentities;
