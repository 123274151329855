import React from "react";
import {
  RmMappingDetailsFragment,
  useRmMappingClearConditionMutation,
  useRmMappingSetConditionRuleMutation,
  useRmMappingSetConditionTermsMutation,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../common/utils/classnames";
import { PencilIcon } from "@heroicons/react/24/outline";
import RuleExpressionEditor from "./rule-expression-editor";
import { NotificationContext } from "../../common/context/notification";

const RMConditionsPanel: React.FC<{
  mapping?: RmMappingDetailsFragment;
}> = ({ mapping }) => {
  const [editConditions, setEditConditions] = React.useState(false);

  return (
    <Panel>
      <Panel.Title
        actions={
          <RMConditionsActions
            mapping={mapping}
            setEditConditions={setEditConditions}
          />
        }
      >
        Conditions
      </Panel.Title>
      <Panel.Body>
        {!editConditions && <ViewConditions mapping={mapping} />}
        {editConditions && (
          <EditConditions
            mapping={mapping}
            switchToView={() => setEditConditions(false)}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

const RMConditionsActions: React.FC<{
  mapping?: RmMappingDetailsFragment;
  setEditConditions: (val: boolean) => void;
}> = ({ mapping, setEditConditions }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled={!mapping || mapping.conditions.length >= 2}>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => {
                      setEditConditions(true);
                      setOpen(!open);
                    }}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Edit
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const ViewConditions: React.FC<{
  mapping?: RmMappingDetailsFragment;
}> = ({ mapping }) => {
  return (
    <ul className="list-disc list-inside">
      {mapping &&
        mapping.conditions.length > 0 &&
        mapping.conditions.map((condition, idx) => {
          return <li key={idx}>{condition.description}</li>;
        })}
      {mapping && mapping.conditions.length === 0 && (
        <li>
          <em>None</em>
        </li>
      )}
    </ul>
  );
};

enum ConditionType {
  None,
  Rule,
  Terms,
}

const EditConditions: React.FC<{
  mapping?: RmMappingDetailsFragment;
  switchToView: () => void;
}> = ({ mapping, switchToView }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [, clearMutation] = useRmMappingClearConditionMutation();
  const [, setRuleMutation] = useRmMappingSetConditionRuleMutation();
  const [, setTermsMutation] = useRmMappingSetConditionTermsMutation();
  const conditionZero = mapping?.conditions[0];
  const [conditionType, setConditionType] = React.useState(() => {
    if (!conditionZero) {
      return ConditionType.None;
    }

    switch (conditionZero.type.__typename) {
      case "RMRuleConditionType":
        return ConditionType.Rule;
      case "RMTermsConditionType":
        return ConditionType.Terms;
    }
  });
  const [expression, setExpression] = React.useState(
    conditionZero?.type.__typename == "RMRuleConditionType"
      ? conditionZero.type.expression
      : ""
  );
  const [source, setSource] = React.useState(
    conditionZero?.type.__typename == "RMTermsConditionType"
      ? conditionZero.type.source
      : ""
  );
  const [terms, setTermsArray] = React.useState<string[]>(
    conditionZero?.type.__typename == "RMTermsConditionType"
      ? conditionZero.type.terms
      : []
  );
  const setTerms = React.useCallback(
    (terms: string) => {
      const termsSplit = terms.split("\n");
      setTermsArray(termsSplit);
    },
    [setTermsArray]
  );

  const onSave = React.useCallback(async () => {
    let response;
    switch (conditionType) {
      case ConditionType.None:
        response = await clearMutation({
          mappingId: mapping?.id ?? "",
        }).then(notifier.notifyGraphql("Condition updated"));
        break;
      case ConditionType.Rule:
        response = await setRuleMutation({
          mappingId: mapping?.id ?? "",
          expression,
        }).then(notifier.notifyGraphql("Condition updated"));
        break;
      case ConditionType.Terms:
        response = await setTermsMutation({
          mappingId: mapping?.id ?? "",
          source,
          terms: terms.map((item) => item.trim()).filter((item) => item != ""),
        }).then(notifier.notifyGraphql("Condition updated"));
        break;
    }

    const { error } = response;

    if (!error) {
      switchToView();
    }
  }, [
    mapping?.id,
    clearMutation,
    setRuleMutation,
    setTermsMutation,
    conditionType,
    expression,
    source,
    terms,
  ]);

  return (
    <div>
      <div className="my-2">
        <label>
          <input
            type="radio"
            checked={conditionType == ConditionType.None}
            onChange={() => setConditionType(ConditionType.None)}
          />{" "}
          None
        </label>
      </div>
      <div className="my-2">
        <div>
          <label>
            <input
              type="radio"
              checked={conditionType == ConditionType.Terms}
              onChange={() => setConditionType(ConditionType.Terms)}
            />{" "}
            Terms
          </label>
        </div>
        <div className="pl-5">
          <div>
            <div>Source</div>
            <div>
              <input
                type="text"
                value={source}
                onChange={(e) => setSource(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div>Terms</div>
            <div>
              <textarea
                value={terms.join("\n")}
                rows={terms.length}
                onChange={(e) => setTerms(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="my-2">
        <div>
          <label>
            <input
              type="radio"
              checked={conditionType == ConditionType.Rule}
              onChange={() => setConditionType(ConditionType.Rule)}
            />{" "}
            Rule
          </label>
        </div>
        <div className="pl-5">
          <div>
            <RuleExpressionEditor
              expression={expression}
              disabled={false}
              onChange={setExpression}
            />
          </div>
        </div>
      </div>
      <div>
        <button
          className="rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          onClick={() => onSave()}
        >
          Update
        </button>{" "}
        <button
          className="rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          onClick={() => switchToView()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RMConditionsPanel;
