import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  AccountType,
  GroupState,
  useAccountQuery,
  useEntitlementsQuery,
} from "../../graphql/generated";
import Patches from "../../patch/components/patches";
import AccountAgents from "../components/agents";
import AccountConversations from "../components/conversations";
import AccountGroups from "../components/groups";
import AccountLeads from "../components/leads";
import AccountIdentities from "../components/identities";
import AccountPayment from "../components/payment";
import AccountStatAgents from "../components/statsagents";
import AccountSummary from "../components/summary";
import AccountTitle from "../components/title";
import AccountApiKeys from "../components/apikeys";
import AccountProfile from "../components/profile";
import AccountEntitlementGrant from "../components/entitlementgrants";
import AccountEntitlements from "../components/entitlements";
import { useTitle } from "../../common/utils/title";
import AccountLenders from "../components/lenders";
import LenderAgentCollaborations from "../components/lenderagentcollaborations";
import AgentProductionSides from "../components/agent-production-sides";
import AccountOauthIdentities from "../components/identities-oauth";

const AccountShow: React.FC = () => {
  const { accountId } = useParams();
  const [{ data, error }, reexecuteQuery] = useAccountQuery({
    variables: { id: accountId ?? "" },
    pause: !accountId,
  });
  const [
    { data: entitlementsData, error: entitlementsError },
    reexecuteEntitlementsQuery,
  ] = useEntitlementsQuery({
    variables: { accountId: accountId ?? "" },
    pause: !accountId,
  });
  useTitle(data?.account.name ?? accountId, "Accounts");

  const refreshAccount = () => {
    console.log("Refetching account");
    reexecuteQuery({ requestPolicy: "network-only" });
    reexecuteEntitlementsQuery({ requestPolicy: "network-only" });
  };

  return (
    <>
      <Loading show={!data && !error && !entitlementsError} />
      <Error error={error || entitlementsError} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <AccountTitle account={data?.account} />
          <AccountSummary account={data?.account} />
          {(data?.account.accountType === AccountType.Agent ||
            data?.account.accountType === AccountType.Lender) && (
            <AccountPayment account={data?.account} />
          )}
          {(data?.account.accountType === AccountType.Agent ||
            data?.account.accountType === AccountType.Lender) && (
            <AccountEntitlementGrant grants={data?.account.entitlementGrants} />
          )}
          {data?.account.accountType === AccountType.Agent &&
            entitlementsData && (
              <AccountEntitlements
                entitlements={entitlementsData?.entitlements}
              />
            )}
          {data?.account.profile && (
            <AccountProfile
              account={data?.account}
              profile={data?.account.profile}
            />
          )}
          {data?.account.accountType == AccountType.Agent &&
            (((!!data?.account.agents?.length ||
              !data?.account.agentAccount?.agents?.length) && (
              <AccountAgents
                account={data?.account}
                linked
                refreshAccount={refreshAccount}
              />
            )) ||
              (!!data?.account.agentAccount?.agents?.length && (
                <AccountAgents
                  account={data?.account}
                  refreshAccount={refreshAccount}
                />
              )))}

          {data?.account.accountType == AccountType.Lender &&
            data?.account.lender && <AccountLenders account={data?.account} />}

          {data?.account.accountType === AccountType.Agent && (
            <AccountStatAgents
              account={data?.account}
              refreshAccount={refreshAccount}
            />
          )}

          <AccountOauthIdentities account={data?.account} />
          <AccountIdentities account={data?.account} />
          <AccountGroups
            account={data?.account}
            states={[GroupState.Active, GroupState.Pending]}
          />
          <LenderAgentCollaborations account={data?.account} />
          <AccountLeads account={data?.account} />
          <AccountConversations account={data?.account} />
          <AccountApiKeys account={data?.account} />
          <AgentProductionSides accountId={data?.account.id} />

          <Patches accountId={data?.account.id} />
        </div>
      </Transition>
    </>
  );
};

export default AccountShow;
