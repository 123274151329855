import React from "react";
import { ListingFragment } from "../../graphql/generated";
import ListingActions from "./actions";
import PageHeader from "../../common/components/page-header";

export const ListingTitle: React.FC<{
  listing?: ListingFragment;
  subtitle?: string;
}> = ({ listing, subtitle }) => {
  return (
    <PageHeader>
      <PageHeader.Title>
        {listing?.address.address1}, {listing?.address.city},{" "}
        {listing?.address.state} {listing?.address.zipCode}
        {subtitle && (
          <span className="text-sm text-gray-500 ml-1">{subtitle}</span>
        )}
      </PageHeader.Title>
      {listing && (
        <PageHeader.Actions>
          <ListingActions listing={listing} />
        </PageHeader.Actions>
      )}
    </PageHeader>
  );
};

export default ListingTitle;
