import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useReprocessDataPlanQuery } from "../../graphql/generated";
import { ReprocessDataPlanTitle } from "../components/title";
import { useTitle } from "../../common/utils/title";
import ReprocessDataPlanSummary from "../components/summary";
import ReprocessDataPlanTasks from "../components/tasks";

const ReprocessDataPlanShow: React.FC = () => {
  const params = useParams();

  const [{ data, error }] = useReprocessDataPlanQuery({
    variables: { id: params.reprocessDataPlanId ?? "" },
    pause: !params.reprocessDataPlanId,
  });

  useTitle(
    data?.reprocessDataPlan?.title ?? data?.reprocessDataPlan?.id,
    "Reprocess Data Plan"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ReprocessDataPlanTitle plan={data?.reprocessDataPlan} />
          <ReprocessDataPlanSummary plan={data?.reprocessDataPlan} />
          <ReprocessDataPlanTasks plan={data?.reprocessDataPlan} />
        </div>
      </Transition>
    </>
  );
};

export default ReprocessDataPlanShow;
