import {
  CalendarIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import AccountProfilePic from "../../account/components/profilepic";
import Timestamp from "../../common/components/timestamp";
import { ClipboardContext } from "../../common/context/clipboard";
import { IdentityFragment } from "../../graphql/generated";
import IdentityActions from "./actions";
import PageHeader from "../../common/components/page-header";

const IdentityTitle: React.FC<{ identity?: IdentityFragment }> = ({
  identity,
}) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <PageHeader>
      {identity?.account && (
        <PageHeader.Pic>
          <AccountProfilePic account={identity?.account} large />
        </PageHeader.Pic>
      )}
      <PageHeader.Title>
        {`${identity?.firstName ?? ""}${identity?.lastName ?? ""}`.trim() === ""
          ? "Empty Name"
          : `${identity?.firstName} ${identity?.lastName}`}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={EnvelopeIcon}>
          <div
            className="text-blue-900 cursor-pointer"
            onClick={() => sendToClip(identity?.email!)}
          >
            {identity?.email}
          </div>
          <Link
            to={{
              pathname: "/subetha/recipients",
              search: `?email=${identity?.email}`,
            }}
          >
            <MagnifyingGlassIcon
              className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
              aria-hidden="true"
            />
          </Link>
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Created:{" "}
          <Timestamp timestamp={identity?.createdAt} format="short" popover />
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Actions>
        <IdentityActions identity={identity} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default IdentityTitle;
