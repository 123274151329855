import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmLookupConstantMappingTypeFragment,
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
  useRmMappingUpdateLookupConstantMutation,
} from "../../../graphql/generated";
import { NotificationContext } from "../../../common/context/notification";
import {
  LookupValueSelector,
  SelectedLookupValue,
  selectedValueSpread,
} from "./LookupValueSelector";
import LoadingIcon from "../../../common/components/loadingicon";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import { DescriptionList } from "../../../common/components/descriptionlist";

const RMLookupConstantMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmLookupConstantMappingTypeFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [value, setValue] = React.useState<SelectedLookupValue>({
    state: "ignore",
    newValue: "",
  });
  const options = mapping.destinationIsLocalLookup
    ? mapping.field.localLookupValues
    : mapping.field.standardLookupValues;

  const [{ fetching }, mutation] = useRmMappingUpdateLookupConstantMutation();

  const updateValue = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const spread = selectedValueSpread(value);
    await mutation({
      mappingId: mapping.id,
      value: spread.destination,
    }).then(notifier.notifyGraphql("Mapping updated"));
  }, [fetching, mapping, value]);

  return (
    <>
      <Panel>
        <Panel.Title>Constant Lookup Mapping</Panel.Title>
        <Panel.Body>
          <DescriptionList>
            <DescriptionListContainer title="Value" span={4}>
              <LookupValueSelector
                selected={value}
                setSelected={setValue}
                options={options}
                creationIsAllowed={false}
              />{" "}
              {!fetching && <button onClick={() => updateValue()}>Set</button>}
              {fetching && <LoadingIcon />}
            </DescriptionListContainer>
          </DescriptionList>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default RMLookupConstantMappingTypeControl;
