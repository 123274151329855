import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  useEditEnterpriseAgreementMutation,
  useEnterpriseAgreementQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";

const EnterpriseAgreementEdit: React.FC = () => {
  const params = useParams();
  const [{ data: agreementData }] = useEnterpriseAgreementQuery({
    variables: { id: params.enterpriseAgreementId ?? "" },
    pause: !params.enterpriseAgreementId,
  });
  useTitle(
    "Edit",
    agreementData?.enterpriseAgreement.name ?? params.enterpriseAgreementId,
    "Enterprise Agreements"
  );

  const navigate = useNavigate();
  const { notifier } = React.useContext(NotificationContext);

  const [messageState, setMessageState] = React.useState(
    agreementData?.enterpriseAgreement.message ?? ""
  );
  const [agentStripePriceIdState, setAgentStripePriceId] = React.useState(
    agreementData?.enterpriseAgreement.agentStripePriceId ?? ""
  );
  const [enterpriseNameState, setEnterpriseName] = React.useState(
    agreementData?.enterpriseAgreement.name ?? ""
  );
  const [enterpriseBillingState, setBillingState] = React.useState(
    agreementData?.enterpriseAgreement.enterpriseBilling.__typename ?? ""
  );
  const [enterpriseStripeCustomerState, setEnterpriseStripeCustomerState] =
    React.useState("");
  const [
    enterpriseStripeSubscriptionState,
    setEnterpriseStripeSubscriptionState,
  ] = React.useState("");
  const [enterpriseAutomateBillingState, setEnterpriseAutomateBilling] =
    React.useState(false);
  const [shouldDisplayStripeEnterprise, changeDisplayStripe] = React.useState(
    agreementData?.enterpriseAgreement.enterpriseBilling.__typename ===
      "EnterpriseBillingStripeSubscription" ?? false
  );
  function changeBillingSelect(newVal: string) {
    setBillingState(newVal);
    changeDisplayStripe(newVal === "EnterpriseBillingStripeSubscription");
  }

  React.useEffect(() => {
    if (agreementData?.enterpriseAgreement) {
      setMessageState(agreementData.enterpriseAgreement.message ?? "");
      setAgentStripePriceId(
        agreementData.enterpriseAgreement.agentStripePriceId ?? ""
      );
      setEnterpriseName(agreementData.enterpriseAgreement.name ?? "");
      setBillingState(
        agreementData.enterpriseAgreement.enterpriseBilling.__typename ?? ""
      );

      if (
        agreementData.enterpriseAgreement.enterpriseBilling.__typename ===
        "EnterpriseBillingStripeSubscription"
      ) {
        setEnterpriseStripeCustomerState(
          agreementData.enterpriseAgreement.enterpriseBilling
            .billingStripeCustomerId || ""
        );
        setEnterpriseStripeSubscriptionState(
          agreementData.enterpriseAgreement.enterpriseBilling
            .billingStripeSubscriptionId || ""
        );
        setEnterpriseAutomateBilling(
          agreementData.enterpriseAgreement.enterpriseBilling
            .automaticallyAdjustStripeSubscription || false
        );
      }
    }
  }, [agreementData]);

  const [{ error, data, fetching }, mutation] =
    useEditEnterpriseAgreementMutation();

  const submitForm = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (agreementData?.enterpriseAgreement) {
        let billing_object = undefined;

        if (enterpriseBillingState === "EnterpriseBillingStripeSubscription") {
          billing_object = {
            enterpriseBillingStripeSubscription: {
              automaticallyAdjustStripeSubscription:
                enterpriseAutomateBillingState,
              billingStripeCustomerId: enterpriseStripeCustomerState,
              billingStripeSubscriptionId: enterpriseStripeSubscriptionState,
            },
          };
        } else if (enterpriseBillingState === "BilledExternalToStripe") {
          billing_object = {
            billedExternalToStripe: true,
          };
        } else if (enterpriseBillingState === "IndividualBilling") {
          billing_object = {
            individualBilling: true,
          };
        }

        mutation({
          id: agreementData.enterpriseAgreement.id,
          details: {
            agentStripePriceId: agentStripePriceIdState ?? undefined,
            message: messageState ?? undefined,
            name: enterpriseNameState ?? undefined,
            enterpriseBilling: billing_object,
          },
        });
      }
    },
    [
      agreementData,
      messageState,
      enterpriseNameState,
      agentStripePriceIdState,
      enterpriseBillingState,
      enterpriseAutomateBillingState,
      enterpriseStripeCustomerState,
      enterpriseStripeSubscriptionState,
      mutation,
    ]
  );

  React.useEffect(() => {
    if (!error && data && !fetching) {
      notifier.success("Enterprise agreement saved");
      navigate(
        `/enterprise_agreements/${agreementData?.enterpriseAgreement.id}`
      );
    } else if (error) {
      notifier.error(error);
    }
  }, [data, error, fetching, navigate, agreementData]);

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Edit Enterprise Agreement
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-white shadow overflow-hidden sm:mx-4 sm:rounded-md">
        <div className="p-4">
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={submitForm}
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="id"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      ID
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        disabled
                        required
                        type="text"
                        name="id"
                        id="id"
                        value={agreementData?.enterpriseAgreement.id ?? ""}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="name"
                        id="name"
                        value={enterpriseNameState}
                        onChange={(e) => setEnterpriseName(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="stripe"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Agent Stripe Price ID
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        required
                        type="text"
                        name="stripe"
                        id="stripe"
                        value={agentStripePriceIdState}
                        onChange={(e) => setAgentStripePriceId(e.target.value)}
                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label
                      htmlFor="billing"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Enterprise Billing
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        required
                        id="billing"
                        name="billing"
                        value={enterpriseBillingState}
                        onChange={(e) => changeBillingSelect(e.target.value)}
                        className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      >
                        {/* <option value={""}>Please Select</option> */}
                        <option value={"EnterpriseBillingStripeSubscription"}>
                          Through Stripe
                        </option>
                        <option value={"BilledExternalToStripe"}>
                          External to Stripe
                        </option>
                        <option value={"IndividualBilling"}>
                          Individual Agent Billing
                        </option>
                      </select>
                    </div>
                  </div>

                  {!shouldDisplayStripeEnterprise ? null : (
                    <>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label
                          htmlFor="stripecustomer"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Enterprise Stripe Customer Id
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="stripecustomer"
                            id="stripecustomer"
                            value={enterpriseStripeCustomerState}
                            onChange={(e) =>
                              setEnterpriseStripeCustomerState(e.target.value)
                            }
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label
                          htmlFor="stripesubscription"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Enterprise Stripe Subscription Id
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            name="stripesubscription"
                            id="stripesubscription"
                            value={enterpriseStripeSubscriptionState}
                            onChange={(e) =>
                              setEnterpriseStripeSubscriptionState(
                                e.target.value
                              )
                            }
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label
                          htmlFor="automaticallyAdjustStripe"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Automatically Adjust Stripe?
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="checkbox"
                            name="automaticallyAdjustStripe"
                            id="automaticallyAdjustStripe"
                            checked={enterpriseAutomateBillingState}
                            onChange={() =>
                              setEnterpriseAutomateBilling(
                                !enterpriseAutomateBillingState
                              )
                            }
                            className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Message
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        id="message"
                        name="message"
                        rows={10}
                        className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                        value={messageState}
                        onChange={(e) => setMessageState(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  disabled={fetching}
                  type="submit"
                  className={classNames(
                    // eslint-disable-next-line no-constant-condition
                    true
                      ? "text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      : "text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                    "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
                  )}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EnterpriseAgreementEdit;
