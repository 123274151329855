import React from "react";
import { Link } from "react-router-dom";
import { ReprocessDataPlanFragment } from "../../graphql/generated";
import ReprocessDataPlanPic from "./pic";
import Timestamp from "../../common/components/timestamp";

const ReprocessDataPlanCard: React.FC<{ plan?: ReprocessDataPlanFragment }> = ({
  plan,
}) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <ReprocessDataPlanPic plan={plan} />
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/reprocess_data_plans/${plan?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900 truncate">
            {plan?.title ?? plan?.id}
          </p>
          <p className="text-sm text-gray-500 truncate">
            <Timestamp timestamp={plan?.createdAt} format="long" />
          </p>
        </Link>
      </div>
    </div>
  );
};

export default ReprocessDataPlanCard;
