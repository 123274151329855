import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useIdentityPairQuery } from "../../graphql/generated";
import IdentityOauthSummary from "../components/summary";
import IdentityOauthTitle from "../components/title";
import { useTitle } from "../../common/utils/title";
import IdentityOauthCognitoUser from "../components/cognito-user";

const IdentityOauthShow: React.FC = () => {
  const params = useParams();

  const [{ data, error }] = useIdentityPairQuery({
    variables: { sub: params.identityId ?? "" },
    pause: !params.identityId,
  });
  useTitle(
    data?.identityPair.identityOauth?.account?.name ??
      data?.identityPair.sub ??
      params.identityId,
    "Identities"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <IdentityOauthTitle identity={data?.identityPair} />
          <IdentityOauthSummary
            sub={data?.identityPair.sub}
            identity={data?.identityPair?.identityOauth ?? undefined}
          />
          <IdentityOauthCognitoUser
            awsCognitoUser={data?.identityPair.awsCognitoUser ?? undefined}
          />
        </div>
      </Transition>
    </>
  );
};

export default IdentityOauthShow;
