import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import { shortDateFormat } from "../../common/utils/dateformat";
import { EnterpriseAgreementFragment } from "../../graphql/generated";
import EnterpriseAgreementItemActions from "./itemactions";
import PageHeader from "../../common/components/page-header";

const EnterpriseAgreementTitle: React.FC<{
  agreement?: EnterpriseAgreementFragment;
}> = ({ agreement }) => {
  return (
    <PageHeader>
      <PageHeader.Title>
        {agreement?.name.trim() === "" ? "Empty Name" : agreement?.name}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Created At: {shortDateFormat(agreement?.createdAt)}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Modified At: {shortDateFormat(agreement?.modifiedAt)}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Actions>
        <EnterpriseAgreementItemActions agreement={agreement} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default EnterpriseAgreementTitle;
