import { CalendarIcon, UserIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import Timestamp from "../../common/components/timestamp";
import { GroupFragment } from "../../graphql/generated";
import GroupActions from "./actions";
import GroupPic from "./pic";
import PageHeader from "../../common/components/page-header";

const GroupTitle: React.FC<{ group?: GroupFragment }> = ({ group }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <GroupPic group={group} large />
      </PageHeader.Pic>
      <PageHeader.Title>{group?.nameLong}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Created:{" "}
          <Timestamp timestamp={group?.createdAt} format="short" popover />
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={UserIcon}>
          Owner:{" "}
          <Link
            to={`/accounts/${group?.owner.id}`}
            className="text-blue-900 hover:underline"
          >
            {group?.owner.name}
          </Link>
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Actions>
        <GroupActions group={group} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default GroupTitle;
