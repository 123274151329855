import React, { Fragment } from "react";
import Panel from "../../common/components/panel";
import {
  ListingDataItemLookupFragment,
  ListingDataItemRecursiveFragment,
} from "../../graphql/generated";
import Timestamp from "../../common/components/timestamp";
import { classNames } from "../../common/utils/classnames";
import { Popover, Transition } from "@headlessui/react";

const localText = "text-purple-700";
const reziText = "text-green-700";
const localDecoration = "decoration-purple-600";
const reziDecoration = "decoration-green-600";

const ListingData: React.FC<{ data?: ListingDataItemRecursiveFragment[] }> = ({
  data,
}) => {
  const tableRows: React.ReactNode[] = [];
  if (data) {
    for (const item of data) {
      renderRecursive(item, tableRows);
    }
  }

  return (
    <Panel>
      <Panel.Title>Rezi Data</Panel.Title>
      <Panel.Body>
        <table className="w-full text-xs text-left">
          <tbody>{tableRows}</tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
};

function renderRecursive(
  item: ListingDataItemRecursiveFragment,
  accumulator: React.ReactNode[],
  depth = 0,
  displayPrefix?: React.ReactNode,
  keyPrefix = ""
) {
  const definedString = (
    <DefinedString
      value={item.key}
      definition={item.definition}
      rezi={!item.local}
    />
  );
  accumulator.push(
    <tr className="border-t" key={`${keyPrefix}${item.key}`}>
      <td className="p-1 align-top">
        <Indent depth={depth}>
          {displayPrefix}
          {definedString}
        </Indent>
      </td>
      <td className="p-1 align-top">
        <ListingDataValue value={item.value} />
      </td>
    </tr>
  );

  if (item.value.__typename === "ListingDataItemResourceValue") {
    for (const field of item.value.fields) {
      renderRecursive(
        field as ListingDataItemRecursiveFragment,
        accumulator,
        depth + 1,
        <>
          {displayPrefix}
          {definedString}
        </>,
        `${keyPrefix}${item.key}`
      );
    }
  }
  if (item.value.__typename === "ListingDataItemResourceListValue") {
    let count = 0;
    for (const resourceValue of item.value.items) {
      const key = `${keyPrefix}${item.key}.${count}`;
      const arrayItemDisplay = (
        <>
          {displayPrefix}
          {definedString}[{count}]
        </>
      );
      accumulator.push(
        <tr className="border-t" key={key}>
          <td className="p-1 align-top">
            <Indent depth={depth + 1}>{arrayItemDisplay}</Indent>
          </td>
          <td className="p-1 align-top">
            <span className="text-gray-400">
              {resourceValue.fields.length} fields
            </span>
          </td>
        </tr>
      );
      for (const field of resourceValue.fields) {
        renderRecursive(
          field as ListingDataItemRecursiveFragment,
          accumulator,
          depth + 2,
          <>{arrayItemDisplay}.</>,
          key
        );
      }
      count += 1;
    }
  }
}

const Indent: React.FC<{ depth: number; children?: React.ReactNode }> = ({
  depth,
  children,
}) => {
  const paddingLeft = `${depth}em`;
  return <span style={{ paddingLeft }}>{children}</span>;
};

const DefinedString: React.FC<{
  value: string;
  definition: string | null | undefined;
  rezi?: boolean;
}> = ({ value, definition, rezi }) => {
  const text = rezi ? reziText : localText;
  const decoration = rezi ? reziDecoration : localDecoration;

  if (definition) {
    return (
      <Popover className="relative inline">
        {() => (
          <>
            <Popover.Button
              className={classNames(
                "inline underline decoration-dashed underline-offset-2",
                text,
                decoration
              )}
            >
              {value}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-l w-64">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="bg-white p-4 whitespace-pre-line">
                    {definition}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  } else {
    return (
      <span className={classNames(rezi ? reziText : localText)}>{value}</span>
    );
  }
};

const LookupList: React.FC<{
  list: ListingDataItemLookupFragment[];
  rezi?: boolean;
}> = ({ list, rezi }) => {
  const x = [];
  let isFirst = true;
  for (const item of list) {
    if (isFirst) {
      isFirst = false;
    } else {
      x.push(", ");
    }
    x.push(
      <DefinedString
        key={item.key}
        value={item.key}
        rezi={rezi}
        definition={item.definition}
      />
    );
  }
  return <>{x}</>;
};

const NoneDash: React.FC = () => {
  return <span className="text-gray-300">—</span>;
};

const ListingDataValue: React.FC<{
  value: ListingDataItemRecursiveFragment["value"];
}> = ({ value }) => {
  switch (value.__typename) {
    case "ListingDataItemSchemaVersionValue":
      return <>{value.schemaVersion}</>;
    case "ListingDataItemGenericValue":
      return <>{value.generic ?? <NoneDash />}</>;
    case "ListingDataItemTextValue":
      return <>{value.text ?? <NoneDash />}</>;
    case "ListingDataItemKeyValue":
      return <>{value.key ?? <NoneDash />}</>;
    case "ListingDataItemDateValue":
      return <>{value.date ?? <NoneDash />}</>;
    case "ListingDataItemTimestampValue":
      return value.ts ? (
        <Timestamp timestamp={value.ts} format="long" popover />
      ) : (
        <NoneDash />
      );
    case "ListingDataItemBooleanValue":
      if (value.bool !== null && value.bool !== undefined) {
        return <>{value.bool.toString()}</>;
      } else {
        return <NoneDash />;
      }
    case "ListingDataItemLookupValue":
      if (value.rezi.length && value.local.length) {
        return (
          <>
            <LookupList list={value.rezi} rezi />;{" "}
            <LookupList list={value.local} />
          </>
        );
      } else if (value.rezi.length) {
        return <LookupList list={value.rezi} rezi />;
      } else if (value.local.length) {
        return <LookupList list={value.local} />;
      } else {
        return <NoneDash />;
      }
    case "ListingDataItemNumberValue":
      return value.number !== undefined && value.number !== null ? (
        <>{value.number.toLocaleString()}</>
      ) : (
        <NoneDash />
      );
    case "ListingDataItemResourceValue":
      return <NoneDash />;
    case "ListingDataItemResourceListValue":
      return value.items && value.items.length ? (
        <span className="text-gray-400">{value.items.length} items</span>
      ) : (
        <NoneDash />
      );
  }
};

export default ListingData;
