import React from "react";
import {
  EnvelopeIcon,
  ChatBubbleLeftRightIcon,
  HomeIcon,
  IdentificationIcon,
  FlagIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  UsersIcon,
  GlobeAmericasIcon,
  AtSymbolIcon,
  PaperClipIcon,
  UserIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
  BuildingLibraryIcon,
  CircleStackIcon,
  BanknotesIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import NavigationItem from "./item";

const NavigationItems: React.FC = () => {
  return (
    <>
      <NavigationItem Icon={HomeIcon} label="Dashboard" path="/" />
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-zenlist-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-zenlist-500 text-sm text-zenlist-300">
            Main
          </span>
        </div>
      </div>
      <NavigationItem label="Accounts" path="/accounts" Icon={UsersIcon} />
      <NavigationItem label="Agents" path="/agents" Icon={UserIcon} />
      <NavigationItem
        label="Identities (legacy)"
        path="/identities"
        Icon={IdentificationIcon}
      />
      <NavigationItem
        label="Identities (new)"
        path="/identities-oauth"
        Icon={IdentificationIcon}
      />
      <NavigationItem label="Groups" path="/groups" Icon={UserGroupIcon} />
      <NavigationItem label="Lenders" path="/lenders" Icon={BanknotesIcon} />
      <NavigationItem
        label="Offices"
        path="/offices"
        Icon={BuildingOfficeIcon}
      />
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-zenlist-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-zenlist-500 text-sm text-zenlist-300">
            Enterprise
          </span>
        </div>
      </div>
      <NavigationItem
        label="Brokerages"
        path="/brokerages"
        Icon={AtSymbolIcon}
      />
      <NavigationItem
        label="Enterprise Agreements"
        path="/enterprise_agreements"
        Icon={PaperClipIcon}
      />
      <NavigationItem
        label="Referrals"
        path="/referrals"
        Icon={CurrencyDollarIcon}
      />
      <NavigationItem label="MLSes" path="/mlses" Icon={BuildingLibraryIcon} />
      <NavigationItem
        label="Internal Listing Buckets"
        path="/internal_listing_buckets"
        Icon={CircleStackIcon}
      />
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-zenlist-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-zenlist-500 text-sm text-zenlist-300">
            Messaging
          </span>
        </div>
      </div>
      <NavigationItem
        Icon={ChatBubbleLeftRightIcon}
        label="Conversations"
        path="/babel/conversations"
      />
      <NavigationItem
        Icon={EnvelopeIcon}
        label="Emails"
        path="/subetha/recipients"
      />
      <NavigationItem
        Icon={DocumentTextIcon}
        label="Notes"
        path="/group_notes"
      />

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-zenlist-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-zenlist-500 text-sm text-zenlist-300">
            Tiers Related
          </span>
        </div>
      </div>
      <NavigationItem
        Icon={DocumentTextIcon}
        label="Account Subscriptions"
        path="/account_subscriptions"
      />
      <NavigationItem
        Icon={DocumentTextIcon}
        label="Products"
        path="/products"
      />
      <NavigationItem
        Icon={DocumentTextIcon}
        label="Product Offerings"
        path="/product_offerings"
      />

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-zenlist-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 bg-zenlist-500 text-sm text-zenlist-300">
            Utilities
          </span>
        </div>
      </div>
      <NavigationItem
        Icon={FlagIcon}
        label="Feature Flags"
        path="/feature_flags"
      />
      <NavigationItem
        Icon={GlobeAmericasIcon}
        label="Listings"
        path="/listings"
      />
      <NavigationItem
        Icon={RectangleGroupIcon}
        label="Resource Mapping"
        path="/resource_mapping"
      />
    </>
  );
};

export default NavigationItems;
