import {
  CalendarIcon,
  InformationCircleIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../../common/utils/dateformat";
import { SubethaMailFragment } from "../../../graphql/generated";
import MailPic from "./pic";
import PageHeader from "../../../common/components/page-header";

const MailTitle: React.FC<{ mail?: SubethaMailFragment }> = ({ mail }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <MailPic mail={mail} large />
      </PageHeader.Pic>
      <PageHeader.Title>{mail?.subject}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          {longDateFormat(mail?.createdAt)}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={InformationCircleIcon}>
          {mail?.latestEvent ?? "NOT DELIVERED"}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={EnvelopeIcon}>
          <Link
            className="hover:underline text-blue-900"
            to={`/subetha/recipients/${mail?.recipient.id}`}
          >
            {mail?.recipient.email}
          </Link>
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default MailTitle;
