import React from "react";
import {
  IngestTaskFinishMode,
  IngestTaskFragment,
  IngestTaskTarget,
} from "../../graphql/generated";
import IngestTaskItemActions from "./itemactions";
import IngestTaskPic from "./pic";
import PageHeader from "../../common/components/page-header";

export function buildTaskTitle(task?: IngestTaskFragment): string {
  return `${taskType(task?.finishMode)} task on ${
    task?.mls.shortName
  } ${taskTarget(task?.target)}`;
}

export const IngestTaskTitle: React.FC<{
  task?: IngestTaskFragment;
  refetch: () => void;
}> = ({ task, refetch }) => {
  const title = buildTaskTitle(task);

  return (
    <PageHeader>
      <PageHeader.Pic>
        <IngestTaskPic task={task} large />
      </PageHeader.Pic>
      <PageHeader.Title>{title}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem>{task?.id}</PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Actions>
        <IngestTaskItemActions task={task} refetch={refetch} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

function taskType(mode?: IngestTaskFinishMode): string {
  if (!mode) {
    return "Unknown";
  }

  switch (mode) {
    case IngestTaskFinishMode.Finish:
      return "Backfill";
    case IngestTaskFinishMode.Follow:
      return "Production";
  }
}

function taskTarget(target?: IngestTaskTarget): string {
  if (!target) {
    return "entities";
  }

  switch (target) {
    case IngestTaskTarget.Agent:
      return "agents";
    case IngestTaskTarget.BrokerTour:
      return "broker tours";
    case IngestTaskTarget.Office:
      return "offices";
    case IngestTaskTarget.Openhouse:
      return "open houses";
    case IngestTaskTarget.Property:
      return "listings";
    default:
      return "entitites";
  }
}
