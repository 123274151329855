import { Menu, Transition } from "@headlessui/react";
import {
  DocumentMagnifyingGlassIcon,
  EllipsisVerticalIcon,
  ForwardIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  RmRootResourceFragment,
  useCreateCanbyAnalysisMutation,
  useCreateReprocessDataTaskForRootResourceMutation,
  useRmRootResourceSetTimezoneMutation,
} from "../../graphql/generated";
import { useNavigate } from "react-router-dom";
import { ZenDialog } from "../../common/components/zen-dialog";

const RootResourceActions: React.FC<{
  rootResource?: RmRootResourceFragment;
}> = ({ rootResource }) => {
  const { notifier } = React.useContext(NotificationContext);
  const navigate = useNavigate();
  const [, createReprocessDataTaskMutation] =
    useCreateReprocessDataTaskForRootResourceMutation();
  const [, createCanbyAnalysisMutation] = useCreateCanbyAnalysisMutation();

  const [, setTimezoneMutation] = useRmRootResourceSetTimezoneMutation();

  const [timezoneDialogIsOpen, setTimezoneDialogIsOpen] = React.useState(false);
  const [timezone, setTimezone] = React.useState(rootResource?.timezone);

  const createReprocessDataTask = React.useCallback(async () => {
    const { data: createMutationData } = await createReprocessDataTaskMutation({
      rootResourceId: rootResource?.id || "",
    }).then(notifier.notifyGraphql());
    if (createMutationData) {
      navigate(
        `/reprocess_data_tasks/${createMutationData.createReprocessDataTaskForRootResource.id}`
      );
    }
  }, [rootResource]);

  const setTimezoneCallback = React.useCallback(async () => {
    const { error } = await setTimezoneMutation({
      id: rootResource?.id || "",
      timezone: timezone ?? "",
    }).then(notifier.notifyGraphql("Time zone updated"));
    if (!error) {
      setTimezoneDialogIsOpen(false);
    }
  }, [rootResource, timezone, setTimezoneDialogIsOpen, setTimezoneMutation]);

  const createCanbyAnalysis = React.useCallback(async () => {
    const { data } = await createCanbyAnalysisMutation({
      mlsResourceId: rootResource?.id || "",
    }).then(notifier.notifyGraphql());
    if (data) {
      navigate(`/canby/${data.createCanbyAnalysis.id}`);
    }
  }, [rootResource, createCanbyAnalysisMutation]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => createReprocessDataTask()}
                  >
                    <ForwardIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Reprocess
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setTimezoneDialogIsOpen(true)}
                  >
                    <GlobeAmericasIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Change time zone...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={!!rootResource?.canbyAnalysis}>
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => createCanbyAnalysis()}
                  >
                    <DocumentMagnifyingGlassIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Create analysis
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <ZenDialog
        show={timezoneDialogIsOpen}
        title="Change time zone"
        icon={GlobeAmericasIcon}
        submit="Change"
        onSubmit={() => setTimezoneCallback()}
        onCancel={() => setTimezoneDialogIsOpen(false)}
      >
        <div className="mt-1">
          <input
            type="text"
            value={timezone}
            onChange={(e) => setTimezone(e.target.value)}
          />
        </div>
      </ZenDialog>
    </>
  );
};

export default RootResourceActions;
