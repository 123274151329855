import React from "react";
import Panel from "../../common/components/panel";
import { ReprocessDataPlanDetailsFragment } from "../../graphql/generated";
import ReprocessDataTaskCard from "../../reprocess-data-task/components/card";

const ReprocessDataPlanTasks: React.FC<{
  plan?: ReprocessDataPlanDetailsFragment;
}> = ({ plan }) => {
  return (
    <Panel>
      <Panel.Title>Tasks</Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {plan?.tasks &&
            plan.tasks.map((task) => (
              <ReprocessDataTaskCard task={task} key={task.id} />
            ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default ReprocessDataPlanTasks;
