import React from "react";
import { useParams } from "react-router-dom";
import { useListingQuery } from "../../graphql/generated";
import Loading from "../../common/components/loading";
import ListingTitle from "../components/title";
import ListingJson from "../components/json";
import ListingSummary from "../components/summary";
import { Transition } from "@headlessui/react";
import Patches from "../../patch/components/patches";
import ListingGeolocation from "../components/geolocation";
import Error from "../../common/components/error";
import { SourceDataHistoryPanel } from "../../source/components/sourcedata";
import { useTitle } from "../../common/utils/title";
import ListingMediaPanel from "../components/media-panel";
import ListingData from "../components/data";
import ListingHistoryItemPanel from "../components/history-item-panel";

const ListingShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useListingQuery({
    variables: { id: params.listingId ?? "" },
    pause: !params.listingId,
  });
  useTitle(params.listingId, "Listings");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <ListingTitle listing={data?.listing} />
          <ListingSummary listing={data?.listing} />
          <ListingGeolocation listing={data?.listing} />
          <SourceDataHistoryPanel
            entityId={data?.listing.sourceDataEntityId}
            createdAt={data?.listing.createdAt}
            removedAt={data?.listing.removedAt}
            manuallyRemovedAt={data?.listing.manuallyRemovedAt}
          />
          <ListingMediaPanel listing={data?.listing} />
          <ListingData data={data?.listing?.data} />
          <ListingHistoryItemPanel listing={data?.listing} />

          <ListingJson listing={data?.listing} />
          <Patches listingId={data?.listing.id} />
        </div>
      </Transition>
    </>
  );
};

export default ListingShow;
