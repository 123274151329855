import React from "react";
import { Link } from "react-router-dom";
import {
  RmFieldDetailsFragment,
  RmFieldType,
  RmMappingSummaryFragment,
  useRmFieldMoveMappingAfterMutation,
  useRmFieldMoveMappingBeforeMutation,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";
import Pill from "./pill";
import { visualSpaces } from "./visual-spaces";
import BooleanMappingsActions from "./mappings-actions/boolean-mappings-actions";
import DateMappingsActions from "./mappings-actions/date-mappings-actions";
import NumberMappingsActions from "./mappings-actions/number-mappings-actions";
import TextMappingsActions from "./mappings-actions/text-mappings-actions";
import TimestampMappingsActions from "./mappings-actions/timestamp-mappings-actions";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import KeyMappingsActions from "./mappings-actions/key-mappings-actions";
import { sourceName } from "./mapping-source";
import ResourceMappingsActions from "./mappings-actions/resource-mappings-actions";

export const MappingsPanel: React.FC<{
  field?: RmFieldDetailsFragment;
  mappings?: RmMappingSummaryFragment[];
  header?: string;
}> = ({ field, mappings, header: defaultHeader }) => {
  const header = defaultHeader ?? "Mappings";
  const [isReordering, setIsReordering] = React.useState(false);

  let actions;
  if (field) {
    switch (field.type) {
      case RmFieldType.Boolean:
        actions = (
          <BooleanMappingsActions
            field={field}
            isReordering={isReordering}
            onStartReordering={() => setIsReordering(true)}
          />
        );
        break;
      case RmFieldType.Date:
        actions = (
          <DateMappingsActions
            field={field}
            isReordering={isReordering}
            onStartReordering={() => setIsReordering(true)}
          />
        );
        break;
      case RmFieldType.Number:
        actions = (
          <NumberMappingsActions
            field={field}
            isReordering={isReordering}
            onStartReordering={() => setIsReordering(true)}
          />
        );
        break;
      case RmFieldType.Text:
        actions = (
          <TextMappingsActions
            field={field}
            isReordering={isReordering}
            onStartReordering={() => setIsReordering(true)}
          />
        );
        break;
      case RmFieldType.Key:
        actions = (
          <KeyMappingsActions
            field={field}
            isReordering={isReordering}
            onStartReordering={() => setIsReordering(true)}
          />
        );
        break;
      case RmFieldType.Timestamp:
        actions = (
          <TimestampMappingsActions
            field={field}
            isReordering={isReordering}
            onStartReordering={() => setIsReordering(true)}
          />
        );
        break;
      case RmFieldType.Lookup:
        // Lookups don't actually take this code path. Instead they use
        // MappingsUl directly as a part of their rezi/local side split view.
        break;
      case RmFieldType.Resource:
        // We don't support mapping resources right now.
        break;
      case RmFieldType.ResourceList:
        actions = (
          <ResourceMappingsActions
            field={field}
            isReordering={isReordering}
            onStartReordering={() => setIsReordering(true)}
          />
        );
        break;
    }
  }

  return (
    <Panel>
      <Panel.Title actions={actions}>{header}</Panel.Title>
      <Panel.Body>
        <MappingsUl
          mappings={mappings}
          isReordering={isReordering}
          onReorderingFinished={() => setIsReordering(false)}
        />
      </Panel.Body>
    </Panel>
  );
};

export const MappingsUl: React.FC<{
  isReordering?: boolean;
  mappings?: RmMappingSummaryFragment[];
  onReorderingFinished?: () => void;
}> = ({ isReordering, mappings, onReorderingFinished }) => {
  if (isReordering) {
    return (
      <MappingsUlReordering
        mappings={mappings}
        onReorderingFinished={onReorderingFinished}
      />
    );
  } else {
    return <MappingsUlStatic mappings={mappings} />;
  }
};

const MappingsUlStatic: React.FC<{
  mappings?: RmMappingSummaryFragment[];
}> = ({ mappings }) => {
  if (mappings && mappings.length) {
    return (
      <ul className="list-disc list-inside">
        {mappings.map((mapping) => {
          return (
            <li key={mapping.id}>
              <Link to={`/resource_mapping/mappings/${mapping.id}`}>
                <MappingSummaryDetails mapping={mapping} />
                {!mapping.isEnabled && (
                  <Pill bgColor="bg-amber-600">disabled</Pill>
                )}
                {!mapping.reportProblems && (
                  <Pill bgColor="bg-amber-600">reporting disabled</Pill>
                )}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return <i>None</i>;
  }
};

const MappingsUlReordering: React.FC<{
  mappings?: RmMappingSummaryFragment[];
  onReorderingFinished?: () => void;
}> = ({ mappings, onReorderingFinished }) => {
  if (!mappings || !mappings.length) {
    return <i>None</i>;
  }

  const { notifier } = React.useContext(NotificationContext);
  const [{ fetching: moveMappingBeforeFetching }, moveMappingBefore] =
    useRmFieldMoveMappingBeforeMutation();
  const [{ fetching: moveMappingAfterFetching }, moveMappingAfter] =
    useRmFieldMoveMappingAfterMutation();

  const anyMutationIsFetching =
    moveMappingBeforeFetching || moveMappingAfterFetching;

  const onDragEnd = React.useCallback(
    async (result: DropResult) => {
      // Current update happening
      if (anyMutationIsFetching) {
        return;
      }

      // dropped outside the list
      if (!result.destination) {
        return;
      }

      // Moved to the same place
      if (result.source.index == result.destination.index) {
        return;
      }

      const mappingId = mappings[result.source.index].id;

      if (result.destination.index > result.source.index) {
        // Item moved down. We want to move it after ...
        const afterId = mappings[result.destination.index].id;
        await moveMappingAfter({
          mappingId,
          afterId,
        }).then(notifier.notifyGraphql("Moved"));
      } else {
        // Item moved up. We want to move it before ...
        const beforeId = mappings[result.destination.index].id;
        await moveMappingBefore({
          mappingId,
          beforeId,
        }).then(notifier.notifyGraphql("Moved"));
      }
    },
    [anyMutationIsFetching, moveMappingBefore, moveMappingAfter, mappings]
  );

  function getDraggableStyle(
    isDragging: boolean,
    style: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties {
    if (isDragging) {
      return {
        background: "rgba(255, 255, 255, 0.8)",
        ...style,
      };
    } else {
      return {
        ...style,
      };
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="mappings">
          {(provided) => (
            <div
              className={classNames(anyMutationIsFetching ? "opacity-25" : "")}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {mappings.map((mapping, idx) => {
                return (
                  <Draggable
                    key={mapping.id}
                    draggableId={mapping.id}
                    index={idx}
                    isDragDisabled={anyMutationIsFetching}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getDraggableStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ChevronUpDownIcon className="w-4 h-4 inline rounded-full bg-gray-200" />{" "}
                        <MappingSummaryDetails mapping={mapping} />
                        {!mapping.isEnabled && (
                          <Pill bgColor="bg-amber-600">disabled</Pill>
                        )}
                        {!mapping.reportProblems && (
                          <Pill bgColor="bg-amber-600">reporting disabled</Pill>
                        )}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
        onClick={() => onReorderingFinished && onReorderingFinished()}
      >
        Done
      </button>
    </>
  );
};

function destinationName(destination: string | undefined): string | undefined {
  return visualSpaces(destination);
}

const MappingSummaryDetails: React.FC<{
  mapping: RmMappingSummaryFragment;
}> = ({ mapping }) => {
  let prefix = <></>;
  if (mapping.name) {
    prefix = <>{mapping.name} &mdash; </>;
  }
  switch (mapping.type.__typename) {
    case "RMBooleanLookupMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMDateBasicMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMLookupBasicMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
          <span className="text-xs text-gray-400">
            .{mapping.destinationIsLocalLookup ? "local" : "rezi"}
          </span>
        </>
      );
    case "RMLookupPriorityMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
          <span className="text-xs text-gray-400">
            .{mapping.destinationIsLocalLookup ? "local" : "rezi"}
          </span>
        </>
      );
    case "RMLookupTwoSourceMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.primarySource)} +{" "}
          {sourceName(mapping.type.secondarySource)} →{" "}
          {destinationName(mapping.field.name)}
          <span className="text-xs text-gray-400">
            .{mapping.destinationIsLocalLookup ? "local" : "rezi"}
          </span>
        </>
      );
    case "RMLookupConstantMappingType":
      return (
        <>
          {prefix}
          {mapping.type.value ? (
            JSON.stringify(mapping.type.value)
          ) : (
            <span className="text-gray-400">null</span>
          )}{" "}
          → {destinationName(mapping.field.name)}
          <span className="text-xs text-gray-400">
            .{mapping.destinationIsLocalLookup ? "local" : "rezi"}
          </span>
        </>
      );
    case "RMNumberBasicMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMRuleMappingType":
      return (
        <>
          {prefix}
          &lt;code expression&gt; → {destinationName(mapping.field.name)}
          {mapping.destinationIsLocalLookup && (
            <span className="text-xs text-gray-400">.local</span>
          )}
        </>
      );
    case "RMTextBasicMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMTextFlattenMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMKeyBasicMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMTimestampBasicMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMResourceBasicMappingType":
      return (
        <>
          {prefix}
          {sourceName(mapping.type.source)} →{" "}
          {destinationName(mapping.field.name)}
        </>
      );
    case "RMTodoMappingType":
      return <>{prefix}(TODO: An unsupported mapping type)</>;
  }
};
