import {
  IdentificationIcon,
  BuildingLibraryIcon,
  EnvelopeIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { ClipboardContext } from "../../common/context/clipboard";
import { AgentQuery } from "../../graphql/generated";
import AgentItemActions from "./itemactions";
import AgentPic from "./pic";
import PageHeader from "../../common/components/page-header";

const AgentTitle: React.FC<{ agent?: AgentQuery["agent"] }> = ({ agent }) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <PageHeader>
      <PageHeader.Pic>
        <AgentPic agent={agent} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {agent?.name.trim() === "" ? "Empty Name" : agent?.name}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={IdentificationIcon}>
          {agent?.mlsId}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={BuildingLibraryIcon}>
          {agent?.mls?.name}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={EnvelopeIcon}>
          <div
            className="text-blue-900 cursor-pointer"
            onClick={() => sendToClip(agent?.email!)}
          >
            {agent?.email}
          </div>
          <Link
            to={{
              pathname: "/subetha/recipients",
              search: `?email=${agent?.email}`,
            }}
          >
            <MagnifyingGlassIcon
              className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
              aria-hidden="true"
            />
          </Link>
        </PageHeader.DetailsItem>
        {agent?.phone && (
          <PageHeader.DetailsItem icon={PhoneIcon}>
            <a href={`tel:${agent?.phone}`}>{agent?.phone}</a>
          </PageHeader.DetailsItem>
        )}
      </PageHeader.Details>
      <PageHeader.Actions>
        <AgentItemActions agent={agent} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default AgentTitle;
