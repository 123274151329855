import { Transition } from "@headlessui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useRmMlsQuery } from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import { Navigation, NavigationItems } from "../components/nav";
import ResourceMappingTitle from "../components/resource-mapping-title";
import Panel from "../../common/components/panel";

const RMMls: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useRmMlsQuery({
    variables: { id: params.mlsId ?? "" },
  });
  useTitle(data?.mls.shortName, "Resource Mapping");

  const nav = NavigationItems.withHome();
  nav.addMls(data?.mls.shortName, data?.mls.id);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle name={data?.mls.shortName}>
            MLS
          </ResourceMappingTitle>
          <Panel>
            <Panel.Body>
              <ul className="list-disc list-inside">
                {data?.mls.resourceMappings.map((resourceMapping) => (
                  <li key={resourceMapping.id}>
                    <Link to={`/resource_mapping/roots/${resourceMapping.id}`}>
                      {resourceMapping.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Body>
              <ul className="list-disc list-inside">
                <li key="statuses">
                  <Link to={`/resource_mapping/mlses/${params.mlsId}/statuses`}>
                    Statuses
                  </Link>
                </li>
                <li key="property_types">
                  <Link
                    to={`/resource_mapping/mlses/${params.mlsId}/property_types`}
                  >
                    Property Types
                  </Link>
                </li>
              </ul>
            </Panel.Body>
          </Panel>
        </div>
      </Transition>
    </>
  );
};

export default RMMls;
