import {
  IdentificationIcon,
  EnvelopeIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { ClipboardContext } from "../../common/context/clipboard";
import { LenderQuery } from "../../graphql/generated";
import LenderPic from "./pic";
import PageHeader from "../../common/components/page-header";

const LenderTitle: React.FC<{ lender?: LenderQuery["lender"] }> = ({
  lender,
}) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <PageHeader>
      <PageHeader.Pic>
        <LenderPic lender={lender} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {lender?.name === " " ? "Empty Name" : lender?.name}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={IdentificationIcon}>
          {lender?.nmlsId}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={EnvelopeIcon}>
          <div
            className="text-blue-900 cursor-pointer"
            onClick={() => sendToClip(lender?.email!)}
          >
            {lender?.email}
          </div>
          <Link
            to={{
              pathname: "/subetha/recipients",
              search: `?email=${lender?.email}`,
            }}
          >
            <MagnifyingGlassIcon
              className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
              aria-hidden="true"
            />
          </Link>
        </PageHeader.DetailsItem>
        {lender?.phoneNumber && (
          <PageHeader.DetailsItem icon={PhoneIcon}>
            <a href={`tel:${lender?.phoneNumber}`}>{lender?.phoneNumber}</a>
          </PageHeader.DetailsItem>
        )}
      </PageHeader.Details>
    </PageHeader>
  );
};

export default LenderTitle;
