import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { IdentityOauthFragment } from "../../graphql/generated";
import { DescriptionList } from "../../common/components/descriptionlist";
import IdentityOauthItemActions from "./item-actions";
import { useSearchParams } from "react-router-dom";
import MissingIdentityOauthActions from "./missing-identity-oauth-actions";

const IdentityOauthSummary: React.FC<{
  sub?: string;
  identity?: IdentityOauthFragment;
}> = ({ sub, identity }) => {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("account");

  if (!identity) {
    return (
      <Panel>
        <Panel.Title
          actions={
            <MissingIdentityOauthActions
              sub={sub}
              accountId={accountId ?? undefined}
            />
          }
        >
          Zenlist data
        </Panel.Title>
        <Panel.Body>
          <i>Not found</i>
        </Panel.Body>
      </Panel>
    );
  }

  return (
    <Panel>
      <Panel.Title actions={<IdentityOauthItemActions identity={identity} />}>
        Zenlist data
      </Panel.Title>
      <Panel.Body summary>
        <DescriptionList>
          <DescriptionListItem title="Sub" value={identity?.id} />
          <DescriptionListItem title="Email" value={identity?.email} />
          <DescriptionListItem
            title="Account"
            value={identity?.account?.name}
            link={`/accounts/${identity?.account?.id}`}
          />
          <DescriptionListItem
            title="Impersonation"
            value={identity?.impersonation ? "Yes" : "No"}
          />
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default IdentityOauthSummary;
