import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import {
  BellIcon,
  CheckIcon,
  CloudArrowDownIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PencilIcon,
  ArrowPathIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../common/context/notification";
import ChangeDisplayNameDialog from "./change-display-name-dialog";
import { classNames } from "../../common/utils/classnames";
import { shortDateFormat } from "../../common/utils/dateformat";
import {
  IngestTaskTarget,
  InheritMode,
  OfficeFragment,
  useCreateBrokerageForOfficeMutation,
  useEnterpriseAgreementsQuery,
  useUpdateEnterpriseAgreementForOfficeMutation,
  useUpdateDisplayNameForOfficeMutation,
  useCreateIngestTaskForPrimaryKeyMutation,
  useAuthorizeOfficeMutation,
  useDeauthorizeOfficeMutation,
  useEnableOfficeMutation,
  useDisableOfficeMutation,
  useUpdateAuthDocForOfficeMutation,
  ExternalCommunicationState,
  useUpdateExternalCommunicationForOfficeMutation,
  ListingQueryPermissionValue,
  useUpdateListingQueryPermissionForOfficeMutation,
  useUpdateAgentMoveNotificationForOfficeMutation,
  AgentMoveNotificationValue,
} from "../../graphql/generated";
import ExternalCommunicationDialog from "./external-communication-dialog";
import ListingQueryPermissionDialog from "./listing-query-permission-dialog";
import AgentMoveNotificationDialog from "./agent-move-notification-dialog";

const OfficeActions: React.FC<{ office?: OfficeFragment }> = ({ office }) => {
  const [open, setOpen] = React.useState(false);
  const [displayNameOpen, setDisplayNameOpen] = React.useState(false);
  const [externalCommunicationOpen, setExternalCommunicationOpen] =
    React.useState(false);
  const [listingQueryPermissionOpen, setListingQueryPermissionOpen] =
    React.useState(false);
  const [agentMoveNotificationOpen, setAgentMoveNotificationOpen] =
    React.useState(false);
  const [{ fetching }, mutation] = useCreateBrokerageForOfficeMutation();
  const [
    { fetching: createIngestTaskForPrimaryKeyFetching },
    createIngestTaskForPrimaryKeyMutation,
  ] = useCreateIngestTaskForPrimaryKeyMutation();
  const navigate = useNavigate();

  const [docOpen, setDocOpen] = React.useState(false);
  const [doc, setDoc] = React.useState(office?.authorizationDocument);

  const [selected, setSelected] = React.useState<string>(
    office?.enterpriseAgreement?.id ?? ""
  );
  const { notifier } = React.useContext(NotificationContext);

  const [{ data }] = useEnterpriseAgreementsQuery({
    variables: {},
  });

  const [, eaMutation] = useUpdateEnterpriseAgreementForOfficeMutation();
  const [, updateDisplayNameForOfficeMutation] =
    useUpdateDisplayNameForOfficeMutation();

  const inheritAgreement = React.useCallback(async () => {
    await eaMutation({
      officeId: office?.id ?? "",
      mode: InheritMode.Auto,
    }).then(notifier.notifyGraphql("Agent inheriting enterprise agreement."));
    setOpen(false);
  }, [office?.id, eaMutation]);

  const excludeAgreement = React.useCallback(async () => {
    await eaMutation({
      officeId: office?.id ?? "",
      mode: InheritMode.ManualNone,
    }).then(
      notifier.notifyGraphql("Agent excluded from enterprise agreement.")
    );
  }, [office?.id, eaMutation]);

  const linkAgreement = React.useCallback(async () => {
    await eaMutation({
      officeId: office?.id ?? "",
      mode: InheritMode.Manual,
      enterpriseAgreementId: selected,
    }).then(notifier.notifyGraphql("Agent linked to enterprise agreement."));
  }, [office?.id, eaMutation, selected]);

  const updateDisplayNameAuto = React.useCallback(async () => {
    await updateDisplayNameForOfficeMutation({
      officeId: office?.id ?? "",
      mode: InheritMode.Auto,
    }).then(
      notifier.notifyGraphql("Display name inherited from parent office.")
    );
    setDisplayNameOpen(false);
  }, [office?.id, updateDisplayNameForOfficeMutation, setDisplayNameOpen]);

  const updateDisplayNameManualNone = React.useCallback(async () => {
    await updateDisplayNameForOfficeMutation({
      officeId: office?.id ?? "",
      mode: InheritMode.ManualNone,
    }).then(notifier.notifyGraphql("Display name set to MLS name."));
    setDisplayNameOpen(false);
  }, [office?.id, updateDisplayNameForOfficeMutation, setDisplayNameOpen]);

  const updateDisplayNameManual = async (value: string) => {
    await updateDisplayNameForOfficeMutation({
      officeId: office?.id ?? "",
      mode: InheritMode.Manual,
      value: value,
    }).then(notifier.notifyGraphql("Display name set."));
    setDisplayNameOpen(false);
  };

  const [, updateExternalCommunicationForOfficeMutation] =
    useUpdateExternalCommunicationForOfficeMutation();

  const updateExternalCommunication = React.useCallback(
    async (mode: InheritMode, value?: ExternalCommunicationState) => {
      await updateExternalCommunicationForOfficeMutation({
        officeId: office?.id ?? "",
        mode,
        value,
      }).then(notifier.notifyGraphql("External communication mode updated."));
      setExternalCommunicationOpen(false);
    },
    [
      office?.id,
      updateExternalCommunicationForOfficeMutation,
      setExternalCommunicationOpen,
    ]
  );

  const [, updateListingQueryPermissionForOfficeMutation] =
    useUpdateListingQueryPermissionForOfficeMutation();

  const updateListingQueryPermission = React.useCallback(
    async (mode: InheritMode, value?: ListingQueryPermissionValue) => {
      await updateListingQueryPermissionForOfficeMutation({
        officeId: office?.id ?? "",
        mode,
        value,
      }).then(notifier.notifyGraphql("Listing query permission updated."));
      setListingQueryPermissionOpen(false);
    },
    [
      office?.id,
      updateListingQueryPermissionForOfficeMutation,
      setListingQueryPermissionOpen,
    ]
  );

  const [, updateAgentMoveNotificationForOfficeMutation] =
    useUpdateAgentMoveNotificationForOfficeMutation();

  const updateAgentMoveNotification = React.useCallback(
    async (mode: InheritMode, value?: AgentMoveNotificationValue) => {
      await updateAgentMoveNotificationForOfficeMutation({
        officeId: office?.id ?? "",
        mode,
        value,
      }).then(notifier.notifyGraphql("Agent move notification updated."));
      setAgentMoveNotificationOpen(false);
    },
    [
      office?.id,
      updateAgentMoveNotificationForOfficeMutation,
      setAgentMoveNotificationOpen,
    ]
  );

  const createBrokerage = React.useCallback(async () => {
    if (fetching) {
      return;
    }
    const { data, error } = await mutation({ id: office?.id ?? "" }).then(
      notifier.notifyGraphql(`Created brokerage for ${office?.name}`)
    );
    if (!error) {
      navigate(`/brokerages/${data?.createBrokerageForOffice.id}`);
    }
  }, [fetching, mutation, navigate, office]);

  const createIngestTaskForPrimaryKey = React.useCallback(async () => {
    if (createIngestTaskForPrimaryKeyFetching) {
      return;
    }
    const { data, error } = await createIngestTaskForPrimaryKeyMutation({
      id: office?.id ?? "",
      mlsId: office?.mls?.id ?? "",
      target: IngestTaskTarget.Office,
    }).then(notifier.notifyGraphql(`Created ingest task for ${office?.name}`));
    if (!error) {
      navigate(`/ingest_tasks/${data?.createIngestTaskForPrimaryKey.id}`);
    }
  }, [
    createIngestTaskForPrimaryKeyFetching,
    createIngestTaskForPrimaryKeyMutation,
    navigate,
    office,
  ]);

  const [, authMutation] = useAuthorizeOfficeMutation();

  const authorizeOffice = React.useCallback(async () => {
    await authMutation({
      id: office?.id ?? "",
    }).then(notifier.notifyGraphql("Office authorized."));
  }, [office?.id, authMutation]);

  const [, deauthMutation] = useDeauthorizeOfficeMutation();

  const deauthorizeOffice = React.useCallback(async () => {
    await deauthMutation({
      id: office?.id ?? "",
    }).then(notifier.notifyGraphql("Office deauthorized."));
  }, [office?.id, deauthMutation]);

  const [, enableMutation] = useEnableOfficeMutation();

  const enableOffice = React.useCallback(async () => {
    await enableMutation({
      id: office?.id ?? "",
    }).then(notifier.notifyGraphql("Office enabled."));
  }, [office?.id, enableMutation]);

  const [, disableMutation] = useDisableOfficeMutation();

  const disableOffice = React.useCallback(async () => {
    await disableMutation({
      id: office?.id ?? "",
    }).then(notifier.notifyGraphql("Office disabled."));
  }, [office?.id, disableMutation]);

  const [, updateDocMutation] = useUpdateAuthDocForOfficeMutation();

  const updateDoc = React.useCallback(async () => {
    if (doc !== office?.authorizationDocument) {
      await updateDocMutation({
        id: office?.id ?? "",
        newDoc: doc ?? "",
      }).then(notifier.notifyGraphql("Authorization Document Updated"));
      setDocOpen(false);
    }
  }, [office?.id, updateDocMutation, office?.authorizationDocument, doc]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update Enterprise Agreement
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setDisplayNameOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update display name
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setExternalCommunicationOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <EnvelopeIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update external communication
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setListingQueryPermissionOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <MagnifyingGlassIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update listing query permission
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setAgentMoveNotificationOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <BellIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update agent move notification
                  </div>
                )}
              </Menu.Item>
              {office?.enabled ? (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={disableOffice}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Disable Signup
                    </div>
                  )}
                </Menu.Item>
              ) : (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={enableOffice}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Enable Signup
                    </div>
                  )}
                </Menu.Item>
              )}
              {office?.authorized ? (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={deauthorizeOffice}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Deauthorize Office
                    </div>
                  )}
                </Menu.Item>
              ) : (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={authorizeOffice}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Authorize Office
                    </div>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setDocOpen(!docOpen)}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Update Authorization Document
                  </div>
                )}
              </Menu.Item>
              {office?.canCreateBrokerage && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={createBrokerage}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <ArrowPathIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Create Brokerage
                    </div>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={createIngestTaskForPrimaryKey}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <CloudArrowDownIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Re-ingest Office from MLS
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-none">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Enterprise Agreement
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1  flex flex-col overflow-scroll">
                      <div className="px-4 sm:px-6 py-4 sm:py-6 mt-4 grow overflow-scroll">
                        <RadioGroup value={selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only">
                            Agent
                          </RadioGroup.Label>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {data?.enterpriseAgreements.map((agreement) => (
                              <RadioGroup.Option
                                key={agreement?.id}
                                value={agreement?.id}
                                className={({ checked, active }) =>
                                  classNames(
                                    checked
                                      ? "border-transparent"
                                      : "border-gray-300",
                                    active ? "ring-2 ring-indigo-500" : "",
                                    "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex items-center">
                                      <div className="text-sm">
                                        <RadioGroup.Label
                                          as="div"
                                          className="font-medium text-gray-900"
                                        >
                                          {agreement?.name}{" "}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="div"
                                          className="text-gray-500"
                                        >
                                          <p className="sm:inline">
                                            {shortDateFormat(
                                              agreement?.createdAt
                                            )}
                                          </p>{" "}
                                          <span
                                            className="hidden sm:inline sm:mx-1"
                                            aria-hidden="true"
                                          >
                                            &middot;
                                          </span>{" "}
                                          <p className="sm:inline">
                                            {agreement?.agentStripePriceId}
                                          </p>
                                        </RadioGroup.Description>
                                      </div>
                                    </div>
                                    <div
                                      className={classNames(
                                        active ? "border" : "border-2",
                                        checked
                                          ? "border-indigo-500"
                                          : "border-transparent",
                                        "absolute -inset-px rounded-lg pointer-events-none"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="pt-5 px-4 sm:px-6">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setOpen(false)}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </button>
                          {office?.enterpriseAgreementMode !==
                            InheritMode.Auto && (
                            <button
                              onClick={inheritAgreement}
                              type="submit"
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Inherit From Office
                            </button>
                          )}
                          {office?.enterpriseAgreementMode !==
                            InheritMode.ManualNone && (
                            <button
                              onClick={excludeAgreement}
                              type="submit"
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                              Exclude From Office Agreement
                            </button>
                          )}
                          <button
                            onClick={linkAgreement}
                            disabled={!selected}
                            type="submit"
                            className={classNames(
                              selected
                                ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
                                : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
                              "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                            )}
                          >
                            Link Selected
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ChangeDisplayNameDialog
        initialValue={office?.displayName ?? office?.name ?? ""}
        isOpen={displayNameOpen}
        onClose={() => setDisplayNameOpen(false)}
        onAuto={updateDisplayNameAuto}
        onManual={(displayName: string) => updateDisplayNameManual(displayName)}
        onManualNone={updateDisplayNameManualNone}
      />
      <ExternalCommunicationDialog
        isOpen={externalCommunicationOpen}
        onClose={() => setExternalCommunicationOpen(false)}
        onAuto={() => updateExternalCommunication(InheritMode.Auto)}
        onManual={(value: ExternalCommunicationState) =>
          updateExternalCommunication(InheritMode.Manual, value)
        }
      />
      <ListingQueryPermissionDialog
        isOpen={listingQueryPermissionOpen}
        onClose={() => setListingQueryPermissionOpen(false)}
        onAuto={() => updateListingQueryPermission(InheritMode.Auto)}
        onManual={(value: ListingQueryPermissionValue) =>
          updateListingQueryPermission(InheritMode.Manual, value)
        }
      />
      <AgentMoveNotificationDialog
        isOpen={agentMoveNotificationOpen}
        onClose={() => setAgentMoveNotificationOpen(false)}
        onAuto={() => updateAgentMoveNotification(InheritMode.Auto)}
        onManual={(value: AgentMoveNotificationValue) =>
          updateAgentMoveNotification(InheritMode.Manual, value)
        }
      />
      <Transition.Root show={docOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setDocOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Update Authorization Document
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="mt-1">
                        <input
                          type="url"
                          name="slug"
                          id="slug"
                          value={doc ?? ""}
                          onChange={(e) => setDoc(e.target.value)}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Link To Authorization Document"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    onClick={updateDoc}
                  >
                    Update Authorization Document
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default OfficeActions;
