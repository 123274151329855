import React from "react";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const SplitTaskDialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (count: number) => void;
  isFetching: boolean;
}> = ({ isOpen, onClose, onSubmit, isFetching }) => {
  const [count, setCount] = React.useState<number | undefined>(2);

  const isValid = count !== undefined && count >= 2;

  return (
    <ZenDialog
      icon={DocumentDuplicateIcon}
      onCancel={onClose}
      onSubmit={() => onSubmit(count ?? 0)}
      title="Split task"
      submit="Split"
      show={isOpen}
      state={
        isFetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="space-y-2">
        <p>Split this task into several approximately equally sized tasks.</p>
        <p>
          This task will be abandoned and a number of duplicates equal to the
          number below will be created.
        </p>
        <p>
          <input
            type="number"
            value={count}
            onChange={(e) => {
              const parsed = parseInt(e.target.value, 10);
              setCount(Number.isNaN(parsed) ? undefined : parsed);
            }}
          />
        </p>
      </div>
    </ZenDialog>
  );
};

export default SplitTaskDialog;
