import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import Timestamp from "../../common/components/timestamp";
import { ReprocessDataPlanDetailsFragment } from "../../graphql/generated";
import { DescriptionList } from "../../common/components/descriptionlist";

const ReprocessDataPlanSummary: React.FC<{
  plan?: ReprocessDataPlanDetailsFragment;
}> = ({ plan }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <DescriptionList>
          <DescriptionListItem title="ID" value={plan?.id} />
          <DescriptionListContainer
            title="Status"
            info="Whether the plan is enabled or disabled"
          >
            <StatusComponent entity={plan} />
          </DescriptionListContainer>
          <DescriptionListContainer title="Created at">
            <Timestamp format="long" timestamp={plan?.createdAt} popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Modified at">
            <Timestamp format="long" timestamp={plan?.modifiedAt} popover />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Target concurrency"
            info="The number of tasks this plan will try to keep active at any given time"
            value={plan?.targetConcurrency}
          />
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default ReprocessDataPlanSummary;
