import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../common/utils/classnames";
import {
  RmFieldType,
  useRmResourceAddFieldMutation,
} from "../../graphql/generated";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import { NotificationContext } from "../../common/context/notification";
import { useNavigate } from "react-router-dom";

const FieldsActions: React.FC<{ resourceId: string }> = ({ resourceId }) => {
  const { notifier } = React.useContext(NotificationContext);
  const navigate = useNavigate();
  const [addFieldDialogIsOpen, setFieldDialogIsOpen] = React.useState(false);

  const [{ fetching: addFieldFetching }, addFieldMutation] =
    useRmResourceAddFieldMutation();

  const addFieldCallback = React.useCallback(
    async (fieldName: string, fieldType: RmFieldType) => {
      const { data } = await addFieldMutation({
        id: resourceId ?? "",
        fieldName,
        fieldType,
      }).then(notifier.notifyGraphql());
      if (data) {
        navigate(`/resource_mapping/fields/${data.rmResourceAddField.id}`);
      }
    },
    [resourceId, addFieldMutation]
  );

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setFieldDialogIsOpen(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add field...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddFieldDialog
        show={addFieldDialogIsOpen}
        onCancel={() => setFieldDialogIsOpen(false)}
        onSubmit={addFieldCallback}
        fetching={addFieldFetching}
      />
    </>
  );
};

const AddFieldDialog: React.FC<{
  show?: boolean;
  onCancel: () => void;
  onSubmit: (fieldName: string, fieldType: RmFieldType) => void;
  fetching?: boolean;
}> = ({ show, onCancel, onSubmit, fetching }) => {
  const [fieldName, setFieldName] = React.useState("");
  const [fieldType, setFieldType] = React.useState(RmFieldType.Boolean);

  const fieldNameId = React.useId();
  const fieldTypeId = React.useId();

  const oldMlsPrefixRegex = /^[A-Z0-9]+_/; // Tries to match things like MRED_LocalFieldName
  let oldMlsPrefixSuggestion;
  if (oldMlsPrefixRegex.test(fieldName)) {
    oldMlsPrefixSuggestion = fieldName.replace(oldMlsPrefixRegex, "");
  }

  const onlyContainsLettersAndNumbersRegex = /[^A-z0-9]/g;
  let onlyContainsLettersAndNumbersSuggestion;
  if (
    !oldMlsPrefixSuggestion &&
    onlyContainsLettersAndNumbersRegex.test(fieldName)
  ) {
    onlyContainsLettersAndNumbersSuggestion = fieldName.replace(
      onlyContainsLettersAndNumbersRegex,
      ""
    );
  }

  const commonSuffixes: { [index: string]: string } = {
    [RmFieldType.Boolean]: "YN",
    [RmFieldType.Date]: "Date",
    [RmFieldType.Timestamp]: "Timestamp",
  };

  const fieldNameErrors = [];
  for (const checkFieldType in commonSuffixes) {
    if (
      checkFieldType == fieldType &&
      !fieldName.endsWith(commonSuffixes[checkFieldType])
    ) {
      fieldNameErrors.push({ positive: true, type: checkFieldType });
    }

    if (
      checkFieldType != fieldType &&
      fieldName.endsWith(commonSuffixes[checkFieldType])
    ) {
      fieldNameErrors.push({ positive: false, type: checkFieldType });
    }
  }

  const fieldNameIsEmpty = fieldName.length === 0;

  const isValid =
    !fieldNameIsEmpty &&
    !onlyContainsLettersAndNumbersSuggestion &&
    !oldMlsPrefixSuggestion &&
    fieldNameErrors.length === 0;

  return (
    <ZenDialog
      show={show}
      title="Add field"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit(fieldName, fieldType)}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <div>
          <label htmlFor={fieldNameId}>Name</label>
        </div>
        <div>
          <input
            id={fieldNameId}
            type="text"
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
          />
        </div>

        {fieldNameIsEmpty && (
          <div className="mt-1">
            <ExclamationCircleIcon className="w-4 h-4 text-red-500 inline-block" />{" "}
            A field name is required.
          </div>
        )}
        {oldMlsPrefixSuggestion && (
          <div className="mt-1">
            <ExclamationCircleIcon className="w-4 h-4 text-red-500 inline-block" />{" "}
            MLS prefixes are no longer necessary. Consider naming the field{" "}
            <span className="font-mono">{oldMlsPrefixSuggestion}</span>.
          </div>
        )}
        {onlyContainsLettersAndNumbersSuggestion && (
          <div className="mt-1">
            <ExclamationCircleIcon className="w-4 h-4 text-red-500 inline-block" />{" "}
            Field names may only contain letters and numbers. Consider naming
            the field{" "}
            <span className="font-mono">
              {onlyContainsLettersAndNumbersSuggestion}
            </span>
            .
          </div>
        )}
        {!fieldNameIsEmpty &&
          fieldNameErrors.map(({ positive, type }) => {
            const suffix = commonSuffixes[type];
            if (positive) {
              return (
                <div className="mt-1">
                  <ExclamationCircleIcon className="w-4 h-4 text-red-500 inline-block" />{" "}
                  Fields of type '{type}' typically end with '{suffix}'.
                  Consider naming the field{" "}
                  <span className="font-mono">
                    {fieldName}
                    {suffix}
                  </span>
                  .
                </div>
              );
            } else {
              const newFieldName = fieldName.substring(
                0,
                fieldName.length - suffix.length
              );
              return (
                <div className="mt-1">
                  <ExclamationCircleIcon className="w-4 h-4 text-red-500 inline-block" />{" "}
                  Fields of type '{fieldType}' should not end with '{suffix}'.
                  Consider naming the field{" "}
                  <span className="font-mono">{newFieldName}</span>.
                </div>
              );
            }
          })}
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={fieldTypeId}>Type</label>
        </div>
        <div>
          <select
            id={fieldTypeId}
            value={fieldType}
            onChange={(e) => setFieldType(e.target.value as RmFieldType)}
          >
            <option value={RmFieldType.Boolean}>Boolean</option>
            <option value={RmFieldType.Number}>Number</option>
            <option value={RmFieldType.Date}>Date</option>
            <option value={RmFieldType.Timestamp}>Timestamp</option>
            <option value={RmFieldType.Text}>Text</option>
            <option value={RmFieldType.Lookup}>Lookup</option>
            <option value={RmFieldType.Resource} disabled>
              Resource
            </option>
            <option value={RmFieldType.ResourceList} disabled>
              Resource List
            </option>
          </select>
        </div>
      </div>
    </ZenDialog>
  );
};

export default FieldsActions;
