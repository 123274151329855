import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  PauseCircleIcon,
  PencilSquareIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  ReprocessDataPlanDetailsFragment,
  useDisableReprocessDataPlanMutation,
  useEnableReprocessDataPlanMutation,
  useUpdateReprocessDataPlanTargetConcurrencyMutation,
  useUpdateReprocessDataPlanTitleMutation,
} from "../../graphql/generated";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";

const ReprocessDataPlanItemActions: React.FC<{
  plan?: ReprocessDataPlanDetailsFragment;
}> = ({ plan }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [, enableMutation] = useEnableReprocessDataPlanMutation();
  const [, disableMutation] = useDisableReprocessDataPlanMutation();
  const [{ fetching: updateTitleFetching }, updateTitleMutation] =
    useUpdateReprocessDataPlanTitleMutation();
  const [
    { fetching: updateTargetConcurrencyFetching },
    updateTargetConcurrencyMutation,
  ] = useUpdateReprocessDataPlanTargetConcurrencyMutation();

  const [editTitleDialogIsOpen, setEditTitleDialogIsOpen] =
    React.useState(false);
  const [
    editTargetConcurrencyDialogIsOpen,
    setEditTargetConcurrencyDialogIsOpen,
  ] = React.useState(false);

  const enable = React.useCallback(async () => {
    await enableMutation({ id: plan?.id || "" }).then(
      notifier.notifyGraphql("Plan has been enabled")
    );
  }, [plan]);

  const disable = React.useCallback(async () => {
    await disableMutation({ id: plan?.id || "" }).then(
      notifier.notifyGraphql("Plan has been disabled")
    );
  }, [plan]);

  const updateTitle = React.useCallback(
    async (title?: string) => {
      await updateTitleMutation({
        id: plan?.id || "",
        title,
      }).then(notifier.notifyGraphql("Title updated"));
      setEditTitleDialogIsOpen(false);
    },
    [plan, setEditTitleDialogIsOpen]
  );

  const updateTargetConcurrency = React.useCallback(
    async (targetConcurrency: number) => {
      await updateTargetConcurrencyMutation({
        id: plan?.id || "",
        targetConcurrency,
      }).then(notifier.notifyGraphql("Target concurrency updated"));
      setEditTargetConcurrencyDialogIsOpen(false);
    },
    [plan, setEditTargetConcurrencyDialogIsOpen]
  );

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item disabled={plan && plan.enabled}>
                {({ active, disabled }) => (
                  <div
                    onClick={enable}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <PlayCircleIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Enable
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={plan && !plan.enabled}>
                {({ active, disabled }) => (
                  <div
                    onClick={disable}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <PauseCircleIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Disable
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setEditTitleDialogIsOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <PencilSquareIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Edit title...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active, disabled }) => (
                  <div
                    onClick={() => setEditTargetConcurrencyDialogIsOpen(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <PencilSquareIcon
                      className={classNames(
                        "mr-3 h-5 w-5 text-gray-400",
                        active ? "group-hover:text-gray-500" : ""
                      )}
                      aria-hidden="true"
                    />
                    Edit target concurrency...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <EditTitleDialog
        isOpen={editTitleDialogIsOpen}
        onCancel={() => setEditTitleDialogIsOpen(false)}
        onSubmit={(title?: string) => updateTitle(title)}
        title={plan?.title}
        fetching={updateTitleFetching}
      />
      <EditTargetConcurrencyDialog
        isOpen={editTargetConcurrencyDialogIsOpen}
        onCancel={() => setEditTargetConcurrencyDialogIsOpen(false)}
        onSubmit={(targetConcurrency: number) =>
          updateTargetConcurrency(targetConcurrency)
        }
        targetConcurrency={plan?.targetConcurrency}
        fetching={updateTargetConcurrencyFetching}
      />
    </>
  );
};

const EditTitleDialog: React.FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (title?: string) => void;
  title?: string;
  fetching: boolean;
}> = ({ isOpen, onCancel, onSubmit, title, fetching }) => {
  const [newTitle, setNewTitle] = React.useState(title ?? "");
  return (
    <ZenDialog
      icon={PencilSquareIcon}
      onCancel={onCancel}
      onSubmit={() => onSubmit(newTitle.length ? newTitle : undefined)}
      title="Set title"
      submit="Update"
      show={isOpen}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <input
        type="text"
        value={newTitle}
        onChange={(e) => setNewTitle(e.target.value)}
      />
    </ZenDialog>
  );
};

const EditTargetConcurrencyDialog: React.FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (targetConcurrency: number) => void;
  targetConcurrency?: number;
  fetching: boolean;
}> = ({ isOpen, onCancel, onSubmit, targetConcurrency, fetching }) => {
  const [newTargetConcurrency, setNewTargetConcurrency] =
    React.useState(targetConcurrency);

  const isValid = newTargetConcurrency !== undefined;

  return (
    <ZenDialog
      icon={PencilSquareIcon}
      onCancel={onCancel}
      onSubmit={() => onSubmit(newTargetConcurrency ?? 0)}
      title="Set target concurrency"
      submit="Update"
      show={isOpen}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <input
        type="number"
        value={newTargetConcurrency}
        onChange={(e) => {
          const parsed = parseInt(e.target.value, 10);
          setNewTargetConcurrency(Number.isNaN(parsed) ? undefined : parsed);
        }}
      />
    </ZenDialog>
  );
};

export default ReprocessDataPlanItemActions;
