import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmResourceBasicMappingTypeFragment,
  useRmMappingSetPrimarySourceMutation,
} from "../../../graphql/generated";
import { DescriptionList } from "../../../common/components/descriptionlist";
import DescriptionListContainer from "../../../common/components/descriptionlistcontainer";
import { EditableSource } from "../mapping-source";
import FieldsPanel from "../fields-panel";
import { NormalizationsPanel } from "../normalizations-panel";

const RMResourceBasicMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmResourceBasicMappingTypeFragment;
}> = ({ mapping, mappingType }) => {
  const [{ fetching: setPrimarySourceFetching }, setPrimarySourceMutation] =
    useRmMappingSetPrimarySourceMutation();

  return (
    <>
      <Panel>
        <Panel.Title>Resource Mapping</Panel.Title>
        <Panel.Body>
          <DescriptionList>
            <DescriptionListContainer title="Source">
              <EditableSource
                source={mappingType.source}
                onSubmit={(_, sourceInput) =>
                  setPrimarySourceMutation({
                    mappingId: mapping.id,
                    source: sourceInput,
                  })
                }
                isSubmitting={setPrimarySourceFetching}
              />
            </DescriptionListContainer>
          </DescriptionList>
        </Panel.Body>
      </Panel>

      <FieldsPanel
        resourceId={mappingType.resource.id}
        fields={mappingType.resource.fields}
      />
      <NormalizationsPanel
        fields={mappingType.resource.fields}
        normalizations={mappingType.resource.normalizations}
      />
    </>
  );
};

export default RMResourceBasicMappingTypeControl;
