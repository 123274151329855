import React from "react";
import { CombinedError, OperationResult } from "urql";

export enum NotificationType {
  Success,
  Error,
}

interface INotification {
  notification: string;
  notificationType: NotificationType;
}

class Notifier {
  private setNotification: (notification: INotification) => void;
  constructor(setNotification: (notification: INotification) => void) {
    this.setNotification = setNotification;
  }

  /** Set the notification using raw data
   */
  update(notification: INotification) {
    this.setNotification(notification);
  }

  /** Used in the `.then` callback for a GraphQL mutation, this displays a
   * notification based on success or failure of the mutation.
   */
  notifyGraphql<A>(
    message?: string
  ): (result: OperationResult<A>) => OperationResult<A> {
    return (operationResult: OperationResult<A>) => {
      const { error } = operationResult;
      if (error) {
        this.error(error);
      } else if (message) {
        this.success(message);
      }
      return operationResult;
    };
  }

  /** Display a notification banner with information from a GraphQL error
   */
  error(error: CombinedError) {
    let message: string = error.message;
    if (error.graphQLErrors && error.graphQLErrors.length) {
      if (error.graphQLErrors[0].extensions) {
        if (
          error.graphQLErrors[0].extensions.type == "ServerError" &&
          typeof error.graphQLErrors[0].extensions.error == "string"
        ) {
          message = error.graphQLErrors[0].extensions.error;
        }
      }
    }
    this.update({
      notification: message,
      notificationType: NotificationType.Error,
    });
  }

  /** Display a notification banner indicating a successful operation
   */
  success(message: string) {
    this.update({
      notification: message,
      notificationType: NotificationType.Success,
    });
  }

  /** Display a notification banner indicating a failed operation
   */
  failure(message: string) {
    this.update({
      notification: message,
      notificationType: NotificationType.Error,
    });
  }
}

interface INotificationContext {
  notifier: Notifier;
  clearNotification: () => void;
  currentNotification?: INotification;
}

export const NotificationContext = React.createContext<INotificationContext>({
  notifier: new Notifier(() => null),
  clearNotification: () => null,
});

const NotificationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [notification, setNotification] = React.useState<INotification>();
  const notifier = new Notifier(setNotification);

  const clearNotification = React.useCallback(
    () => setNotification(undefined),
    []
  );

  return (
    <NotificationContext.Provider
      value={{
        currentNotification: notification,
        notifier,
        clearNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
