import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  RmFieldDetailsFragment,
  RmFieldLookupValueFragment,
  RmFieldType,
  RmLookupMode,
  useRmFieldQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import { Navigation, NavigationItems } from "../components/nav";
import { MappingsPanel, MappingsUl } from "../components/mappings-panel";
import ResourceMappingTitle from "../components/resource-mapping-title";
import Pill from "../components/pill";
import {
  NormalizationsPanel,
  NormalizationsUl,
} from "../components/normalizations-panel";
import Panel from "../../common/components/panel";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import { DescriptionList } from "../../common/components/descriptionlist";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import LookupMappingsActions from "../components/mappings-actions/lookup-mappings-actions";
import LookupDetailsActions from "../components/lookup-details-actions";
import { FieldDetailsPanel } from "../components/field-details-panel";

const RMField: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useRmFieldQuery({
    variables: { id: params.fieldId ?? "" },
  });
  useTitle(
    data?.rmField.name,
    data?.rmField.rootResource.name,
    data?.rmField.rootResource.mls.shortName,
    "Resource Mapping"
  );

  const nav = NavigationItems.withHome();
  nav.addMls(
    data?.rmField.rootResource.mls.shortName,
    data?.rmField.rootResource.mls.id
  );
  if (data && data.rmField && data.rmField.ancestors) {
    for (const ancestor of data.rmField.ancestors) {
      nav.addAncestor(ancestor);
    }
  }
  nav.addField(data?.rmField.name, data?.rmField.id);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle name={data?.rmField.name}>
            <Pill.forSource source={data?.rmField.source} />
            <Pill.forFieldType
              fieldType={data?.rmField.type}
              reziLookupMode={data?.rmField.reziLookupMode}
              localLookupMode={data?.rmField.localLookupMode}
            />
          </ResourceMappingTitle>

          <FieldDetailsPanel field={data?.rmField} />

          {data?.rmField.type == RmFieldType.Lookup ? (
            <RMLookupField field={data.rmField} />
          ) : (
            <>
              <MappingsPanel
                field={data?.rmField}
                mappings={data?.rmField.mappings}
              />
              <NormalizationsPanel
                normalizations={data?.rmField.normalizations}
              />
            </>
          )}
        </div>
      </Transition>
    </>
  );
};

// A lookup field is sufficiently different enough that we'll encapsulate it into its own component
const RMLookupField: React.FC<{ field: RmFieldDetailsFragment }> = ({
  field,
}) => {
  const reziMappings = field.mappings.filter(
    (mapping) => !mapping.destinationIsLocalLookup
  );
  const localMappings = field.mappings.filter(
    (mapping) => mapping.destinationIsLocalLookup
  );
  const reziNormalizations = field.normalizations.filter(
    (normalization) => !normalization.destinationIsLocalLookup
  );
  const localNormalizations = field.normalizations.filter(
    (normalization) => normalization.destinationIsLocalLookup
  );
  const [reziIsReordering, setReziIsReordering] = React.useState(false);
  const [localIsReordering, setLocalIsReordering] = React.useState(false);
  const [reziShowAllValues, setReziShowAllValues] = React.useState(false);
  const [localShowAllValues, setLocalShowAllValues] = React.useState(false);

  return (
    <>
      <Panel>
        <Panel.Title>Rezi side</Panel.Title>
        {field.reziLookupMode == RmLookupMode.None ? (
          <Panel.Body>
            <i>No Rezi lookup side for this field</i>
          </Panel.Body>
        ) : (
          <>
            <Panel.Body>
              <Panel.SubTitle>Details</Panel.SubTitle>
              <DescriptionList>
                <DescriptionListItem
                  title="Mode"
                  value={field.reziLookupMode}
                />
                <DescriptionListContainer title="Values" span={3}>
                  <LookupValuesList
                    values={field.standardLookupValues}
                    showAll={reziShowAllValues}
                    onExpand={() => setReziShowAllValues(true)}
                  />
                </DescriptionListContainer>
              </DescriptionList>
            </Panel.Body>
            <Panel.Body>
              <Panel.SubTitle
                actions={
                  <LookupMappingsActions
                    field={field}
                    isReordering={reziIsReordering}
                    onStartReordering={() => setReziIsReordering(true)}
                  />
                }
              >
                Mappings
              </Panel.SubTitle>
              <MappingsUl
                mappings={reziMappings}
                isReordering={reziIsReordering}
                onReorderingFinished={() => setReziIsReordering(false)}
              />
            </Panel.Body>
            <Panel.Body>
              <Panel.SubTitle>Normalizations</Panel.SubTitle>
              <NormalizationsUl normalizations={reziNormalizations} />
            </Panel.Body>
          </>
        )}
      </Panel>
      <Panel>
        <Panel.Title>Local side</Panel.Title>
        {field.localLookupMode == RmLookupMode.None ? (
          <Panel.Body>
            <i>No local lookup side for this field</i>
          </Panel.Body>
        ) : (
          <>
            <Panel.Body>
              <Panel.SubTitle
                actions={<LookupDetailsActions field={field} local />}
              >
                Details
              </Panel.SubTitle>
              <DescriptionList>
                <DescriptionListItem
                  title="Mode"
                  value={field.localLookupMode}
                />
                <DescriptionListContainer title="Values" span={3}>
                  <LookupValuesList
                    values={field.localLookupValues}
                    showAll={localShowAllValues}
                    onExpand={() => setLocalShowAllValues(true)}
                  />
                </DescriptionListContainer>
              </DescriptionList>
            </Panel.Body>
            <Panel.Body>
              <Panel.SubTitle
                actions={
                  <LookupMappingsActions
                    field={field}
                    local
                    isReordering={localIsReordering}
                    onStartReordering={() => setLocalIsReordering(true)}
                  />
                }
              >
                Mappings
              </Panel.SubTitle>
              <MappingsUl
                mappings={localMappings}
                isReordering={localIsReordering}
                onReorderingFinished={() => setLocalIsReordering(false)}
              />
            </Panel.Body>
            <Panel.Body>
              <Panel.SubTitle>Normalizations</Panel.SubTitle>
              <NormalizationsUl normalizations={localNormalizations} />
            </Panel.Body>
          </>
        )}
      </Panel>
    </>
  );
};

const LookupValuesList: React.FC<{
  values: RmFieldLookupValueFragment[];
  showAll: boolean;
  onExpand: () => void;
}> = ({ values, showAll, onExpand }) => {
  if (values.length === 0) {
    return <>—</>;
  }

  if (!showAll && values.length > 5) {
    const numberOfUnshown = values.length - 5;
    return (
      <>
        {values.slice(0, 5).map((item, idx) => (
          <>
            {idx > 0 && ", "}
            <ValuePopover value={item.value} definition={item.definition} />
          </>
        ))}
        ,{" "}
        <button onClick={() => onExpand()} className="italic text-blue-400">
          show {numberOfUnshown} more...
        </button>
      </>
    );
  }

  return (
    <>
      {values.map((item, idx) => (
        <>
          {idx > 0 && ", "}
          <ValuePopover value={item.value} definition={item.definition} />
        </>
      ))}
    </>
  );
};

const ValuePopover: React.FC<{ value: string; definition?: string }> = ({
  value,
  definition,
}) => {
  if (definition) {
    return (
      <Popover className="relative inline">
        {() => (
          <>
            <Popover.Button className="inline">
              <span className={`border-b border-dashed border-blue-600`}>
                {value}
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-l w-64">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="bg-white p-4">{definition}</div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  } else {
    return <>{value}</>;
  }
};

export default RMField;
