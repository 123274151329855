import React from "react";
import LoadingIcon from "../../../common/components/loadingicon";
import Panel from "../../../common/components/panel";
import { NotificationContext } from "../../../common/context/notification";
import { classNames } from "../../../common/utils/classnames";
import {
  ListingInputDefinitionFragment,
  ListingInputDefinitionOrderInput,
  ListingInputDefinitionOrderItemInputType,
  ListingInputDefinitionControlOverlayInput,
  useUpdateListingInputDefinitionMutation,
  ListingInputDefinitionFieldFragment,
  useListingInputDefinitionQuery,
  ListingInputDefinitionCustomRuleFragment,
  ListingInputDefinitionCustomRuleInput,
  ListingInputDefinitionPropertyTypeFragment,
  ListingInputDefinitionRuleFragment,
  ListingInputDefinitionRuleOverlayInput,
  ListingInputDefinitionStatusFragment,
  ListingInputDefinitionStatusOverlayInput,
} from "../../../graphql/generated";
import { customAlphabet } from "nanoid";
import { ControlsPanel } from "./controls";
import { CustomRulesPanel } from "./custom-rules";
import { RulesPanel } from "./rules";
import { StatusesPanel } from "./statuses";

export const nanoid = customAlphabet("1234567890abcdefghijklmnpqrstuvwxyz", 10);

export type Fields = Map<string, ListingInputDefinitionFieldFragment>;
export type Statuses = Map<string, ListingInputDefinitionStatusFragment>;
export type Rules = Map<string, ListingInputDefinitionRuleFragment>;
export type CustomRules = Map<string, ListingInputDefinitionCustomRuleFragment>;
export type PropertyTypes = Map<
  string,
  ListingInputDefinitionPropertyTypeFragment
>;

export type State = {
  orderedItems: Item[];
  hiddenItems: Item[];
  newItems: Item[];
  statusOrder: string[];
  fields: Fields;
  statuses: Statuses;
  rules: Rules;
  customRules: CustomRules;
  propertyTypes: PropertyTypes;

  controlUpdates: Map<string, ListingInputDefinitionControlOverlayInput>;
  statusUpdates: Map<string, ListingInputDefinitionStatusOverlayInput>;
  ruleUpdates: Map<string, ListingInputDefinitionRuleOverlayInput>;
  customRuleUpdates: Map<string, ListingInputDefinitionCustomRuleInput>;
};

export type HeaderItem = {
  type: "header";
  id: string;
  name: string;
  expandedByDefault: boolean;
};
export type SubheaderItem = {
  type: "subheader";
  id: string;
  name: string;
};
export type FieldItem = {
  type: "field";
  id: string;
  name: string;
};
export type Item = HeaderItem | SubheaderItem | FieldItem;

function buildState(
  listingInputDefinition?: ListingInputDefinitionFragment
): State {
  if (listingInputDefinition) {
    const state: State = {
      orderedItems: [],
      hiddenItems: [],
      newItems: [],
      statusOrder: [],
      fields: new Map(),
      statuses: new Map(),
      rules: new Map(),
      customRules: new Map(),
      propertyTypes: new Map(),
      controlUpdates: new Map(),
      statusUpdates: new Map(),
      ruleUpdates: new Map(),
      customRuleUpdates: new Map(),
    };
    for (const orderedItem of listingInputDefinition.order.orderedItems) {
      switch (orderedItem.__typename) {
        case "ListingInputDefinitionOrderItemHeader":
          state.orderedItems.push({
            type: "header",
            id: orderedItem.id,
            name: orderedItem.name,
            expandedByDefault: orderedItem.expandedByDefault,
          });
          break;
        case "ListingInputDefinitionOrderItemSubheader":
          state.orderedItems.push({
            type: "subheader",
            id: orderedItem.id,
            name: orderedItem.name,
          });
          break;
        case "ListingInputDefinitionOrderItemField":
          state.orderedItems.push({
            type: "field",
            id: orderedItem.name,
            name: orderedItem.name,
          });
          break;
      }
    }
    for (const newItem of listingInputDefinition.order.newItems) {
      state.newItems.push({
        type: "field",
        id: newItem,
        name: newItem,
      });
    }
    for (const hiddenItem of listingInputDefinition.order.hiddenItems) {
      state.hiddenItems.push({
        type: "field",
        id: hiddenItem,
        name: hiddenItem,
      });
    }
    for (const statusName of listingInputDefinition.statusOrder) {
      state.statusOrder.push(statusName);
    }
    for (const field of listingInputDefinition.fields) {
      state.fields.set(field.name, field);
    }
    for (const status of listingInputDefinition.statuses) {
      state.statuses.set(status.name, status);
    }
    for (const rule of listingInputDefinition.rules) {
      state.rules.set(rule.id, rule);
    }
    for (const customRule of listingInputDefinition.customRules) {
      state.customRules.set(customRule.id, customRule);
    }
    for (const propertyType of listingInputDefinition.propertyTypes) {
      state.propertyTypes.set(propertyType.name, propertyType);
    }
    return state;
  } else {
    return {
      orderedItems: [],
      hiddenItems: [],
      newItems: [],
      statusOrder: [],
      fields: new Map(),
      statuses: new Map(),
      rules: new Map(),
      customRules: new Map(),
      propertyTypes: new Map(),
      controlUpdates: new Map(),
      statusUpdates: new Map(),
      ruleUpdates: new Map(),
      customRuleUpdates: new Map(),
    };
  }
}

export const ListingInputEdit: React.FC<{ id?: string }> = ({ id }) => {
  const [{ data: queryData }, refetch] = useListingInputDefinitionQuery({
    variables: {
      id: id ?? "",
    },
    pause: !id,
  });

  const [state, setState] = React.useState<State>(() =>
    buildState(queryData?.listingInputDefinition)
  );
  const [isModified, setIsModified] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  React.useEffect(() => {
    setState(buildState(queryData?.listingInputDefinition));
    setIsModified(false);
  }, [setState, queryData, setIsModified]);

  const { notifier } = React.useContext(NotificationContext);

  const [, updateListingInputDefinition] =
    useUpdateListingInputDefinitionMutation();

  const onUpdate = React.useCallback(async () => {
    setIsSaving(true);

    const order: ListingInputDefinitionOrderInput = {
      orderedItems: [],
      hiddenItems: [],
    };

    for (const orderedItem of state.orderedItems) {
      switch (orderedItem.type) {
        case "header":
          order.orderedItems.push({
            itemType: ListingInputDefinitionOrderItemInputType.Header,
            id: orderedItem.id,
            name: orderedItem.name,
            expandedByDefault: orderedItem.expandedByDefault,
          });
          break;
        case "subheader":
          order.orderedItems.push({
            itemType: ListingInputDefinitionOrderItemInputType.Subheader,
            id: orderedItem.id,
            name: orderedItem.name,
          });
          break;
        case "field":
          order.orderedItems.push({
            itemType: ListingInputDefinitionOrderItemInputType.Field,
            name: orderedItem.name,
          });
          break;
      }
    }
    for (const hiddenItem of state.hiddenItems) {
      switch (hiddenItem.type) {
        case "field":
          order.hiddenItems.push(hiddenItem.name);
          break;
      }
    }
    const controls = Array.from(state.controlUpdates.values());
    const statusOrder = Array.from(state.statusOrder);
    const statuses = Array.from(state.statusUpdates.values());
    const rules = Array.from(state.ruleUpdates.values());
    const customRules = Array.from(state.customRuleUpdates.values());

    const { error } = await updateListingInputDefinition({
      id: id ?? "",
      order,
      controls,
      statusOrder,
      statuses,
      rules,
      customRules,
    }).then(notifier.notifyGraphql("Listing Input Definition updated"));
    setIsSaving(false);
    if (!error) {
      refetch();
    }
  }, [state, updateListingInputDefinition, refetch, setIsSaving, id]);

  return (
    <>
      <Panel>
        <Panel.Body summary>
          <div className="my-4">
            <button
              type="button"
              className={classNames(
                "text-white font-bold py-1 px-4 rounded mx-1",
                isModified && !isSaving
                  ? "bg-red-500 hover:bg-red-700"
                  : "bg-gray-300"
              )}
              onClick={onUpdate}
              disabled={!isModified}
            >
              {isSaving && (
                <>
                  <LoadingIcon /> Saving
                </>
              )}
              {!isSaving && <>Save</>}
            </button>
          </div>
        </Panel.Body>
      </Panel>

      <ControlsPanel
        state={state}
        setState={setState}
        setIsModified={setIsModified}
      />

      <StatusesPanel
        state={state}
        setState={setState}
        setIsModified={setIsModified}
      />

      <RulesPanel
        state={state}
        setState={setState}
        setIsModified={setIsModified}
      />

      <CustomRulesPanel
        state={state}
        setState={setState}
        setIsModified={setIsModified}
      />
    </>
  );
};

export const PropertyTypeSelect: React.FC<{
  selected: string[];
  state: State;
  onChange: (value: string[]) => void;
}> = ({ selected, state, onChange }) => {
  function isChecked(name: string): boolean {
    return !!selected.find((value) => name === value);
  }

  const checkedChanged = React.useCallback(
    (name: string, checked: boolean) => {
      const newSelected = Array.from(selected);
      if (checked) {
        newSelected.push(name);
      } else {
        const index = newSelected.indexOf(name);
        if (index > -1) {
          newSelected.splice(index, 1);
        }
      }

      onChange(newSelected);
    },
    [selected, onChange]
  );

  return (
    <div>
      {Array.from(state.propertyTypes.values()).map((propertyType) => (
        <label className="block">
          <input
            type="checkbox"
            checked={isChecked(propertyType.name)}
            onChange={(event) =>
              checkedChanged(propertyType.name, event.target.checked)
            }
          />{" "}
          {propertyType.overlayDisplayName ??
            propertyType.sourceDisplayName ??
            propertyType.name}
        </label>
      ))}
    </div>
  );
};
