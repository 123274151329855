import {
  CalendarIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { longDateFormat } from "../../../common/utils/dateformat";
import { SubethaEventQuery } from "../../../graphql/generated";
import PageHeader from "../../../common/components/page-header";

const EventTitle: React.FC<{ event: SubethaEventQuery["event"] }> = ({
  event,
}) => {
  return (
    <PageHeader>
      <PageHeader.Title>
        {event.mail?.subject} - {event.eventType}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          {longDateFormat(event.createdAt)}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={InformationCircleIcon}>
          {longDateFormat(event.createdAt)}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default EventTitle;
