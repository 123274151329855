import {
  HashtagIcon,
  InformationCircleIcon,
  PauseIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../../common/utils/dateformat";
import { RecipientQuery } from "../../../graphql/generated";
import RecipientActions from "./actions";
import RecipientPic from "./pic";
import PageHeader from "../../../common/components/page-header";

const RecipientTitle: React.FC<{ recipient?: RecipientQuery["recipient"] }> = ({
  recipient,
}) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <RecipientPic recipient={recipient} large />
      </PageHeader.Pic>
      <PageHeader.Title>{recipient?.email}</PageHeader.Title>
      <PageHeader.Details>
        {recipient?.pauseAt && (
          <>
            <PageHeader.DetailsItem icon={PauseIcon}>
              {longDateFormat(recipient.pauseAt)}
            </PageHeader.DetailsItem>
            <PageHeader.DetailsItem icon={InformationCircleIcon}>
              {recipient.pauseReason}
            </PageHeader.DetailsItem>
            <PageHeader.DetailsItem icon={InformationCircleIcon}>
              <Link
                to={`/subetha/events/${recipient?.pauseEvent?.id}`}
                className="text-blue-900 hover:underline"
              >
                Pause Event
              </Link>
            </PageHeader.DetailsItem>
            <PageHeader.DetailsItem icon={HashtagIcon}>
              Pause Count: {recipient.pauseCount}
            </PageHeader.DetailsItem>
          </>
        )}
        {!recipient?.pauseAt && (
          <PageHeader.DetailsItem icon={PlayIcon}>
            Active
          </PageHeader.DetailsItem>
        )}
      </PageHeader.Details>
      <PageHeader.Actions>
        <RecipientActions recipient={recipient} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default RecipientTitle;
