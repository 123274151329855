import { Transition } from "@headlessui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  ReprocessDataTaskStatus,
  RmProblemsForResourceDocument,
  useRmProblemsForResourceQuery,
  useRmRootResourceFieldUseSummaryQuery,
  useRmRootResourceQuery,
} from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import { Navigation, NavigationItems } from "../components/nav";
import FieldsPanel from "../components/fields-panel";
import ResourceMappingTitle from "../components/resource-mapping-title";
import Problems from "../components/problems";
import Panel from "../../common/components/panel";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import { useClient } from "urql";
import CacheSafeAtPill from "../components/cache-safe-pill";
import SchemaVersionCountsTable from "../components/schema-version-counts-table";
import { NormalizationsPanel } from "../components/normalizations-panel";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import RootResourceActions from "../components/root-resource-actions";
import ReprocessDataTasksPanel from "../components/reprocess-data-tasks-panel";

const RMRootResource: React.FC = () => {
  const graphqlClient = useClient();
  const params = useParams();
  const [{ data, error }] = useRmRootResourceQuery({
    variables: { id: params.mlsResourceMappingId ?? "" },
  });
  const [{ data: problemData, error: problemError }] =
    useRmProblemsForResourceQuery({
      variables: { resourceId: params.mlsResourceMappingId ?? "" },
      pause: !params.mlsResourceMappingId,
    });
  const [{ data: fieldUseSummaryData }] = useRmRootResourceFieldUseSummaryQuery(
    {
      variables: { id: params.mlsResourceMappingId ?? "" },
      pause: !params.mlsResourceMappingId,
    }
  );

  const [loading, setLoading] = React.useState(false);
  const canFetchMore =
    problemData?.rmProblemsForResource.pageInfo.hasNextPage ?? false;
  const fetchMore = React.useCallback(async () => {
    if (problemData?.rmProblemsForResource.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(RmProblemsForResourceDocument, {
          first: 100,
          after: problemData.rmProblemsForResource.pageInfo.endCursor,
          resourceId: params.mlsResourceMappingId ?? "",
        })
        .toPromise();
      setLoading(false);
    }
  }, [problemData, params.mlsResourceMappingId, graphqlClient]);

  useTitle(
    data?.rmRootResource.name,
    data?.rmRootResource.mls.shortName,
    "Resource Mapping"
  );

  const nav = NavigationItems.withHome();
  nav.addMls(data?.rmRootResource.mls.shortName, data?.rmRootResource.mls.id);
  nav.addRoot(data?.rmRootResource.name, data?.rmRootResource.id);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <Navigation items={nav} />
          <ResourceMappingTitle name={data?.rmRootResource.name}>
            Root
          </ResourceMappingTitle>
          <Panel>
            <Panel.Title
              actions={
                <RootResourceActions rootResource={data?.rmRootResource} />
              }
            >
              Details
            </Panel.Title>
            <Panel.Body summary>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                <DescriptionListContainer title="Schema version" span={2}>
                  {data?.rmRootResource.schemaVersion}
                  <CacheSafeAtPill
                    cacheSafeAt={data?.rmRootResource.cacheSafeAt}
                  />
                </DescriptionListContainer>
                <DescriptionListItem
                  title="Timestamp-parsing time zone"
                  info="When timestamps are seen in the data that don't indicate which time zone they are from, treat them as timestamps from this time zone"
                  value={data?.rmRootResource.timezone}
                />
                <DescriptionListContainer
                  title="Analysis"
                  info="An analysis of the raw data for this MLS"
                >
                  {data?.rmRootResource.canbyAnalysis && (
                    <Link
                      to={`/canby/${data.rmRootResource.canbyAnalysis.id}`}
                      className="text-blue-900 hover:underline"
                    >
                      {data.rmRootResource.canbyAnalysis.id}
                    </Link>
                  )}
                  {!data?.rmRootResource.canbyAnalysis && "—"}
                </DescriptionListContainer>
              </dl>
            </Panel.Body>
          </Panel>
          <FieldsPanel
            resourceId={data?.rmRootResource.resource.id}
            fields={data?.rmRootResource.resource.fields}
            fieldUseSummary={
              fieldUseSummaryData?.rmRootResource.fieldUseSummary
            }
          />
          <NormalizationsPanel
            fields={data?.rmRootResource.resource.fields}
            normalizations={data?.rmRootResource.resource.normalizations}
          />
          <Panel>
            <Panel.Title>Schema Versions</Panel.Title>
            <Panel.Body>
              <SchemaVersionCountsTable
                counts={data?.rmRootResource.schemaVersionCounts}
                targetSchemaVersion={data?.rmRootResource.schemaVersion}
              />
            </Panel.Body>
          </Panel>
          {data?.rmRootResource.id && (
            <ReprocessDataTasksPanel
              ids={[data.rmRootResource.id]}
              statuses={[
                ReprocessDataTaskStatus.Paused,
                ReprocessDataTaskStatus.Pausing,
                ReprocessDataTaskStatus.PausedForProblems,
                ReprocessDataTaskStatus.Ready,
                ReprocessDataTaskStatus.Running,
                ReprocessDataTaskStatus.WaitingOnCache,
              ]}
            />
          )}

          <Problems
            data={problemData?.rmProblemsForResource}
            error={problemError}
            fetch={{
              fetch: fetchMore,
              canFetch: canFetchMore,
              isFetching: loading,
            }}
          />
        </div>
      </Transition>
    </>
  );
};

export default RMRootResource;
