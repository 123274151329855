import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  XMarkIcon,
  UserPlusIcon,
  UserMinusIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  useEnableProductOfferingForFeatureFlagMutation,
  useDisableProductOfferingForFeatureFlagMutation,
  FeatureFlagFragment,
  useProductOfferingsQuery,
} from "../../graphql/generated";
import { shortDateFormat } from "../../common/utils/dateformat";

const ProductOfferingActions: React.FC<{
  featureFlag?: FeatureFlagFragment;
  enabled?: boolean;
}> = ({ featureFlag, enabled }) => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<string>();

  const { notifier } = React.useContext(NotificationContext);

  const [{ data }] = useProductOfferingsQuery({
    variables: {
      excludeTest: false,
    },
  });

  const [
    { fetching: enableProductOfferingFetching },
    enableProductOfferingMutation,
  ] = useEnableProductOfferingForFeatureFlagMutation();

  const [
    { fetching: disableProductOfferingFetching },
    disableProductOfferingMutation,
  ] = useDisableProductOfferingForFeatureFlagMutation();

  const enableProductOffering = React.useCallback(() => {
    if (!enableProductOfferingFetching && selected) {
      enableProductOfferingMutation({
        featureFlagId: featureFlag?.id ?? "",
        productOfferingId: selected,
      }).then(notifier.notifyGraphql());
    }
  }, [
    featureFlag,
    enableProductOfferingFetching,
    enableProductOfferingMutation,
    selected,
  ]);

  const disableProductOffering = React.useCallback(() => {
    if (!disableProductOfferingFetching && selected) {
      disableProductOfferingMutation({
        featureFlagId: featureFlag?.id ?? "",
        productOfferingId: selected,
      }).then(notifier.notifyGraphql());
    }
  }, [
    featureFlag,
    disableProductOfferingFetching,
    disableProductOfferingMutation,
    selected,
  ]);

  const action = enabled ? enableProductOffering : disableProductOffering;
  const text = enabled ? "Enable Product Offering" : "Disable Product Offering";

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setOpen(!open)}
                  >
                    {enabled ? (
                      <UserPlusIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <UserMinusIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                    {text}
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <Transition.Root show={open} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden h-screen">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-none">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {text}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1  flex flex-col overflow-scroll">
                      <div className="px-4 sm:px-6 py-4 sm:py-6 mt-4 grow overflow-scroll">
                        <RadioGroup value={selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only">
                            ProductOffering
                          </RadioGroup.Label>
                          <div className="space-y-2">
                            {data?.productOfferings.map((productOffering) => (
                              <RadioGroup.Option
                                key={productOffering?.id}
                                value={productOffering?.id}
                                className={({ checked, active }) =>
                                  classNames(
                                    checked
                                      ? "border-transparent"
                                      : "border-gray-300",
                                    active ? "ring-2 ring-indigo-500" : "",
                                    "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex items-center">
                                      <div className="w-full flex items-center justify-between space-x-6">
                                        <div className="flex-1 truncate">
                                          <div className="flex items-center space-x-3">
                                            <h3 className="text-gray-900 text-sm font-medium truncate">
                                              {productOffering?.description?.trim() ===
                                              ""
                                                ? "Empty Name"
                                                : productOffering?.description}
                                            </h3>
                                          </div>
                                          <p className="mt-1 text-gray-500 text-sm truncate">
                                            {shortDateFormat(
                                              productOffering?.createdAt
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={classNames(
                                        active ? "border" : "border-2",
                                        checked
                                          ? "border-indigo-500"
                                          : "border-transparent",
                                        "absolute -inset-px rounded-lg pointer-events-none"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="pt-5 px-4 sm:px-6">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setOpen(false)}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={action}
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ProductOfferingActions;
