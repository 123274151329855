type PageHeaderPicType = React.FC<React.PropsWithChildren>;

const PageHeaderPic: PageHeaderPicType = ({ children }) => {
  return (
    <div
      className="flex mr-4"
      style={{ gridColumn: "1 / span 1", gridRow: "1 / span 4" }}
    >
      {children}
    </div>
  );
};

type PageHeaderTitleType = React.FC<React.PropsWithChildren>;

const PageHeaderTitle: PageHeaderTitleType = ({ children }) => {
  return (
    <h2
      className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate"
      style={{ gridColumn: "2 / span 1", gridRow: "1 / span 1" }}
    >
      {children}
    </h2>
  );
};

type PageHeaderDetailsType = React.FC<React.PropsWithChildren>;

const PageHeaderDetails: PageHeaderDetailsType = ({ children }) => {
  return (
    <div
      className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
      style={{ gridColumn: "2 / span 1" }}
    >
      {children}
    </div>
  );
};

type PageHeaderDetailsItemType = React.FC<
  {
    icon?: React.ForwardRefExoticComponent<
      React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
        title?: string;
        titleId?: string;
      } & React.RefAttributes<SVGSVGElement>
    >;
  } & React.PropsWithChildren
>;

const PageHeaderDetailsItem: PageHeaderDetailsItemType = ({
  icon,
  children,
}) => {
  const Icon = icon;

  return (
    <div className="mt-2 flex items-center text-sm text-gray-500">
      {Icon && (
        <Icon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-zenlist-400"
          aria-hidden="true"
        />
      )}
      {children}
    </div>
  );
};

type PageHeaderActionsType = React.FC<React.PropsWithChildren>;

const PageHeaderActions: PageHeaderActionsType = ({ children }) => {
  return (
    <div
      className="ml-4"
      style={{ gridRow: "1 / span 4", gridColumn: "3 / span 1" }}
    >
      {children}
    </div>
  );
};

const PageHeader: React.FC<React.PropsWithChildren> & {
  Pic: PageHeaderPicType;
  Title: PageHeaderTitleType;
  Details: PageHeaderDetailsType;
  DetailsItem: PageHeaderDetailsItemType;
  Actions: PageHeaderActionsType;
} = ({ children }) => {
  return (
    <header
      className="px-4 py-5 sm:px-6 grid"
      style={{
        gridTemplateColumns: "auto 1fr auto",
        gridAutoFlow: "row",
        gridAutoRows: "auto",
      }}
    >
      {children}
    </header>
  );
};

PageHeader.Actions = PageHeaderActions;
PageHeader.Title = PageHeaderTitle;
PageHeader.Details = PageHeaderDetails;
PageHeader.DetailsItem = PageHeaderDetailsItem;
PageHeader.Pic = PageHeaderPic;

export default PageHeader;
