import { CalendarIcon, SignalIcon } from "@heroicons/react/24/outline";
import React from "react";
import Timestamp from "../../common/components/timestamp";
import { ApiKeyFragment } from "../../graphql/generated";
import PageHeader from "../../common/components/page-header";

const ApiKeyTitle: React.FC<{ apiKey?: ApiKeyFragment }> = ({ apiKey }) => {
  return (
    <PageHeader>
      <PageHeader.Title>Api Key: {apiKey?.id}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          <Timestamp timestamp={apiKey?.createdAt} format="short" popover />
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={SignalIcon}>
          {apiKey?.activeState}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default ApiKeyTitle;
