import React from "react";
import { Link } from "react-router-dom";
import {
  RmFieldFragment,
  RmFieldUseSummaryFragment,
  RmSource,
} from "../../graphql/generated";
import Panel from "../../common/components/panel";
import { classNames } from "../../common/utils/classnames";
import Pill from "./pill";
import Gauge from "../../common/components/gauge";
import FieldsActions from "./fields-actions";

const FieldsPanel: React.FC<{
  resourceId?: string;
  fields?: RmFieldFragment[];
  header?: string;
  fieldUseSummary?: RmFieldUseSummaryFragment;
}> = ({ resourceId, fields, header: defaultHeader, fieldUseSummary }) => {
  const header = defaultHeader ?? "Fields";

  const sortedFields = React.useMemo(() => {
    if (!fields) {
      return;
    }
    const sortedFields = [...fields];
    sortedFields.sort((a, b) => {
      // Sort local fields below rezi fields
      if (a.source === RmSource.Local && b.source !== RmSource.Local) {
        return 1;
      }
      if (a.source !== RmSource.Local && b.source === RmSource.Local) {
        return -1;
      }

      // Otherwise sort by name
      if (a.name < b.name) {
        return -1;
      }
      if (b.name < a.name) {
        return 1;
      }
      return 0;
    });
    return sortedFields;
  }, [fields]);

  const fieldActions = resourceId ? (
    <FieldsActions resourceId={resourceId} />
  ) : undefined;

  return (
    <Panel>
      <Panel.Title actions={fieldActions}>{header}</Panel.Title>
      <Panel.Body>
        <table>
          <tbody>
            {sortedFields &&
              sortedFields.map((field) => {
                return (
                  <tr key={field.id}>
                    <td className="p-1">
                      <Link
                        to={`/resource_mapping/fields/${field.id}`}
                        className={classNames(
                          field.hasMappings || field.hasNormalizations
                            ? ""
                            : "text-gray-300"
                        )}
                      >
                        {field.name}
                      </Link>{" "}
                    </td>
                    <td className="p-1">
                      <Pill.forSource source={field.source} />
                    </td>
                    <td className="p-1">
                      <Pill.forFieldType
                        fieldType={field.type}
                        reziLookupMode={field.reziLookupMode}
                        localLookupMode={field.localLookupMode}
                      />
                    </td>
                    <td className="p-1">
                      <FieldUse
                        fieldName={field.name}
                        fieldUseSummary={fieldUseSummary}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Panel.Body>
    </Panel>
  );
};

const FieldUse: React.FC<{
  fieldUseSummary?: RmFieldUseSummaryFragment;
  fieldName?: string;
}> = ({ fieldUseSummary, fieldName }) => {
  if (!fieldUseSummary) {
    return <></>;
  }
  if (!fieldName) {
    return <></>;
  }
  const fieldUseSummaryItem = fieldUseSummary.fields.find(
    (item) => item.name == fieldName
  );
  if (!fieldUseSummaryItem) {
    return <></>;
  }

  return (
    <Gauge count={fieldUseSummaryItem.count} total={fieldUseSummary.total} />
  );
};

export default FieldsPanel;
