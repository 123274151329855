import { Switch } from "@headlessui/react";
import { GoogleMap, Marker, StandaloneSearchBox } from "@react-google-maps/api";
import React, { FormEvent } from "react";
import Panel from "../../common/components/panel";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  ListingFragment,
  useNeighborhoodsQuery,
  useSchoolDistrictsQuery,
  useSchoolZonesQuery,
  useUpdateListingGeocodeMutation,
} from "../../graphql/generated";

const ListingMap: React.FC<{ listing: ListingFragment }> = ({ listing }) => {
  const [map, setMap] = React.useState<google.maps.Map>();
  const [skipLocation, setSkipLocation] = React.useState(false);
  const [searchBox, setSearchBox] =
    React.useState<google.maps.places.SearchBox>();

  const [search, setSearch] = React.useState("");
  const [center, setCenter] = React.useState(
    listing.location
      ? {
          lat: listing.location[1],
          lng: listing.location[0],
        }
      : undefined
  );

  const { notifier } = React.useContext(NotificationContext);

  const onPlacesChanged = React.useCallback(() => {
    if (searchBox?.getPlaces()?.length) {
      const place = searchBox.getPlaces()![0];
      if (place.geometry?.location) {
        setSearch("");
        setCenter({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    }
  }, [searchBox]);

  const [{ data: schoolZoneData }] = useSchoolZonesQuery({
    variables: { lattitude: center!.lat, longitude: center!.lng },
    pause: !center,
  });

  const [{ data: schoolDistrictData }] = useSchoolDistrictsQuery({
    variables: { lattitude: center!.lat, longitude: center!.lng },
    pause: !center,
  });

  const [{ data: neighborhoodData }] = useNeighborhoodsQuery({
    variables: { lattitude: center!.lat, longitude: center!.lng },
    pause: !center,
  });

  const onMarkerMoved = React.useCallback((e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setCenter({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
    }
  }, []);

  const [{ fetching: updateFetching }, updateMutation] =
    useUpdateListingGeocodeMutation();

  const saveListing = React.useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!center) {
        notifier.failure("No coordinates set");
        return;
      }

      updateMutation({
        id: listing.id,
        details: {
          skipLocation: skipLocation,
          lattitude: center.lat,
          longitude: center.lng,
          neighborhoodIds: neighborhoodData?.neighborhoods.map((n) => n.id),
          schoolDistrictIds: schoolDistrictData?.schoolDistricts.map(
            (d) => d.id
          ),
          schoolZoneIds: schoolZoneData?.schoolZones.map((z) => z.id),
        },
      }).then(notifier.notifyGraphql("Listing geolocation has been updated."));

      if (skipLocation) {
        if (listing.location) {
          setCenter({ lat: listing.location[1], lng: listing.location[0] });
        }
      }
    },
    [
      center,
      listing,
      neighborhoodData,
      schoolDistrictData,
      schoolZoneData,
      updateMutation,
      skipLocation,
    ]
  );

  return (
    <Panel>
      <Panel.Body summary>
        {center && (
          <div>
            <GoogleMap
              mapContainerClassName="flex-1 h-[32rem] border-2"
              onLoad={setMap}
              center={center}
              zoom={17}
            >
              <StandaloneSearchBox
                onLoad={setSearchBox}
                bounds={map?.getBounds()}
                onPlacesChanged={onPlacesChanged}
              >
                <div className="flex justify-center">
                  <input
                    type="text"
                    placeholder="Search the map area"
                    className="focus:ring-indigo-500 w-1/3 text-indigo-600 border-gray-300 rounded absolute mt-3"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </StandaloneSearchBox>

              <Marker position={center} draggable onDragEnd={onMarkerMoved} />
            </GoogleMap>
          </div>
        )}
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={saveListing}
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Listing Geolocation
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Use this to place the listing in a new location manually. This
                  will also update neighborhoods, school zones and school
                  districts.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="lattitude"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Lattitude
                  </label>
                  <div className="mt-1">
                    <input
                      disabled
                      type="text"
                      name="lattitude"
                      id="lattitude"
                      value={center?.lat}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-xs text-gray-500">
                    Current {listing.location ? listing.location[1] : ""}
                  </p>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="longitude"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Longitude
                  </label>
                  <div className="mt-1">
                    <input
                      disabled
                      type="text"
                      name="longitude"
                      id="longitude"
                      value={center?.lng}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-xs text-gray-500">
                    Current {listing.location ? listing.location[0] : ""}
                  </p>
                </div>
              </div>
              <div className="mt-6 grid sm:grid-cols-2">
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">
                    Current School Zones
                  </legend>
                  {listing?.schoolZones?.map((zone) => {
                    return (
                      <div className="mt-2 space-y-2" key={zone.id}>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              disabled
                              checked
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {zone.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">
                    New School Zones
                  </legend>
                  {schoolZoneData?.schoolZones?.map((zone) => {
                    return (
                      <div className="mt-2 space-y-2" key={zone.id}>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              disabled
                              checked
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {zone.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
              </div>
              <div className="mt-6 grid sm:grid-cols-2">
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">
                    Current School Distrcits
                  </legend>
                  {listing?.schoolDistricts?.map((district) => {
                    return (
                      <div className="mt-2 space-y-2" key={district.id}>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              disabled
                              checked
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {district.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">
                    New School Distrcits
                  </legend>
                  {schoolDistrictData?.schoolDistricts?.map((district) => {
                    return (
                      <div className="mt-2 space-y-2" key={district.id}>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              disabled
                              checked
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {district.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
              </div>
              <div className="mt-6 grid sm:grid-cols-2">
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">
                    Current Neighborhoods
                  </legend>
                  {listing?.neighborhoods?.map((neighborhood) => {
                    return (
                      <div className="mt-2 space-y-2" key={neighborhood.id}>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              disabled
                              checked
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {neighborhood.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
                <fieldset>
                  <legend className="text-base font-medium text-gray-900">
                    New Neighborhoods
                  </legend>
                  {neighborhoodData?.neighborhoods?.map((neighborhood) => {
                    return (
                      <div className="mt-2 space-y-2" key={neighborhood.id}>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              disabled
                              checked
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="comments"
                              className="font-medium text-gray-700"
                            >
                              {neighborhood.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </fieldset>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex">
              <Switch.Group as="div" className="flex items-center flex-1">
                <Switch
                  checked={skipLocation}
                  onChange={() => setSkipLocation(!skipLocation)}
                  className={classNames(
                    skipLocation ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      skipLocation ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-900">
                    Skip Location Update{" "}
                  </span>
                </Switch.Label>
              </Switch.Group>
              <button
                onClick={() =>
                  setCenter({
                    lat: listing.location ? listing.location[1] : 41.8781,
                    lng: listing.location ? listing.location[0] : -87.6298,
                  })
                }
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                disabled={updateFetching}
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Panel.Body>
    </Panel>
  );
};
export default ListingMap;
