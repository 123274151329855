import {
  CalendarIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import AccountProfilePic from "../../account/components/profilepic";
import Timestamp from "../../common/components/timestamp";
import { ClipboardContext } from "../../common/context/clipboard";
import { IdentityPairFragment } from "../../graphql/generated";
import PageHeader from "../../common/components/page-header";

const IdentityOauthTitle: React.FC<{
  identity?: IdentityPairFragment;
}> = ({ identity }) => {
  const { sendToClip } = React.useContext(ClipboardContext);
  return (
    <PageHeader>
      {identity?.identityOauth?.account && (
        <PageHeader.Pic>
          <AccountProfilePic account={identity?.identityOauth?.account} large />
        </PageHeader.Pic>
      )}
      <PageHeader.Title>{identity?.id}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={EnvelopeIcon}>
          <div
            className="text-blue-900 cursor-pointer"
            onClick={() => sendToClip(identity?.identityOauth?.email!)}
          >
            {identity?.identityOauth?.email}
          </div>
          <Link
            to={{
              pathname: "/subetha/recipients",
              search: `?email=${identity?.identityOauth?.email}`,
            }}
          >
            <MagnifyingGlassIcon
              className="flex-shrink-0 mx-1.5 h-5 w-5 text-zenlist-400"
              aria-hidden="true"
            />
          </Link>
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Created:{" "}
          <Timestamp
            timestamp={identity?.identityOauth?.createdAt}
            format="short"
            popover
          />
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default IdentityOauthTitle;
