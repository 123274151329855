import { CalendarIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../../common/utils/dateformat";
import { ConversationFragment } from "../../../graphql/generated";
import GroupPic from "../../../group/components/pic";
import PageHeader from "../../../common/components/page-header";

const ConversationTitle: React.FC<{
  conversation?: ConversationFragment;
}> = ({ conversation }) => {
  return (
    <PageHeader>
      {conversation?.group && (
        <PageHeader.Pic>
          <GroupPic group={conversation.group} large />
        </PageHeader.Pic>
      )}
      <PageHeader.Title>
        Conversation{" "}
        <span className="text-xs text-gray-500">
          {conversation?.displayName ??
            "Group: " + conversation?.group?.nameLong}
        </span>
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Latest Message: {longDateFormat(conversation?.messagedAt)}
        </PageHeader.DetailsItem>
        {conversation?.group && (
          <PageHeader.DetailsItem icon={UserGroupIcon}>
            <Link
              to={`/groups/${conversation.group.id}`}
              className="text-blue-900 hover:underline"
            >
              {conversation.group.nameLong}
            </Link>
          </PageHeader.DetailsItem>
        )}
      </PageHeader.Details>
    </PageHeader>
  );
};

export default ConversationTitle;
