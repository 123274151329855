import React from "react";
import Panel from "../../../common/components/panel";
import {
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
  RmRuleMappingTypeFragment,
  useRmMappingUpdateRuleMappingMutation,
} from "../../../graphql/generated";
import { NotificationContext } from "../../../common/context/notification";
import { classNames } from "../../../common/utils/classnames";
import RuleExpressionEditor from "../rule-expression-editor";

const RMRuleMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  mappingType: RmRuleMappingTypeFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping, mappingType }) => {
  const [expression, setExpression] = React.useState(mappingType.expression);
  const { notifier } = React.useContext(NotificationContext);
  const [{ fetching }, mutation] = useRmMappingUpdateRuleMappingMutation();

  const submitForm = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (fetching) {
        return;
      }
      await mutation({
        mappingId: mapping.id,
        expression,
      }).then(notifier.notifyGraphql("Mapping updated"));
    },
    [expression, mapping.id, fetching, mutation]
  );

  return (
    <>
      <Panel>
        <Panel.Title>Rule Mapping</Panel.Title>
        <Panel.Body>
          <form onSubmit={submitForm}>
            <div className="my-2">
              Destination: {mapping.field.name} ({mapping.field.type})
            </div>
            <div className="my-2">
              <RuleExpressionEditor
                expression={expression}
                disabled={fetching}
                onChange={setExpression}
              />
            </div>
            <div className="my-2">
              <button
                type="submit"
                className={classNames(
                  "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                  fetching
                    ? "text-gray-600 bg-gray-300"
                    : "text-white bg-indigo-600 hover:bg-indigo-700"
                )}
                disabled={fetching}
              >
                {fetching ? "Updating..." : "Update"}
              </button>
            </div>
          </form>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default RMRuleMappingTypeControl;
