import { Menu, Transition } from "@headlessui/react";
import {
  ArrowsPointingInIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import { GroupFragment, useMergeGroupMutation } from "../../graphql/generated";
import MergeDialog from "./merge-dialog";

const GroupActions: React.FC<{ group?: GroupFragment }> = ({ group }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [mergeOpen, setMergeOpen] = React.useState(false);

  const [, mergeGroupMutation] = useMergeGroupMutation();

  const mergeGroup = React.useCallback(
    async (destinationGroup: GroupFragment) => {
      await mergeGroupMutation({
        sourceGroupId: group?.id ?? "",
        destinationGroupId: destinationGroup.id,
      }).then(
        notifier.notifyGraphql(
          "Group merge started. Refresh in a few seconds to see results."
        )
      );
      setMergeOpen(false);
    },
    [group?.id, mergeGroupMutation, setMergeOpen]
  );

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/groups/${group?.id}/items`}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <MagnifyingGlassCircleIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Search Group Items
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setMergeOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <ArrowsPointingInIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Merge group
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <MergeDialog
        sourceGroup={group}
        isOpen={mergeOpen}
        onClose={() => setMergeOpen(false)}
        onSubmit={(group) => mergeGroup(group)}
      />
    </>
  );
};

export default GroupActions;
