import React from "react";
import PageHeader from "../../common/components/page-header";

const ResourceMappingTitle: React.FC<
  { name?: string; actions?: React.ReactChild } & React.PropsWithChildren
> = ({ name, actions, children }) => {
  return (
    <PageHeader>
      <PageHeader.Title>{name}</PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem>{children}</PageHeader.DetailsItem>
      </PageHeader.Details>
      {actions && <PageHeader.Actions>{actions}</PageHeader.Actions>}
    </PageHeader>
  );
};

export default ResourceMappingTitle;
