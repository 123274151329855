import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import { shortDateFormat } from "../../common/utils/dateformat";
import { ClientInvitationFragment } from "../../graphql/generated";
import ClientInvitationPic from "./pic";
import PageHeader from "../../common/components/page-header";

const ClientInvitationTitle: React.FC<{
  invitation?: ClientInvitationFragment;
}> = ({ invitation }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <ClientInvitationPic invitation={invitation} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {invitation?.name.trim() === "" ? "Empty Name" : invitation?.name}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          {shortDateFormat(invitation?.createdAt)}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default ClientInvitationTitle;
