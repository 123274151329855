import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { BrokerageFragment } from "../../graphql/generated";
import BrokeragePic from "./brokeragepic";
import BrokerageItemActions from "./itemactions";
import PageHeader from "../../common/components/page-header";

const BrokerageTitle: React.FC<{ brokerage?: BrokerageFragment }> = ({
  brokerage,
}) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <BrokeragePic brokerage={brokerage} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {brokerage?.name.trim() === "" ? "Empty Name" : brokerage?.name}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={InformationCircleIcon}>
          {brokerage?.enabledAgentsCount}
        </PageHeader.DetailsItem>
        <PageHeader.DetailsItem icon={CheckIcon}>
          {brokerage?.activeAgentsCount}
        </PageHeader.DetailsItem>
      </PageHeader.Details>
      <PageHeader.Actions>
        <BrokerageItemActions brokerage={brokerage} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default BrokerageTitle;
