import React from "react";
import { ReprocessDataPlanDetailsFragment } from "../../graphql/generated";
import ReprocessDataTaskPic from "./pic";
import PageHeader from "../../common/components/page-header";
import ReprocessDataPlanItemActions from "./itemactions";

export const ReprocessDataPlanTitle: React.FC<{
  plan?: ReprocessDataPlanDetailsFragment;
}> = ({ plan }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <ReprocessDataTaskPic plan={plan} large />
      </PageHeader.Pic>
      <PageHeader.Title>{plan?.title ?? plan?.id}</PageHeader.Title>
      <PageHeader.Actions>
        <ReprocessDataPlanItemActions plan={plan} />
      </PageHeader.Actions>
    </PageHeader>
  );
};
