import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import {
  FeatureFlagFragment,
  FeatureFlagState,
  useArchiveFeatureFlagMutation,
  useUnarchiveFeatureFlagMutation,
} from "../../graphql/generated";
import { NotificationContext } from "../../common/context/notification";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";

const FeatureFlagItemActions: React.FC<{ flag?: FeatureFlagFragment }> = ({
  flag,
}) => {
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [unarchiveOpen, setUnarchiveOpen] = React.useState(false);

  const { notifier } = React.useContext(NotificationContext);

  const [{ fetching: archiveFeatureFlagFetching }, archiveFeatureFlagMutation] =
    useArchiveFeatureFlagMutation();

  const [
    { fetching: unarchiveFeatureFlagFetching },
    unarchiveFeatureFlagMutation,
  ] = useUnarchiveFeatureFlagMutation();

  const archiveFeatureFlag = React.useCallback(async () => {
    if (!archiveFeatureFlagFetching) {
      await archiveFeatureFlagMutation({
        id: flag?.id ?? "",
      }).then(notifier.notifyGraphql("Feature flag has been archived"));

      setArchiveOpen(false);
    }
  }, [flag, archiveFeatureFlagFetching, archiveFeatureFlagMutation]);

  const unarchiveFeatureFlag = React.useCallback(async () => {
    if (!unarchiveFeatureFlagFetching) {
      await unarchiveFeatureFlagMutation({
        id: flag?.id ?? "",
      }).then(notifier.notifyGraphql("Feature flag has been unarchived"));
      setUnarchiveOpen(false);
    }
  }, [flag, unarchiveFeatureFlagFetching, unarchiveFeatureFlagMutation]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/feature_flags/${flag?.id}/edit`}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Edit
                  </Link>
                )}
              </Menu.Item>
              {flag?.state === FeatureFlagState.Active && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => setArchiveOpen(!archiveOpen)}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Archive
                    </div>
                  )}
                </Menu.Item>
              )}
              {flag?.state === FeatureFlagState.Archived && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => setUnarchiveOpen(!unarchiveOpen)}
                    >
                      <PencilIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Unarchive
                    </div>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <ZenDialog
        onCancel={() => setArchiveOpen(false)}
        onSubmit={() => archiveFeatureFlag()}
        submit="Archive"
        title="Archive"
        show={archiveOpen}
        icon={ExclamationTriangleIcon}
        state={
          archiveFeatureFlagFetching
            ? ZenDialogState.Submitting
            : ZenDialogState.Displaying
        }
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Are you sure you want to archive this feature flag?
          </p>
        </div>
      </ZenDialog>

      <ZenDialog
        onCancel={() => setUnarchiveOpen(false)}
        onSubmit={() => unarchiveFeatureFlag()}
        submit="Unarchive"
        title="Unarchive"
        show={unarchiveOpen}
        icon={ExclamationTriangleIcon}
        state={
          unarchiveFeatureFlagFetching
            ? ZenDialogState.Submitting
            : ZenDialogState.Displaying
        }
      >
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Are you sure you want to unarchive this feature flag?
          </p>
        </div>
      </ZenDialog>
    </>
  );
};

export default FeatureFlagItemActions;
