import React from "react";
import { MlsFragment } from "../../graphql/generated";
import MlsPic from "./pic";
import MlsActions from "./actions";
import PageHeader from "../../common/components/page-header";

const MlsTitle: React.FC<{ mls?: MlsFragment }> = ({ mls }) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <MlsPic mls={mls} large />
      </PageHeader.Pic>
      <PageHeader.Title>{mls?.name}</PageHeader.Title>
      <PageHeader.Actions>
        <MlsActions mls={mls} />
      </PageHeader.Actions>
    </PageHeader>
  );
};

export default MlsTitle;
